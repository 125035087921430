<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          v-for="(header, idx) in this.timeSlotTableHeaders"
          :class="[
            idx === 0
              ? 'py-3.5 pl-4 pr-3 sm:pl-6'
              : 'px-3 py-3.5 lg:table-cell',
            'text-left text-sm font-semibold text-gray-900',
          ]"
          :key="header.name"
        >
          <div
            :class="[
              header.name != 'actions'
                ? 'inline-flex sm:mt-0 cursor-pointer'
                : 'inline-flex sm:mt-0',
            ]"
            @click="
              this.setSortingTimeSpans(header.sort, header.name),
                (header.isSort = !header.isSort)
            "
          >
            <span
              :class="[
                header.name === this.activeSorting && header.name != 'actions'
                  ? `underline`
                  : '',
                'mr-1',
              ]"
            >
              {{ header.name !== "actions" ? $t(header.name) : "" }}
            </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="flex-1 sm:flex-none divide-y divide-gray-200">
      <tr
        v-for="(callQueue, idx) in this.tablePaginatedData"
        :key="callQueue.id"
      >
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="text-sm font-medium capitalize text-gray-900">
            <ul>
              <li v-for="day in callQueue.daysOfWeek" :key="day">
                {{ $t(this.weekdays[day]) }}
              </li>
            </ul>
            <!-- {{ $t(this.weekdays[callQueue.daysOfWeek]) }} -->
            <!-- {{ callQueue.fullDate }} -->
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="text-sm text-gray-900 inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ callQueue.start.slice(0, 5) }} to
            {{ callQueue.end.slice(0, 5) }}
          </div>
        </td>
        <td
          class="px-6 py-4 whitespace-nowrap"
        >
          <div class="text-sm text-gray-900 flex justify-end">
            <a
              @click="$emit('deleteList', idx)"
              href="javascript:void(0)"
              :title="$t('delete')"
              class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <!-- <button
        class="rounded p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer mr-1"
        @click="$emit('openEditForm')"
        :title="$t('edit')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
          />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button> -->
          </div>
        </td>

        <!-- <EditCallQueueDetail
          :id="callQueue.id"
          v-on:deleteList="
            this.deleteTimeSlotList(
              callQueue.id,
              'Time slot deleted successfully',
              `Delete time slot for ${[
                callQueue.dayOfWeek
                  ? $t(this.weekdays[callQueue.dayOfWeek])
                  : this.fullFormatDateFrench(callQueue.fullDate),
              ]}`,
              'Do you wish to continue?',
              'Continue',
              'Go back'
            )
          "
          @openEditForm="
            $emit(
              'openEditFormTimeSlots',
              callQueue.id,
              callQueue.dayOfWeek,
              callQueue.fullDate
            )
          "
        /> -->
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import EditCallQueueDetail from "../components/EditCallQueueDetail.vue";

export default {
  name: "CallQueuesDetailTable",

  props: ["tablePaginatedData", "account", "id", "callqueueTable"],
  components: {
    Loading,
  },
  data() {
    return {
      timeSlotTableHeaders: [
        {
          name: "callqueueTable.weekdayAndDate",
          sort: ["dayOfWeek", "fullDate"],
          isSort: false,
        },
        { name: "callqueueTable.timeslot", sort: "start", isSort: false },
        { name: "actions" },
      ],
      weekdays: [
        "",
        "weekdays.mon",
        "weekdays.tue",
        "weekdays.wed",
        "weekdays.thu",
        "weekdays.fri",
        "weekdays.sat",
        "weekdays.sun",
      ],
      callQueueDetail: {},
      callQueuesGroup: {},
      twelveMonthsBankHolidays: {},
      openTimeSlotForm: false,
      openExceptionForm: false,
      openBankHolidaysForm: false,
      showMessage: false,
      isLoading: true,
      fullPage: false,
      activeSorting: "",
    };
  },
  methods: {
    async deleteTimeSlotList(
      id,
      msg,
      title,
      confirmationMessage,
      confirmButton,
      goBack
    ) {
      const options = {
        method: "DELETE",
        url: `${this.$cookie.getCookie("API")}/api/v1/callQueueTimeSpans/${id}`,
        params: { customerAccount: this.account },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      if (true) {
        axios
          .request(options)
          .then((response) => {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
          })
          .catch((error) => {
            this.errorHandling(error);
          });
      }
    },
    async deleteExceptionList(
      id,
      msg,
      title,
      confirmationMessage,
      confirmButton,
      goBack
    ) {
      const options = {
        method: "DELETE",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/callQueueExceptions/${id}`,
        params: { customerAccount: this.account },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .request(options)
          .then((response) => {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
          })
          .catch((error) => {
            this.errorHandling(error);
          });
      }
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      return dateCreated.toLocaleDateString("fr-FR");
    },
    fullFormatDateFrench(date) {
      const dateCreated = new Date(date);
      const frFormatDate = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formatDate = dateCreated.toLocaleDateString("fr-FR", frFormatDate);
      const capitalizeformatDate = formatDate.replace(/\w\S*/g, (w) =>
        w.replace(/^\w/, (c) => c.toUpperCase())
      );
      return capitalizeformatDate;
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
    closeTimeSlotForm() {
      this.openTimeSlotForm = !this.openTimeSlotForm;
    },
    closeExceptionForm() {
      if (this.openExceptionForm != false) {
        this.openExceptionForm = !this.openExceptionForm;
      } else {
        this.openBankHolidaysForm = !this.openBankHolidaysForm;
      }
    },
    setSortingTimeSpans(sort, header) {
      this.$emit("sortTimeSpans", sort);
      this.activeSorting = header;
    },
    setSortingExceptions(sort, header) {
      this.$emit("sortExceptions", sort);
      this.activeSorting = header;
    },
  },

  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style></style>
