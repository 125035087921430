<template>
 <div class="relative">
  <div class="pointer-events-none absolute left-0 pl-3 flex items-center top-2">
   <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
  </div>
  <vue-select
   v-if="!addUserToGroup"
   v-model="search"
   :options="data"
   :visible-options="visibleOptions"
   :close-on-select="closeOnSelect ? true : false"
   searchable
   :taggable="isMultiple"
   :loading="isLoading"
   clear-on-select
   :multiple="isMultiple"
   :placeholder="placeholder"
   @search:input="handleSearchInput"
   class="text-left block w-1/3 border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
  >
  </vue-select>
  <vue-select
   v-else
   v-model="selected"
   :options="data"
   :visible-options="visibleOptions"
   :close-on-select="closeOnSelect ? true : false"
   searchable
   :taggable="isMultiple"
   :loading="isLoading"
   clear-on-select
   :multiple="isMultiple"
   :placeholder="placeholder"
   @search:input="handleSearchInput"
   class="text-left block w-1/3 border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
  >
  </vue-select>
 </div>
</template>

<script>
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { SearchIcon } from "@heroicons/vue/outline";

export default {
 props: [
  "placeholder",
  "selectedCustomer",
  "searchMethod",
  "data",
  "closeOnSelect",
  "isMultiple",
  "selectAllElements",
  "selectedAllElements",
  "clearAllElements",
  "elementToRemove",
  "maxHeight",
  "groupData",
  "extensionListConcat",
  "selectedElement",
  "selected",
  "visibleOptions",
  "addUserToGroup",
 ],
 emits: ["action"],
 components: {
  VueSelect,
  SearchIcon,
 },
 data() {
  return {
   search: "",
   selected: "",
   searchableTerm: "",
   isLoading: false,
  };
 },
 methods: {
  handleSearchInput(event) {
   this.searchableTerm = event.target.value;
  },
 },
 watch: {
  searchableTerm: function (val) {
   if (this.searchMethod) {
    if (val.length > 2) {
     this.searchMethod(val);
    }
   }
  },
  search: function (val) {
   this.isLoading = false;
   this.$emit("action", val);
  },
  selected: function (val) {
   this.search = val;
  },
 },
 mounted() {},
};
</script>

<style></style>
