<template>
  <popup-modal ref="popup">
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed z-50 inset-0 overflow-y-auto"
        @close="_close"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div
            class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6"
              >
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    @click="_cancel"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <font-awesome-icon
                      icon="circle-question"
                      class="h-6 w-6 text-blue-600"
                    />
                  </div>
                  <div class="mt-3 sm:mt-0 sm:ml-4">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      {{ $t("strategyType") }}
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{ $t("strategyTypePopup") }}.
                      </p>
                    </div>
                    <div class="">
                      <h2 class="sr-only">A better way to send money.</h2>
                      <ul class="list-disc text-gray-400">
                        <li
                          v-for="feature in features"
                          :key="feature.id"
                          class="my-2"
                        >
                          <p class="text-base font-medium text-gray-700">
                            {{ feature.name }}
                          </p>
                          <p class="text-base text-gray-500">
                            {{ feature.description }}
                          </p>
                          <ul
                            v-show="feature.id === 6"
                            class="list-disc ml-5 text-gray-300"
                          >
                            <li v-for="list in lists" :key="list.id">
                              <p class="text-sm font-medium text-gray-700">
                                {{ list.name }}
                              </p>
                              <p class="text-sm text-gray-500">
                                {{ list.description }}
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </popup-modal>
</template>

<script>
import PopupModal from "../../PopupModal.vue";
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, XIcon, ExclamationIcon } from "@heroicons/vue/outline";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");
const features = [
  {
    id: 1,
    name: "Sonne tous",
    description: "Les téléphones de TOUS les agents sonneront simultanément.",
  },
  {
    id: 2,
    name: "1 à 1 dans l'ordre",
    description:
      "3CX distribuera l’appel en fonction de la position spécifiée dans la section Agents de la file d’attente (selon l'ordre indiqué dans la section « Membres »). Tous les appels sont d’abord distribués au premier agent, et si ce dernier est occupé, ils seront distribués à l’agent suivant.",
  },
  {
    id: 3,
    name: "1 à 1 au hasard",
    description:
      "3CX choisira un agent au hasard auquel il distribuera l’appel. Ceci distribuera équitablement les appels à chaque agent.",
  },
  {
    id: 4,
    name: "Agent avec le moins d'appel",
    description:
      "3CX distribuera l’appel en priorité à l'agent ayant reçu le moins d'appel. La période de remise à zéro du compteur est quotidienne par défaut. Si vous souhaitez une période de remise à zéro hebdomadaire ou mensuelle indiquez le dans les commentaires.",
  },
  {
    id: 5,
    name: "Temps de communication",
    description:
      "3CX distribuera l’appel en priorité à l'agent ayant passé le moins de temps en communication. La période de remise à zéro du compteur est quotidienne par défaut. Si vous souhaitez une période de remise à zéro hebdomadaire ou mensuelle indiquez le dans les commentaires.",
  },
  {
    id: 6,
    name: "Routage sur compétences (Licence Entreprise)",
    description:
      "Le routage basé sur les compétences dirige les appels entrants des files d’attente vers les agents du prochain groupe de compétences si les membres du groupe de compétences précédent sont occupés, indisponibles ou déconnectés. Par exemple, un call center peut assigner ses agents de support dans des groupes de compétences basés sur leur expertise. Dans ce cas, les appels entrants sont d’abord assignés aux agents dans le groupe de compétence de niveau “1”, et s’ils ne sont pas disponibles, redirigés vers des agents moins expérimentés dans les prochains groupes de compétence. Les stratégies suivantes pour le “routage sur compétences” sont disponibles :",
  },
];

const lists = [
  {
    id: 1,
    name: "Sonne tous",
    description:
      "l’appel sonne sur les téléphones de tous les agents dans le groupe de compétences spécifique.",
  },

  {
    id: 2,
    name: "1 à 1 au hasard",
    description:
      "sélectionne au hasard un agent à qui assigner l’appel, en distribuant équitablement les appels entre les agents du groupe de compétences spécifique.",
  },
  {
    id: 3,
    name: "Round Robin",
    description:
      "les appels tournent de façon séquentielle entre tous les agents disponibles dans le groupe de compétences spécifique.",
  },
  {
    id: 4,
    name: "Routage sur compétences (Licence Entreprise)",
    description:
      "Le routage basé sur les compétences dirige les appels entrants des files d’attente vers les agents du prochain groupe de compétences si les membres du groupe de compétences précédent sont occupés, indisponibles ou déconnectés. Par exemple, un call center peut assigner ses agents de support dans des groupes de compétences basés sur leur expertise. Dans ce cas, les appels entrants sont d’abord assignés aux agents dans le groupe de compétence de niveau “1”, et s’ils ne sont pas disponibles, redirigés vers des agents moins expérimentés dans les prochains groupes de compétence. Les stratégies suivantes pour le “routage sur compétences” sont disponibles :",
  },
  {
    id: 5,
    name: "Agent avec le moins d'appel",
    description:
      "donne la priorité aux agents disponibles qui ont répondu au moins d’appels dans ce groupe de compétences.",
  },
];

export default {
  props: [],
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  data() {
    return {
      account,
      features,
      lists,
      cancelButton: undefined, // Cancel button text content
      message: undefined, // Main text content
      messageOption: undefined, // Optional text content
      okButton: undefined, // Confirmation button text content
      title: undefined, // Popup title content
      subMessage: undefined, // text content before input
    };
  },
  components: {
    CheckIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    ExclamationIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    PopupModal,
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.messageOption = opts.messageOption;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.action) {
        this.action = opts.action;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      try {
        this.$refs.popup.close();
        this.resolvePromise(true);
      } catch (error) {
        console.error(error);
      }
    },
    _cancel() {
      try {
        this.$refs.popup.close();
        this.resolvePromise(false);
      } catch (error) {
        console.error(error);
      }
    },
    _close() {
      try {
        this.$refs.popup.close();
        this.resolvePromise(false);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style></style>
