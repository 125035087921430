<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel class="block text-sm font-medium text-gray-700">
    </ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton
        class="body__form--input-ring-focus relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
      >
        <span class="w-full inline-flex truncate">
          <span class="truncate">{{ selected.name }}</span>
          <!--  <span class="ml-2 truncate text-gray-500"
            >{{ selected.quantity ? selected.quantity + " restants" : "" }}
          </span> -->
        </span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            as="template"
            v-for="(item, idx) in items"
            :key="idx"
            :value="item"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-white sidebar' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
            >
              <div v-show="item.quantity > 0" class="flex">
                <span :class="[selected ? 'font-semibold' : 'font-normal', '']">
                  {{ item.name }}
                </span>
                <!--     <span
                  :class="[
                    active ? 'text-gray-200' : 'text-gray-500',
                    'ml-2 truncate',
                  ]"
                >
                  {{ item.quantity }} restants
                </span> -->
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'body__table-header-filter--text',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  props: ["ipModelePhoneSelected", "items"],
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },

  data() {
    return { enabled: false, selected: "" };
  },
  methods: {},

  watch: {
    ipModelePhoneSelected: function() {
      this.selected = this.ipModelePhoneSelected;
    },
    selected: function(val) {
      this.$emit("ipModelePhone", val);
    },
  },
  mounted() {
    this.selected = this.ipModelePhoneSelected;
  },
};
</script>
