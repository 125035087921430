<template>
 <div class="space-y-6">
  <div class="bg-white shadow mx-5 px-4 py-5 sm:rounded-lg sm:p-6">
   <div>
    <div>
     <h3 class="text-lg font-medium leading-6 text-gray-900">
      {{ $t("siteNetworkInformations") }}
     </h3>
     <p class="mt-1 text-sm text-gray-500">{{ $t("siteNetworkInformationsDetail") }}.</p>
     <!--       <collect-network-form-popup
            v-if="showForm"
            @closeForm="showForm = false"
            @submitForm="submitForm($event)"
            :editData="editData"
            :editIdx="editIdx"
          /> -->
     <CollectNetworkEdit
      v-if="showForm"
      :editData="editData"
      :editIdx="editIdx"
      :bounceButton="bounceButton"
      :unsavedChangesBool="unsavedChangesBool"
      :elementRef="elementRef"
      @closeForm="(showForm = false), $emit('unsavedChanges', false, '')"
      @submitForm="submitForm($event)"
      @unsaved-changes="(...args) => $emit('unsavedChanges', ...args)"
     />
     <!-- <div class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <InformationCircleIcon
                  class="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                  Exemple : Pour être mis en relation avec le service
                  commercial, composez le 1, pour être mis en relation avec le
                  service technique composer le 2, etc...
                </p>
              </div>
            </div>
          </div> -->
    </div>
    <!-- <div class="mt-0">
          <div class="flex justify-center">
            <button
              @click="
                (showForm = !showForm),
                  (editIdx = undefined),
                  (editData = undefined)
              "
              type="button"
              class="px-2 py-2 border border-transparent rounded-md text-white text-white body__button uppercase text-sm"
            >
              add network
            </button>
          </div>
        </div> -->
   </div>
   <div v-show="networkList.length > 0">
    <div class="flex justify-between">
     <!-- <h3 class="text-lg font-medium leading-6 text-gray-900">
            Sites et reseau
          </h3> -->
     <div class="ml-4 flex-shrink-0">
      <!-- <button
            @click="
              (showForm = !showForm),
                (editIdx = undefined),
                (editData = undefined)
            "
            type="button"
            :class="[
              'relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none',
            ]"
          >
            <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
          </button> -->
     </div>
    </div>
    <collect-network-table
     :tablePaginatedData="networkList"
     @editForm="openEditForm($event)"
     @deleteItem="deleteItem($event)"
    />
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import CollectNetworkTable from "./CollectNetworkTable.vue";
import CollectNetworkEdit from "./CollectNetworkEdit.vue";
import { mapGetters } from "vuex";
import { ExclamationIcon, PlusSmIcon, InformationCircleIcon } from "@heroicons/vue/solid";

const networkList = [];

export default {
 props: ["fileId", "contentData", "bounceButton", "unsavedChangesBool", "elementRef"],
 components: {
  InformationCircleIcon,
  PlusSmIcon,
  CollectNetworkTable,
  CollectNetworkEdit,
 },
 data() {
  return {
   showForm: false,
   networkList,
   editData: undefined,
   editIdx: undefined,
   stepName: "network",
  };
 },
 methods: {
  getData() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.networkList = this.contentData[this.stepName];
   }
  },
  sendForm(msg) {
   let stepContent = this.networkList;

   let content = this.contentData;

   content[this.stepName] = stepContent;

   content = JSON.stringify(content);

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     console.log(res);
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function(error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  openEditForm(idx) {
   if (this.networkList.length > 0) {
    // this.editData = this.networkList.filter((res) => {
    //   return res.sviName === name;
    // });
    this.editData = this.networkList[idx];
    this.editIdx = idx;
   }
   this.showForm = true;
  },
  deleteItem(idx) {
   this.networkList.splice(idx, 1);
   this.sendForm("Item deleted.");
  },
  submitForm(event) {
   if (this.editIdx >= 0) {
    console.log("file: SVIForm.vue ~ line 146 ~ addToList ~ this.editIdx", this.editIdx);
    this.networkList.splice(this.editIdx, 1, event);
   } else {
    this.networkList.push(event);
   }
   this.sendForm(this.$t("informationSaved"));
   this.showForm = false;
  },
  clearForm() {
   for (let index = 0; index < this.modules.length; index++) {
    const element = this.modules[index];
    element.active = 0;
   }
  },
 },
 mounted() {
  this.getData();
 },
 computed: {
  ...mapGetters(["account"]),
 },
};
</script>

<style></style>
