<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="_close">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
     >
      <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
     >
      <div
       class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
       <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
         <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
         >
          <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
         </div>
         <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <DialogTitle v-if="title" as="h3" class="text-lg leading-6 font-medium text-gray-900">
           {{ $t(title) }}
          </DialogTitle>
          <div class="mt-2">
           <p class="text-sm text-gray-500" v-if="message">
            {{ $t(message) }}
           </p>
           <p v-if="messageOption" class="text-sm text-gray-500">
            {{ $t(messageOption) }}
           </p>
          </div>
         </div>
        </div>
       </div>
       <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
         type="button"
         class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
         @click="_confirm"
        >
         {{ $t(okButton) }}
        </button>

        <button
         type="button"
         class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium sm:w-auto sm:text-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none body__form--input-ring-focus"
         @click="_close"
         ref="cancelButtonRef"
        >
         {{ $t(cancelButton) }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ExclamationIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");

export default {
 props: [
  "elementOption",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   account,
   cancelButton: undefined, // Cancel button text content
   contentData: undefined, // current data
   data: {},
   fileId: undefined, // id of the file
   idx: undefined, // idx of the step in the navbar
   isEdited: undefined, // Boolean to check if the user edit somethin inside User or Group
   message: undefined, // Main text content
   messageOption: undefined, // Optional text content
   objName: undefined, // name of the current object
   okButton: undefined, // Confirmation button text content
   title: undefined, // Popup title content
   subMessage: undefined, // text content before input
   customerFileId: undefined,
   unsavedChangesAlert: undefined,
  };
 },
 components: {
  ExclamationCircleIcon,
  ExclamationIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
 },
 methods: {
  saveChanges() {
   if (this.objName === "users" || this.objName === "groups" || this.objName === "callQueue") {
    let content = this.contentData;
    // console.log(
    //   "file: PopupAlert.vue ~ line 172 ~ saveChanges ~ content",
    //   content
    // );
    // console.log(
    //   "file: PopupAlert.vue ~ line 182 ~ saveChanges ~ content[this.objName]",
    //   content[this.objName]
    // );
    // console.log(
    //   "file: PopupAlert.vue ~ line 178 ~ saveChanges ~ this.data",
    //   this.data
    // );
    // if (content[this.objName]) {
    //   content[this.objName].push(this.data);
    // } else {
    //   content[this.objName] = [];
    //   content[this.objName].push(this.data);
    // }

    //Calcul the sum of SDA left in 03/USERS
    if (this.objName === "users") {
     let sdaUsed = 0;
     for (let index = 0; index < this.data.length; index++) {
      const element = this.data[index];
      sdaUsed = sdaUsed + element.userSettings.sdaNumber.length;
     }
     let sdaLeft = this.contentData["numbers"].sdaUsed.sdaLeft;

     this.contentData["numbers"].sdaUsed.sdaLeft = sdaLeft - sdaUsed;
    }
    const formContent = JSON.stringify(content);
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${formContent}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then((res) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", this.$t("informationSaved"));
     })
     .catch((err) => {
      this.errorHandling(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", err.response.data.response);
     });
   } else {
    let content = this.contentData;
    var totalSda = 0;
    var totalRange = 0;

    let sdaObj = {};
    let rangeObj = {};
    let sdaKeys = [];
    let rangeKeys = [];
    if (this.data.address) {
     let adressess = Object.values(this.data.address);
     let networkList = content["network"];
     for (let index = 0; index < adressess.length; index++) {
      let hasAddress = false;
      let network = {};
      network = {
       siteName: "",
       siteAddress: "",
       customerProvideSwitch: 1,
       switchPOE: 1,
       switchVlan: 1,
       switchType: { model: "", brand: "" },
       firewall: 1,
       firewallType: { model: "", brand: "" },
       firewallReservedSpeed: "",
       firewallAllowTrafic: 0,
       smartphoneWifi: false,
       wifiType: { model: "", brand: "" },
       numbFixedWorkStations: 0,
       numbSBC: 0,
       wifiName: "",
       internetName: "",
       numbSwitchesProvided: 0,
       numbFirewallsProvided: 0,
       numbEthernetSockets: 0,
       numbPOEPorts: 0,
       numbElectricOutlets: 0,
       societyName: "",
       fileResponsibleName: "",
       fileResponsibleContact: "",
       fileResponsibleEmail: "",
      };
      const element = adressess[index];
      network.siteName = element.name;
      network.siteAddress = element.street;
      for (let index = 0; index < networkList.length; index++) {
       const elementList = networkList[index];
       if (elementList.siteName === network.siteName) {
        hasAddress = true;
       }
      }
      if (!hasAddress || networkList.length == 0) {
       networkList.push(network);
      }
     }
     content["network"] = networkList;
    }
    if (this.data.sda) {
     //Calcul the total of number to be carried in 02/NUMBERS
     rangeKeys = Object.keys(this.data.number.range);
     rangeObj = this.data.number.range;
     rangeKeys.forEach((element, index) => {
      let diff = rangeObj[element].to - rangeObj[element].from;
      totalRange += diff;
     });

     //Calcul the sum of SDA chosen by the user in 02/NUMBERS
     sdaKeys = Object.keys(this.data.sda.sda);
     sdaObj = this.data.sda.sda;
     sdaKeys.forEach((element, index) => {
      totalSda += sdaObj[element].number;
     });
     totalSda += totalRange;

     this.data.sdaUsed ? "" : (this.data.sdaUsed = { sdaMax: 0, sdaLeft: 0 });
     if (this.data.sdaUsed.sdaMax < totalSda) {
      const sdaDiff = this.data.sdaUsed.sdaMax - this.data.sdaUsed.sdaLeft;
      this.data.sdaUsed.sdaLeft = totalSda - sdaDiff;
      this.data.sdaUsed.sdaMax = totalSda;
     } else {
      const sdaDiff = this.data.sdaUsed.sdaMax - totalSda;
      this.data.sdaUsed.sdaLeft = this.data.sdaUsed.sdaLeft - sdaDiff;

      this.data.sdaUsed.sdaMax = totalSda;
     }
    }
    content[this.objName] = { ...this.data };
    const formContent = JSON.stringify(content);

    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${formContent}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then((res) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", "Collect saved");
     })
     .catch((err) => {
      this.errorHandling(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", err.response.data.response);
     });
   }
  },
  editChanges() {
   let content = this.contentData;
   content[this.objName] = this.data;

   //Calcul the sum of SDA left in 03/USERS
   if (this.objName === "users") {
    var totalSdaUsed = null;
    this.data.forEach((user) => {
     totalSdaUsed += user.userSettings.sdaNumber.length;
    });
    //let sdaMax = this.$cookie.getCookie("sdaMax");
    let sdaMax = this.contentData["numbers"].sdaUsed.sdaMax;
    this.contentData["numbers"].sdaUsed.sdaLeft = sdaMax - totalSdaUsed;
   }
   const formContent = JSON.stringify(content);

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${formContent}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("informationSaved"));
    })
    .catch((err) => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.response);
    });
  },
  unsaveChanges() {
   this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/${this.idx}`);
   this.$emit("activeBanner");
   this.$emit("success", false);
   this.$emit("successMsg", this.$t("lostChanges"));
  },
  show(opts = {}) {
   this.title = opts.title;
   this.message = opts.message;
   this.messageOption = opts.messageOption;
   this.data = opts.data;
   this.okButton = opts.okButton;
   this.idx = opts.idx;
   this.fileId = opts.fileId;
   this.objName = opts.objName;
   this.contentData = opts.contentData;
   this.isEdited = opts.isEdited;
   this.customerFileId = opts.customerFileId;
   this.unsavedChangesAlert = opts.unsavedChangesAlert;
   if (opts.cancelButton) {
    this.cancelButton = opts.cancelButton;
   }
   if (opts.action) {
    this.action = opts.action;
   }
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  /*_confirm() {
      try {
        if (!this.isEdited) {
          this.saveChanges();
        } else {
          this.editChanges();
        }
        this.$refs.popup.close();
        this.resolvePromise(true);
        this.$router.push(
          `/customer-files/collect/${this.customerFileId}/${this.fileId}/${this.idx}`
        );
      } catch (error) {
        console.error(error);
      }
    },
     _cancel() {
      try {
        this.unsaveChanges();
        this.$refs.popup.close();
        this.resolvePromise(false);
      } catch (error) {
        console.error(error);
      }
    }, */
  _confirm() {
   try {
    this.unsaveChanges();
    this.$refs.popup.close();
    this.resolvePromise(true);
    // this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/${this.idx}`);
   } catch (error) {
    console.error(error);
   }
  },
  /*   _cancel() {
   try {
    this.saveChanges();
    this.$refs.popup.close();
    this.resolvePromise(true);
    this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/${this.idx}`);
   } catch (error) {
    console.error(error);
   }
  }, */
  _close() {
   try {
    this.$refs.popup.close();
    this.$emit("goToRef");
    this.resolvePromise(false);
   } catch (error) {
    console.error(error);
   }
  },
 },
 computed: {},
 watch: {},
};
</script>

<style></style>
