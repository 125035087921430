<template>
 <form @submit.prevent="isGroupAddUser ? saveAction() : emitForm()" method="POST">
  <div class="space-y-6 mt-2">
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6 space-y-6">
    <div class="border px-4 py-5 sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("mainInfos") }}
       </h3>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
       <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
         <label for="first-name" class="block text-sm font-medium text-gray-700">
          Nom du groupe
         </label>

         <input
          v-model="this.form.name"
          type="text"
          required
          name="first-name"
          id="first-name"
          class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
         />
        </div>
        <div v-show="groupNameExist" class="rounded-md bg-red-50 p-2 mt-3">
         <FormFieldMandatory :message="$t('groupNameAlreadyUsed')" />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="border px-4 py-5 sm:rounded-lg sm:p-6">
     <CollectTable
      :key="this.form.users ? this.form.users.length : ''"
      :users="this.form.users"
      :tableHeaders="tableHeaders"
      :userData="userData"
      :contentData="contentData"
      :collectType="collectType"
      :saveForm="saveForm"
      @isGroupAddUser="isGroupAddUser = $event"
      @isGroupEdited="isGroupEdited = $event"
      @addUserInGroup="(...args) => this.addUserInGroup(...args)"
      @editUserInGroup="(...args) => this.editUserInGroup(...args)"
      @deleteUserInGroup="
       (...args) =>
        this.deleteUserInGroup(
         args[0],
         $t('deleteMessage'),
         $t('deleteUserFromGroup', { user: args[1] }),
         $t('userDeletedSuccessfully'),
         $t('delete'),
         $t('cancel')
        )
      "
     />
    </div>
   </div>

   <div class="pb-5 flex justify-end" ref="groupForm">
    <button
     type="button"
     class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none encom_ring_color"
     @click="this.$emit('closeForm', false)"
    >
     {{ $t("cancel") }}
    </button>

    <button
     type="submit"
     class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button"
     :class="{ 'animate-bounce': bounceButton === 'groupForm' && unsavedChangesBool }"
    >
     {{ $t("validate") }}
    </button>
   </div>
  </div>
 </form>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import FormFieldMandatory from "./formTools/FormFieldMandatory.vue";
import axios from "axios";

import { ExclamationIcon, InformationCircleIcon } from "@heroicons/vue/solid";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import CollectTable from "./formTools/CollectTable.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
 name: "CollectAddUsers",
 props: [
  "groupChosen",
  "stepName",
  "isEdited",
  "idx",
  "fileId",
  "contentData",
  "userData",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "collectType",
 ],
 components: {
  AskConfirmationDialog,
  CollectTable,
  ExclamationIcon,
  InformationCircleIcon,
  ToggleWithIcon,
  FormFieldMandatory,
 },

 data() {
  return {
   saveForm: false,
   isGroupAddUser: false,
   isGroupEdited: false,
   groupNameExist: false,
   account,
   objKey: 0,
   missCall: false,
   voiceMail: false,
   callRestrictions: false,
   internationalRestrictions: false,
   showMore: false,
   form: {
    name: "",
    users: [],
    isComplete: false,
   },
   tableHeaders: [
    {
     name: "extension",
     description: "Indiquer les extensions appartenant au groupe",
    },
    {
     name: "voir les membres du groupe",
    },
    { name: "Voir les appels du Groupe" },
    { name: "Diffuse sa présence au groupe" },
    { name: "Montre ses appels au groupe" },
    { name: "intervenir, écouter, chuchotter" },
    {
     name: "appel Général interphonie",
     icon: "circle-info",
     description:
      "L'appel Général interphonie permet de déclencher un appel vers un poste ou un groupe de poste en décroché automatique sur haut parleur.",
    },
    { name: "parquer les appels" },
    { name: "modifier l'annuaire d'entreprise" },
    {
     name: "Administrateurs",
     icon: "circle-info",
     description:
      "Opérations récéptionnistes : Assigner/Désassigner une extension, Changer  le statut des utilisateurs (Dispo, Absent, Ne pas déranger , etc...), Programmer le reveil matin",
    },
   ],
   savedForm: "",
   showForm: false,
  };
 },

 methods: {
  addUserInGroup(...args) {
   const obj = args[0];
   const usersArray = args[1];
   if (this.form.users.length > 0) {
    if (usersArray.length === 0) {
     this.form.users.push(obj);
    } else {
     usersArray.forEach((user) => {
      var objUser = {
       adminRight: obj.adminRight,
       extension: user.split(" - ")[0],
       extensionName: user.split(" - ")[1],
       canSeeGroupMembers: obj.canSeeGroupMembers,
       canSeeGroupCalls: obj.canSeeGroupCalls,
       canUseGeneralIntercomCall: obj.canUseGeneralIntercomCall,
       canParkCalls: obj.canParkCalls,
       canEditCompanyDirectory: obj.canEditCompanyDirectory,
       canActListenWhisper: obj.canActListenWhisper,
       sprayPresenceGroup: obj.sprayPresenceGroup,
       showCallsToGroup: obj.showCallsToGroup,
      };

      this.form.users.push(objUser);
     });
    }
   } else {
    this.form.users = usersArray.map((user) => ({
     adminRight: obj.adminRight,
     extension: user.split(" - ")[0],
     extensionName: user.split(" - ")[1],
     canSeeGroupMembers: obj.canSeeGroupMembers,
     canSeeGroupCalls: obj.canSeeGroupCalls,
     canUseGeneralIntercomCall: obj.canUseGeneralIntercomCall,
     canParkCalls: obj.canParkCalls,
     canEditCompanyDirectory: obj.canEditCompanyDirectory,
     canActListenWhisper: obj.canActListenWhisper,
     sprayPresenceGroup: obj.sprayPresenceGroup,
     showCallsToGroup: obj.showCallsToGroup,
    }));
   }
   this.emitForm();
  },
  editUserInGroup(...args) {
   const newUser = args[0];
   const idx = args[1];
   this.form.users[idx] = newUser;
   this.emitForm();
  },
  async deleteUserInGroup(idx, confirmationMessage, title, msg, confirmButton, goBack) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.form.users.splice(idx, 1);
    var content = this.contentData;

    content = JSON.stringify(content);
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${content}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      console.error(error);
      this.errorHandling(error);
     });
   }
  },
  editGroup() {
   if (this.isEdited) {
    this.form.name = this.groupChosen.name;
    this.form.users = this.groupChosen.users;
   }
   this.savedForm = JSON.stringify(this.form);
  },
  clearForm() {
   this.form.address.name = "";
   this.form.address.street = "";
   this.form.address.city = "";
   this.form.address.state = "";
   this.form.address.postal = "";
   this.isEdited = false;
  },
  saveAction() {
   this.saveForm = true;
  },
  emitForm() {
   let groupNames = [];
   if (this.contentData && this.contentData["groups"] && this.contentData["groups"].length > 0) {
    for (let index = 0; index < this.contentData["groups"].length; index++) {
     const element = this.contentData["groups"][index];

     groupNames.push(element.name);
    }
   }

   if (groupNames.indexOf(this.form.name) < 0 || this.isEdited) {
    if (this.form.users && this.form.users.length > 0) {
     this.form.isComplete = true;
    }
    if (this.isEdited) {
     this.$emit("editGroup", this.form, this.idx, this.$t("informationSaved"));
    } else {
     this.$emit("addGroup", this.form);
    }
   } else {
    this.groupNameExist = true;
   }
  },
 },
 mounted() {
  this.editGroup();
 },
 watch: {
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.groupForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  form: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "groupForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
};
</script>
