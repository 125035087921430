<template>
 <div class="space-y-6">
  <div class="bg-white shadow mx-5 px-4 py-5 sm:rounded-lg sm:p-6">
   <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
     <h3 class="text-lg font-medium leading-6 text-gray-900">
      {{ $t("studioMessage") }}
     </h3>
     <p class="mt-1 text-sm text-gray-500">
      Renseignez ici les messages que vous nous mettrez à disposition ou à créer avec Encom Studio.
     </p>
     <p class="mt-1 text-sm text-gray-500">
      Pour les messages Encom Studio pensez à vous rapprocher de votre commercial pour obtenir votre
      devis personnalisé.
     </p>
     <p class="mt-1 text-sm text-gray-500">
      Si vous souhaitez avoir des messages en plusieurs langues, veuillez créer un message par
      langue.
     </p>
    </div>
    <div class="mt-0">
     <div class="flex justify-center">
      <button
       @click="
        (defaultMsg = ''),
         (editIdx = undefined),
         (editData = undefined),
         (showForm = !showForm),
         (isEdit = false)
       "
       type="button"
       class="inline-flex items-center p-1.5 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button focus:outline-none encom_ring_color"
      >
       <PlusIcon class="h-5 w-5" aria-hidden="true" />
      </button>
     </div>
    </div>
   </div>
   <studio-message-form-popup
    v-if="showForm"
    :key="editIdx"
    :messageAssociatedNumbers="messageAssociatedNumbers"
    :contentData="contentData"
    :createdSda="createdSda"
    :defaultMsg="defaultMsg"
    :defaultTitle="defaultTitle"
    :sdaTown="this.sdaTown"
    :fileId="this.fileId"
    :editData="editData"
    :editIdx="editIdx"
    :isEdit="isEdit"
    :queues="queues"
    :users="users"
    :svi="svi"
    :messagesNames="messagesNames"
    :bounceButton="bounceButton"
    :unsavedChangesBool="unsavedChangesBool"
    :elementRef="elementRef"
    @submitForm="submitForm($event)"
    @closeForm="(showForm = false), this.$emit('unsavedChanges', false, '')"
    @unsaved-changes="(...args) => $emit('unsavedChanges', ...args)"
   />
   <div v-show="userMessages.length > 0">
    <div class="flex justify-between">
     <!-- <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ $t("studioMessage") }}
          </h3> -->
    </div>
    <studio-messages-table
     :tablePaginatedData="filteredData"
     @editForm="openEditForm($event)"
     @deleteItem="(...args) => deleteItem(...args)"
    />
   </div>
  </div>

  <div v-if="!showForm" class="bg-white shadow mx-5 px-4 py-5 sm:rounded-lg sm:p-6">
   <h3 class="text-lg font-medium leading-6 text-gray-900">
    {{ $t("studioMessageExample") }}
   </h3>
   <div class="mt-2 md:grid md:grid-cols-4 md:gap-2">
    <div
     v-for="msg in msgEx"
     :key="msg.id"
     class="flex flex-col relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg"
    >
     <div class="bg-white p-6 flex flex-col justify-between">
      <p class="text-xl font-semibold text-gray-700">
       {{ msg.title }}
      </p>
      <div class="flex items-center mt-4 mb-4">
       <div class="ml-3">
        <p class="text-sm font-medium text-gray-500">
         {{ msg.message }}
        </p>
       </div>
      </div>
     </div>
     <button
      @click="addMessage(msg)"
      class="message_button px-1 py-0.5 border border-transparent rounded-md text-white encom_primary encom_primary_hover uppercase text-sm ml-4 mb-2"
     >
      {{ $t("useMessage") }}
     </button>
    </div>
   </div>
  </div>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"> </ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import StudioMessagesTable from "./StudioMessagesTable.vue";
import StudioMessageFormPopup from "./StudioMessageFormPopup.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

import { ExclamationIcon, PlusSmIcon, PlusIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
const modules = [
 { id: 0, name: "SmartRouting", active: 0 },
 { id: 1, name: "CustomerSatisfacionSurvey", active: 0 },
 { id: 2, name: "VoiceRecognition", active: 0 },
 { id: 3, name: "LiveWebChat", active: 0 },
 { id: 4, name: "WebCallBack", active: 0 },
 { id: 5, name: "PushSMS", active: 0 },
];
const msgEx = [
 {
  id: 0,
  message:
   "Bonjour et bienvenue chez encom. Veuillez patienter, nous allons donner suite à votre appel.",
  title: "Prédécroché classique",
 },
 {
  id: 1,
  message:
   "Bonjour et bienvenue chez Encom. Veuillez patienter, patienter nous allons donner suite à votre appel. Dans le cadre de notre qualité de service, votre appel est suceptible d'être écouté ou enregistré. Vous pouvez vous opposer à cet enregistrement en appuyant sur la touche 3 de votre téléphone, ou en le signalant auprès de votre conseiller.",
  title: "Prédécroché avec enregistrement",
 },
 {
  id: 2,
  message:
   "Bonjour et bienvenue chez Encom. Veuillez patienter nous allons prendre votre appel. Durant votre attente, vous pouvez demander le rappel automatique en appuyant sur le touche 2 de votre téléphone.",
  title: "Prédécroché avec demande de rappel",
 },
 {
  id: 3,
  message:
   "Bonjour et bienvenue chez Encom. Veuillez patienter, nous allons donner suite à votre appel. Pour améliorer notre qualité de service, votre appel est suceptible d'être écouté ou enregistré. Vous pouvez vous opposer à cet enregistrement en appuyant sur la touche 3 de votre téléphone, ou en le signalant auprès de votre conseiller. Durant votre attente, vous pouvez demander le rappel automatique en appuyant sur le touche 2 de votre téléphone.",
  title: "Prédécroché avec enregistrement et demande de rappel",
 },
];
const userMessages = [];
export default {
 props: [
  "fileId",
  "contentData",
  "queues",
  "users",
  "svi",
  "createdSda",
  "sdaTown",
  "search",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
 ],
 components: {
  AskConfirmationDialog,
  StudioMessageFormPopup,
  StudioMessagesTable,
  ExclamationIcon,
  PlusSmIcon,
  PlusIcon,
 },
 data() {
  return {
   showForm: false,
   modules,
   msgEx,
   userMessages,
   defaultMsg: "",
   defaultTitle: "",
   editData: undefined,
   editIdx: undefined,
   stepName: "studio",
   isEdit: false,
  };
 },
 methods: {
  getData() {
   let keys = Object.keys(this.contentData);

   if (keys.includes(this.stepName)) {
    this.userMessages = this.contentData[this.stepName];
   }
  },
  sendForm(msg) {
   let stepContent = this.userMessages;

   let content = this.contentData;

   let queueContent = [];
   if (this.contentData.callQueue && this.contentData.callQueue.length > 0) {
    queueContent = this.contentData.callQueue;
   }
   if (stepContent && stepContent.length > 0) {
    for (let index = 0; index < stepContent.length; index++) {
     const element = stepContent[index];
     if (element.associatedQueue && element.associatedQueue.length > 0) {
      for (let index = 0; index < element.associatedQueue.length; index++) {
       const elementQueue = element.associatedQueue[index];
       queueContent.map((res) => {
        if (res.name === elementQueue) {
         res.predecroche.value = element.name;
         res.predecroche.type = 1;
         res.predecrocheMusic.value = element.music;
         res.predecrocheMusic.type = 1;
        } else {
         res.predecroche.value = "";
         res.predecroche.type = 0;
         res.predecrocheMusic.value = "";
         res.predecrocheMusic.type = 0;
        }
       });
      }
     }
    }
   } else {
    queueContent.map((res) => {
     res.predecroche.value = "";
     res.predecroche.type = 0;
     res.predecrocheMusic.value = "";
     res.predecrocheMusic.type = 0;
    });
    // queueContent.map((res) => {
    //   res.predecrocheMusic.value = "";
    //   res.predecrocheMusic.type = 0;
    // });
   }

   content[this.stepName] = stepContent;

   content = JSON.stringify(content);

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  openEditForm(idx) {
   if (this.userMessages.length > 0) {
    if (this.editIdx === idx) {
     this.showForm = false;
     this.isEdit = false;
     this.editIdx = null;
    } else {
     this.showForm = true;
     this.isEdit = true;
     this.editIdx = idx;
     this.editData = this.userMessages[idx];
    }
   }
  },
  async deleteItem(...args) {
   let idx = args[0];
   let confirmationMessage = args[1];
   let title = args[2];
   let msg = args[3];
   let confirmButton = args[4];
   let goBack = args[5];
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.userMessages.splice(idx, 1);
    this.sendForm(msg);
   }
  },
  submitForm(event) {
   if (this.editIdx >= 0) {
    this.userMessages.splice(this.editIdx, 1, event);
   } else {
    this.userMessages.push(event);
   }
   this.sendForm(this.$t("informationSaved"));
   this.showForm = false;
  },
  addMessage(msg) {
   this.defaultMsg = msg.message;
   this.defaultTitle = msg.title;
   this.showForm = true;
  },
  async getFile() {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/documents`, {
     params: {
      customerAccount: this.account,
      documentable_type: "App\\InformationCollection",
      documentable_id: this.fileId,
     },
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    const data = res.data;
    const arrWithData = [];

    for (let index = 0; index < data.length; index++) {
     const document = data[index];
     const documentName = document.fileDescription.split("_");
     const documentType = documentName[0];
     const documentIndex = documentName[1];

     const obj = {
      name: "",
      people: [],
     };

     if (data.fileDescription !== "message studio") {
      obj.name = "Dossier" + " " + documentIndex;

      //look into the data object and get the object with the condition :  documentIndex === obj.name.split(" ")[1])
      documentIndex === obj.name.split(" ")[1] ? obj.people.push(document) : "";

      arrWithData.push(obj);
      documentType === "mandate"
       ? (this.mandateIdx = parseInt(documentIndex))
       : (this.invoiceIdx = parseInt(documentIndex));
     }
    }

    var output = new Array();
    arrWithData.forEach((elem) => {
     var found = false;
     for (var i = 0; i < output.length; i++) {
      if (output[i].name == elem.name) {
       output[i].people = [...output[i].people, ...elem.people];
       var found = true;
       break;
      }
     }
     if (!found) {
      output.push(elem);
     }
    });

    this.documents = output;
   } catch (error) {
    console.error(error.response);
   }
  },
 },
 mounted() {
  this.getData();
  this.getFile();
 },
 computed: {
  filteredData() {
   return this.userMessages.filter((res) => {
    if (this.search) {
     return (
      res.name.toLowerCase().includes(this.search.toLowerCase()) ||
      res.message.toLowerCase().includes(this.search.toLowerCase())
     );
    } else {
     return 1;
    }
   });
  },
  messagesNames() {
   let messages = [];
   if (this.contentData && this.contentData.studio) {
    this.contentData.studio.forEach((element) => {
     messages.push(element.name);
    });
   }
   return messages;
  },
  messageAssociatedNumbers() {
   let messages = [];
   let associatedNumbers = [];
   if (this.contentData && this.contentData.studio && this.contentData.studio.length > 0) {
    messages = this.contentData.studio;
   }
   for (let index = 0; index < messages.length; index++) {
    const element = messages[index];
    if (element.associatedQueue && element.associatedQueue.length > 0) {
     for (let index = 0; index < element.associatedQueue.length; index++) {
      const elementQueue = element.associatedQueue[index];
      associatedNumbers.push(elementQueue);
     }
    }
    if (element.associatedExtension && element.associatedExtension.length > 0) {
     for (let index = 0; index < element.associatedExtension.length; index++) {
      const elementExtension = element.associatedExtension[index];
      associatedNumbers.push(elementExtension);
     }
    }
   }

   return associatedNumbers;
  },
  ...mapGetters(["account"]),
 },
};
</script>

<style scoped>
.message_button {
 position: absolute;
 left: 0;
 bottom: 0;
}
</style>
