const xmlExtensionFile = {
  Extension: {
    AuthID: "",
    AuthPassword: "",
    BreakTime: "",
    BusyDetection: "",
    CurrentProfile: "",
    DeliverAudio: "",
    EmailAddress: "",
    Enabled: "",
    SRTPMode: "",
    FirstName: "",
    ForwardingRules: "",
    FwdProfiles: {
      FwdProfile: [
        {
          AwayRoute: {
            Internal: {
              AllHours: {
                To: "",
                Internal: "",
              },
              OutOfOfficeHours: {
                To: "",
                Internal: "",
              },
            },
            External: {
              AllHours: {
                To: "",
                Internal: "",
              },
              OutOfOfficeHours: {
                To: "",
                Internal: "",
              },
            },
          },
          BlockPushCalls: "",
          BusyDetection: "",
          DisableRingGroupCalls: "",
          ForceChatStatus: "",
          ForceInternal: "",
          ForceQueueStatus: "",
          Name: "",
          NoAnswerTimeout: "",
          RingMyMobile: "",
        },
      ],
    },
    HidePresence: "",
    Internal: "",
    LastName: "",
    NoAnswerTimeout: "",
    PhoneDevices: "",
    QueueStatus: "",
    RecordCalls: "",
    SupportReinvite: "",
    SupportReplaces: "",
    UserStatus: "",
    VMEmailOptions: "",
    VMEnabled: "",
    VMPIN: "",
    VMPlayCallerID: "",
    VMPlayMsgDateTime: "",
    ContactImage: "",
    Number: "",
    PhoneBookEntries: "",
    Properties: {
      DNProperty: [
        {
          Name: "",
          Type: "",
          Value: "",
        },
      ],
    },
    Hours: "",
  },
};
export { xmlExtensionFile };
