<template>
 <div class="sm:flex sm:items-center">
  <div class="sm:flex-auto">
   <h3 class="capitalize text-lg font-medium leading-6 text-gray-900">
    {{ $t("users") }}
   </h3>
   <p class="mt-2 text-sm text-gray-700">{{ $t("usersList") }}.</p>
  </div>
  <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
   <button
    type="button"
    v-show="users.length > 0"
    class="inline-flex items-center p-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="(addUser = !addUser), (isEdited = false)"
   >
    <PlusIcon class="h-5 w-5" aria-hidden="true" />
   </button>
  </div>
 </div>
 <div v-show="addUser">
  <CollectAddUsersToGroup
   :key="userIdx"
   :userData="userData"
   :userForm="userForm"
   :userIdx="userIdx"
   :addUser="addUser"
   :isEdited="isEdited"
   :contentData="contentData"
   :users="users"
   :saveForm="saveForm"
   :collectType="collectType"
   @add-user-in-group="(...args) => this.$emit('addUserInGroup', ...args)"
   @cancel-form="(addUser = $event), (isEdited = $event)"
  />
 </div>
 <div v-show="isEdited">
  <CollectAddUsersToGroup
   :key="userIdx"
   :userData="userData"
   :userForm="userChosen"
   :userIdx="userIdx"
   :addUser="addUser"
   :isEdited="isEdited"
   :users="users"
   :contentData="contentData"
   :collectType="collectType"
   @edit-user-in-group="(...args) => this.$emit('editUserInGroup', ...args)"
   @cancel-form="(addUser = $event), (isEdited = $event)"
  />
 </div>
 <div v-if="this.users && this.users.length > 0" class="mt-2 flex flex-col">
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <th
         v-for="title in tableHeaders"
         :key="title"
         scope="col"
         :class="[
          title.name === tableHeaders[0].name ? 'pl-4 sm:pl-6 pr-3' : 'hidden px-3 lg:table-cell',
          'py-3 text-left text-xs font-semibold text-gray-900 uppercase',
         ]"
        >
         {{ title.name }}
         <font-awesome-icon
          v-if="title.icon"
          :icon="title.icon"
          class="text-blue-400 h-4 w-4 icon alt"
          :title="title.description"
         />
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
         <span class="sr-only">Edit</span>
        </th>
       </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
       <tr
        v-for="(user, idx) in users"
        :key="user.email"
        :class="idx % 2 === 0 ? undefined : 'bg-gray-50'"
       >
        <td
         class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6"
        >
         {{ user.extension }} -
         <span v-if="user.extensionName">{{ user.extensionName }}</span>
         <span v-else class="text-gray-500">Extension existante</span>
         <dl class="font-normal lg:hidden">
          <dt class="sr-only sm:hidden">Name</dt>
          <dd class="mt-1 truncate text-gray-500 sm:hidden">
           {{ user.firstName }} {{ user.lastName }}
          </dd>
          <dt class="sr-only">Email</dt>
          <dd class="mt-1 truncate text-gray-700">
           {{ user.email }}
          </dd>
         </dl>
        </td>
        <td class="px-3 py-4 text-sm truncate text-gray-500">
         <span
          v-if="user.canSeeGroupMembers"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
         <span
          v-if="user.canSeeGroupCalls"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
         <span
          v-if="user.sprayPresenceGroup"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
         <span
          v-if="user.showCallsToGroup"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
         <span
          v-if="user.canActListenWhisper"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
         <span
          v-if="user.canUseGeneralIntercomCall"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
         <span
          v-if="user.canParkCalls"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm sm:table-cell">
         <span
          v-if="user.canEditCompanyDirectory"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
         <span
          v-if="user.adminRight"
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
          </svg>
         </span>
         <span
          v-else
          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
         </span>
        </td>
        <td class="p-4 whitespace-nowrap text-right text-sm font-medium flex justify-end">
         <button
          class="rounded p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
          @click="(userIdx = idx), (userChosen = user), (isEdited = true), (addUser = false)"
          type="button"
          :title="$t('edit')"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           viewBox="0 0 20 20"
           fill="currentColor"
          >
           <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
           />
           <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
           />
          </svg>
         </button>
         <a
          @click="this.$emit('deleteUserInGroup', idx, user.extensionName)"
          href="javascript:void(0)"
          :title="$t('delete')"
          class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           viewBox="0 0 20 20"
           fill="currentColor"
          >
           <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
           />
          </svg>
         </a>
        </td>
       </tr>
      </tbody>
     </table>
    </div>
   </div>
  </div>
 </div>
 <div v-else-if="!addUser" class="text-center max-w-lg mx-auto">
  <svg
   class="mx-auto h-12 w-12 text-gray-400"
   fill="none"
   stroke="currentColor"
   viewBox="0 0 48 48"
   aria-hidden="true"
  >
   <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
   />
  </svg>

  <div class="mt-3">
   <button
    type="button"
    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="(addUser = !addUser), (isEdited = false)"
   >
    <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
    {{ $t("addUser") }}
   </button>
  </div>
 </div>
</template>

<script>
import SearchExtensionsGroup from "../SearchExtensionsGroup.vue";
import CollectAddUsersToGroup from "./CollectAddUsersToGroup.vue";
import { PlusIcon } from "@heroicons/vue/solid";
const account = localStorage.getItem("account");

export default {
 name: "CollectTable",
 props: ["tableHeaders", "users", "fileId", "userData", "contentData", "collectType"],
 components: { PlusIcon, SearchExtensionsGroup, CollectAddUsersToGroup },
 data() {
  return {
   account,
   addUser: false,
   isEdited: false,
   userChosen: {},
   userIdx: null,
   userSelected: "",
   userForm: {
    adminRight: false,
    extension: null,
    extensionName: "",
    canSeeGroupMembers: true,
    canSeeGroupCalls: false,
    canUseGeneralIntercomCall: false,
    canParkCalls: false,
    canEditCompanyDirectory: false,
    canActListenWhisper: false,
    sprayPresenceGroup: true,
    showCallsToGroup: true,
    isComplete: true,
   },
  };
 },

 methods: {
  /*   emitForm() {
      if (this.isEdited) {
        if (this.userForm.extension) {
          this.$emit(
            "editUserInGroup",
            this.userForm,
            this.userIdx,
            "Information saved"
          );
        } else {
          console.log("err");
        }
      } else {
        if (this.userForm.extension) {
          this.$emit("addUserInGroup", this.userForm);
        } else {
          console.log("err");
        }
      }

      // this.clearForm();
    }, 
    setUserFormExt(event) {
       var val = event.split(" - ");
      this.userSelected = event;
      this.userForm.extension = val[0];
      this.userForm.extensionName = val[1];
    }, 
    clearForm() {
      this.userForm.adminRight = false;
      this.userForm.extension = null;
      this.userForm.extensionName = "";
      this.userForm.canSeeGroupMembers = false;
      this.userForm.canSeeGroupCalls = false;
      this.userForm.canUseGeneralIntercomCall = false;
      this.userForm.canParkCalls = false;
      this.userForm.canEditCompanyDirectory = false;
      this.userForm.canActListenWhisper = false;
      this.userForm.sprayPresenceGroup = false;
      this.userForm.showCallsToGroup = false;
    },*/
 },
 watch: {
  addUser: function (bool) {
   this.$emit("isGroupAddUser", bool);
  },
  isEdited: function (bool) {
   this.$emit("isGroupEdited", bool);
  },
 },
};
</script>
