<template>
 <form @submit.prevent="emitForm()" method="POST">
  <div class="mt-2 bg-white px-4 py-5 sm:rounded-lg sm:p-6 space-y-8 divide-gray-200">
   <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1">
     <h3 class="text-lg font-medium leading-6 text-gray-900">Membres du groupe</h3>
     <p class="mt-1 text-sm text-gray-500">
      Appliquer la même configuration à la sélection
      <span class="italic">
       (les fonctionnalités les plus communes ont été sélectionnées par défaut)
      </span>
     </p>
    </div>

    <div class="mt-5 md:mt-0 md:col-span-2">
     <div class="grid grid-cols-6 gap-6">
      <div class="col-span-6 sm:col-span-3">
       <div class="flex justify-between mb-2">
        <label for="userSelected" class="block text-sm font-medium text-gray-700">
         Numéro de l'extension
        </label>
        <div v-if="collectType === 'newSite'" class="flex items-center justify-between">
         <Switch
          v-model="enabled"
          class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
         >
          <span class="sr-only">Use setting</span>
          <span
           aria-hidden="true"
           class="pointer-events-none absolute h-full w-full rounded-md bg-white"
          />
          <span
           aria-hidden="true"
           :class="[
            enabled ? 'encom_primary' : 'bg-gray-200',
            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
           ]"
          />
          <span
           aria-hidden="true"
           :class="[
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
           ]"
          />
         </Switch>
         <span class="ml-2 text-xs font-medium text-gray-600" passive>Extension existante</span>
        </div>
       </div>
       <SearchExtensionsGroup
        v-if="addUser && !enabled"
        :data="filteredGroupElements"
        placeholder="Sélectionner un ou plusieurs membres"
        :isMultiple="true"
        :closeOnSelect="false"
        @action="setUserFormExt($event)"
        :selected="userSelected"
       />

       <input
        v-else-if="enabled"
        v-model="userForm.extension"
        type="tel"
        name="userSelected"
        id="userSelected"
        :placeholder="this.$t('existingExtension')"
        class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
       />
       <SearchExtensionsGroup
        v-else
        :addUserToGroup="true"
        :data="userData"
        :placeholder="$t('header.search')"
        :isMultiple="false"
        :closeOnSelect="true"
        @action="setUserFormExt($event)"
        :selected="userForm.extension + ' - ' + userForm.extensionName"
       />
      </div>
     </div>
    </div>
   </div>

   <div class="md:grid md:grid-cols-4 md:gap-6">
    <div class="md:col-span-1">
     <h3 class="text-lg font-medium leading-6 text-gray-900">Droits de l'utilisateur</h3>
     <p class="mt-1 text-sm text-gray-500">
      Gérer les droits de lecture et d'actions de l'utilisateur dans ce groupe.
     </p>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-1">
     <div class="space-x-6 flex">
      <fieldset class="space-y-5">
       <legend class="sr-only">Notifications</legend>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canSeeGroupMembers"
          id="canSeeGroupMembers"
          aria-describedby="canSeeGroupMembers-description"
          name="canSeeGroupMembers"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canSeeGroupMembers" class="font-medium text-gray-700">
          Peut voir les membres de son groupe
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canSeeGroupCalls"
          id="canSeeGroupCalls"
          aria-describedby="canSeeGroupCalls-description"
          name="canSeeGroupCalls"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canSeeGroupCalls" class="font-medium text-gray-700">
          Peut voir les Numéros appelés par les membres du groupe
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.sprayPresenceGroup"
          id="sprayPresenceGroup"
          aria-describedby="sprayPresenceGroup-description"
          name="sprayPresenceGroup"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="sprayPresenceGroup" class="font-medium text-gray-700">
          Diffuse sa présence au groupe
         </label>
        </div>
       </div>
      </fieldset>
     </div>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-1 md:col-start-3">
     <div class="space-x-6 flex">
      <fieldset class="space-y-5">
       <legend class="sr-only">Notifications</legend>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.showCallsToGroup"
          id="showCallsToGroup"
          aria-describedby="showCallsToGroup-description"
          name="showCallsToGroup"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="showCallsToGroup" class="font-medium text-gray-700">
          Montre ses appels au groupe
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canActListenWhisper"
          id="canActListenWhisper"
          aria-describedby="canActListenWhisper-description"
          name="canActListenWhisper"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canActListenWhisper" class="font-medium text-gray-700">
          Peut intervenir, écouter, chuchotter
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canUseGeneralIntercomCall"
          id="canUseGeneralIntercomCall"
          aria-describedby="canUseGeneralIntercomCall-description"
          name="canUseGeneralIntercomCall"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canUseGeneralIntercomCall" class="font-medium text-gray-700">
          Peut utiliser l'appel Général interphonie
         </label>
         <p id="canUseGeneralIntercomCall-description" class="text-gray-500">
          Permet de déclencher un appel vers un poste ou un groupe de poste en décroché automatique
          sur haut parleur.
         </p>
        </div>
       </div>
      </fieldset>
     </div>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-1 md:col-start-4">
     <div class="space-x-6 flex">
      <fieldset class="space-y-5">
       <legend class="sr-only">Notifications</legend>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canParkCalls"
          id="canParkCalls"
          aria-describedby="canParkCalls-description"
          name="canParkCalls"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canParkCalls" class="font-medium text-gray-700">
          Peut parquer les appels
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.canEditCompanyDirectory"
          id="canEditCompanyDirectory"
          aria-describedby="canEditCompanyDirectory-description"
          name="canEditCompanyDirectory"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="canEditCompanyDirectory" class="font-medium text-gray-700">
          Peut modifier l'annuaire d'entreprise
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="userForm.adminRight"
          id="adminRight"
          aria-describedby="adminRight-description"
          name="adminRight"
          type="checkbox"
          class="encom_border_color h-4 w-4 encom_primary_text border-gray-300 rounded"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="adminRight" class="font-medium text-gray-700">
          Effectue des operations Administrateurs
         </label>

         <ul id="adminRight-description" class="text-gray-500">
          <li>- Assigner/Désassigner une extension</li>
          <li>- Changer le statut des utilisateurs (Dispo, Absent, Ne pas déranger , etc...)</li>
          <li>- Programmer le reveil matin</li>
         </ul>
        </div>
       </div>
      </fieldset>
     </div>
    </div>
   </div>
  </div>

  <div class="pt-2 flex justify-end">
   <button
    type="button"
    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none encom_ring_color"
    @click="this.$emit('cancelForm', false)"
   >
    Cancel
   </button>
   <button
    type="submit"
    class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
   >
    {{ isEdited ? $t("edit") : $t("add") }}
    <span class="lowercase ml-1"> {{ $t("users") }}</span>
   </button>
  </div>
 </form>
</template>

<script>
import SearchExtensionsGroup from "../SearchExtensionsGroup.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/solid";
const account = localStorage.getItem("account");

export default {
 name: "CollectAddUsersToGroup",
 props: [
  "userData",
  "addUser",
  "isEdited",
  "userIdx",
  "userChosen",
  "userForm",
  "contentData",
  "users",
  "collectType",
  "saveForm",
 ],
 components: { PlusIcon, SearchExtensionsGroup, Switch, SwitchGroup, SwitchLabel },
 data() {
  return {
   enabled: false,
   account,
   userSelected: "",
   form: {
    adminRight: false,
    extension: null,
    extensionName: "",
    canSeeGroupMembers: true,
    canSeeGroupCalls: false,
    canUseGeneralIntercomCall: false,
    canParkCalls: false,
    canEditCompanyDirectory: false,
    canActListenWhisper: false,
    sprayPresenceGroup: true,
    showCallsToGroup: true,
    isComplete: true,
   },
  };
 },

 methods: {
  emitForm() {
   this.form = { ...this.userForm };
   if (!this.form.extension) {
    console.error("Extension is not available");
    return;
   }

   const event = this.isEdited ? "editUserInGroup" : "addUserInGroup";
   const message = this.isEdited ? "Information saved" : this.userSelected;
   const params = this.isEdited ? [this.form, this.userIdx, message] : [this.form, message];

   this.$emit(event, ...params);
  },
  setUserFormExt(event) {
   this.userSelected = event;
   if (this.userSelected.length === 1) {
    var val = this.userSelected[0].split(" - ");
    this.userForm.extension = val[0];
    this.userForm.extensionName = val[1];
   }
  },
  clearForm() {
   this.form.adminRight = false;
   this.form.extension = null;
   this.form.extensionName = "";
   this.form.canSeeGroupMembers = true;
   this.form.canSeeGroupCalls = false;
   this.form.canUseGeneralIntercomCall = false;
   this.form.canParkCalls = false;
   this.form.canEditCompanyDirectory = false;
   this.form.canActListenWhisper = false;
   this.form.sprayPresenceGroup = true;
   this.form.showCallsToGroup = true;
  },
 },
 watch: {
  saveForm: function (bool) {
   bool && this.emitForm();
  },
 },
 computed: {
  filteredGroupElements() {
   let data = this.userData;
   let selectedElements = [];
   if (this.users && this.users.length > 0) {
    let groupData = this.users;
    for (let index = 0; index < groupData.length; index++) {
     const element = groupData[index];
     let elementName = element["extension"] + " - " + element["extensionName"];
     selectedElements.push(elementName);
    }
    data = data.filter((res) => {
     return selectedElements.indexOf(res) < 0;
    });
   }

   return data;
  },
 },
};
</script>
