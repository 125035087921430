<template>
 <form @submit.prevent="onSubmit()" method="POST">
  <div class="space-y-6 mt-2">
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("mainInfos") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="svi-name" class="block text-sm font-bold text-gray-700 mt-2">
         {{ $t("ivsName") }}*
        </label>
        <input
         v-model="formName"
         type="text"
         name="svi-name"
         id="svi-name"
         required
         class="mt-0 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
        <div v-show="messageNameInvalid" class="rounded-md bg-red-50 p-2 mt-3">
         <div class="flex">
          <div class="ml-3">
           <h3 class="text-sm font-medium text-red-800 mt-px">{{ $t("messageNameInvalid") }}.</h3>
          </div>
         </div>
        </div>
       </div>
       <div class="col-span-6 sm:col-span-4">
        <SdaLinkedSelect
         :sdaNumberList="this.form.associateNumber"
         :createdSda="createdSda"
         :sdaLeft="sdaLeft"
         :sdaMax="sdaMax"
         :range="range"
         :isolatedNumbers="isolatedNumbers"
         :sdaTown="sdaTown"
         :customerFileId="customerFileId"
         :fileId="fileId"
         :errorAddedSda="errorAddedSda"
         :errorSdaFound="errorSdaFound"
         :errorIsInside="errorIsInside"
         :parentFormError="isError"
         :navIdx="navIdx"
         :notBundledNumbers="otherNumbers"
         :collectType="collectType"
         @remove-sda="this.removeSda($event)"
         @remove-element="removeElement()"
         @create-new-number="this.createNewNumber($event)"
         @check-validate-sda="this.checkValidateSda($event)"
         @check-carried-number="this.checkCarriedNumber($event)"
        />

        <p v-if="isError" class="ml-1 text-sm text-red-600">{{ $t("sviSdaLinkedError") }}.</p>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("strategyOpeningHours") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 space-y-6">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
         <div>
          <label for="open-time" class="block text-sm font-bold text-gray-700">
           {{ $t("openTimeSlot") }}*
          </label>
          <p class="block text-xs text-gray-400">
           {{ $t("openTimeSlotDetail") }}
          </p>
         </div>
         <button
          @click="(openTimeSlotForm = true), (isActive = true)"
          type="button"
          class="capitalize rounded inline-flex items-center px-2 py-2 border border-transparent text-sm font-medium text-white body__button focus:outline-none"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-5 w-5 mr-1"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2"
          >
           <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          {{ $t("callqueueTable.addTimeSlot") }}
         </button>
        </div>
       </div>

       <div v-if="form.openingDaysAndTime.length > 0" class="col-span-6 sm:col-span-6">
        <div class="overflow-y-hidden border rounded-md">
         <CollectTimeSlotTable
          :tablePaginatedData="form.openingDaysAndTime"
          @deleteList="deteleTimeSpan($event)"
         />
        </div>
       </div>
      </div>

      <div class="col-span-6 sm:col-span-4">
       <label for="msg-audio" class="block text-sm font-bold text-gray-700">
        {{ $t("audioMessageNumber") }}*
       </label>
       <p class="text-sm text-gray-500">
        {{ $t("prehookDescription") }}
       </p>
       <select
        v-model="form.audioMessageNumber"
        id="type"
        name="type"
        class="w-1/2 col-span-5 mt-0 capitalize shadow-sm sm:text-sm border-gray-300 rounded-md"
       >
        <option value="">
         {{ $t("none") }}
        </option>
        <option
         v-for="msg in getRecordedMsgs()"
         :key="msg.message"
         :value="msg.message ? msg.message : msg.file.fileName"
        >
         {{ msg.name }}
        </option>
       </select>
      </div>

      <div class="col-span-6 sm:col-span-4">
       <label for="msg-audio" class="block text-sm font-medium text-gray-700">
        {{ $t("sviChoice") }}
       </label>
       <div class="mt-1 relative flex items-start">
        <div class="flex items-center">
         <ToggleWithIcon
          :validated="form.bindingKeysOption"
          @boolean-toggle="form.bindingKeysOption = $event"
         />
        </div>
        <div class="ml-3 text-sm mt-px">
         <label for="comments" class="text-gray-500">
          {{ form.bindingKeysOption ? $t("endCallAfterMessageNo") : $t("endCallAfterMessageYes") }}
         </label>
        </div>
       </div>
      </div>
      <div v-if="form.bindingKeysOption" class="col-span-6 sm:col-span-4">
       <ul role="list" class="my-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <li
         v-for="(choice, index) in this.form.choice"
         :key="index"
         class="col-span-1 flex shadow-sm rounded-md"
        >
         <div
          class="flex-1 flex items-center justify-between border border-gray-200 bg-gray-50 rounded-md truncate"
         >
          <div class="flex-1 px-4 py-2 text-sm truncate">
           <p class="text-gray-900 font-medium">
            {{ $t("pressDialFor", { dial: choice.dial }) }}
            {{ getResponseType(choice.responseType) }}
           </p>
           <p class="text-gray-600">{{ choice.desc }}</p>
          </div>
          <div class="flex-shrink-0 pr-2">
           <button
            type="button"
            @click="this.deleteNewRange(index)"
            class="w-8 h-8 bg-white inline-flex items-center justify-center text-red-400 rounded-full bg-transparent hover:text-red-500"
           >
            <span class="sr-only">Open options</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </li>
       </ul>

       <label for="choice-one" class="block text-sm text-gray-700">
        <span class="font-bold"> {{ $t("workingTimeDestination") }}*</span>
        <Tooltip
         tooltip1="Séléctionnez les choix proposés au téléphone pour se faire guider (e.g Tapez 1 pour l'acceuil, etc)"
        />
       </label>
       <div class="flex space-x-2 grid grid-cols-10">
        <select
         v-model="choiceForm.dial"
         id="type"
         name="type"
         class="mt-0 shadow-sm sm:text-sm border-gray-300 rounded-md"
        >
         <option v-for="dial in dialChoices" :key="dial.id" :value="dial.desc">
          {{ dial.desc }}
         </option>
        </select>
        <select
         v-model="choiceForm.responseType"
         id="type"
         name="type"
         class="col-span-3 mt-0 shadow-sm sm:text-sm border-gray-300 rounded-md"
        >
         <option v-for="element in filteredChoiceList" :key="element.id" :value="element.id">
          {{ element.desc }}
         </option>
        </select>
        <div class="col-span-4">
         <SearchExtensionsGroup
          v-if="choiceForm.responseType == 0"
          :data="queues"
          :placeholder="$t('header.search')"
          :isMultiple="false"
          :closeOnSelect="true"
          @action="choiceForm.desc = $event"
          :selected="choiceForm.desc"
         />
         <SearchExtensionsGroup
          v-else-if="choiceForm.responseType == 1 || choiceForm.responseType == 2"
          :data="users"
          :placeholder="$t('header.search')"
          :isMultiple="false"
          :closeOnSelect="true"
          @action="choiceForm.desc = $event"
          :selected="choiceForm.desc"
         />
         <SearchExtensionsGroup
          v-else-if="choiceForm.responseType == 3"
          :data="svi"
          :placeholder="$t('header.search')"
          :isMultiple="false"
          :closeOnSelect="true"
          @action="choiceForm.desc = $event"
          :selected="choiceForm.desc"
         />

         <input
          v-else
          v-model="choiceForm.desc"
          @change="
           checkValidatePhoneNumber(
            choiceForm.desc,
            choiceForm.responseType === 5 ? 'extension' : null
           )
          "
          type="tel"
          name="choice-one"
          id="choice-one"
          :maxlength="choiceForm.responseType === 5 ? '4' : '16'"
          :placeholder="
           choiceForm.responseType === 5 ? this.$t('newSitePlaceholder') : '0198765432'
          "
          :class="[
           (errorPhoneLength || errorPhoneFormat) && choiceForm.desc
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-0 col-span-3 w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
        </div>

        <p
         v-show="(errorPhoneLength || errorPhoneFormat) && choiceForm.desc"
         class="col-start-5 col-span-4 pl-2 text-sm text-red-600"
        >
         {{
          $t("pleaseUseFormat", {
           n: form.nonWorkingTimeDestination.type === 5 ? "123 ou 1234" : "0198765432",
          })
         }}.
        </p>
        <div class="row-start-1 col-start-9 self-center">
         <button
          @click="addNewChoice(choiceForm)"
          type="button"
          class="inline-flex items-center p-1 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button focus:outline-none encom_ring_color"
         >
          <PlusIcon class="h-5 w-5" aria-hidden="true" />
         </button>
        </div>
       </div>

       <p v-show="errorExistedDial || errorExistedDesc" class="pl-2 text-sm text-red-600">
        {{ errorExistedDial ? $t("dialAlreadyUsed") : $t("descAlreadyUsed") }}.
       </p>
      </div>
     </div>
    </div>
   </div>
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("strategyClosingHours") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 space-y-6">
      <div class="col-span-6 sm:col-span-4">
       <label for="out-work-time" class="text-sm font-semibold text-gray-700">
        {{ $t("nonWorkingTimeDestination") }}*
       </label>
       <Tooltip
        tooltip1="Séléctionnez la destination des appels aux heures de fermeture du SVI : "
        tooltipList1="REPONDEUR : l’appel sera dirigé sur le répondeur d'une extension. Sélectionner à droite le numéro d'extension du répondeur"
        tooltipList2="REPONDEUR SIMPLE : l’appel sera dirigé sur un répondeur SANS offrir la possibilité de laisser un message.Sélectionner à droite le message audio correspondant."
        tooltipList3="FILE  : l’appel sera dirigé sur une File d’attente. Sélectionner à droite la File. NUMERO EXTERNE : l’appel sera dirigé vers un numéro externe à l’entreprise. Indiquez à droite le Numéro externe."
        tooltipList4="SVI : le choix SVI permet de créer un SVI multi-niveaux. Indiquez à droite le SVI secondaire."
        :list="true"
       />
       <p v-show="form.nonWorkingTimeDestination.type === 5" class="text-sm text-gray-500">
        Saisissez une extension existante valide de votre 3CX.
       </p>
       <span
        v-if="
         form.nonWorkingTimeDestination.number &&
         (form.nonWorkingTimeDestination.type != 3 ||
          form.nonWorkingTimeDestination.type != 1 ||
          form.nonWorkingTimeDestination.type != 5)
        "
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
       >
        {{ form.nonWorkingTimeDestination.number }}
        <a
         :title="$t('delete')"
         class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
         @click="form.nonWorkingTimeDestination.number = ''"
        >
         <span class="sr-only">Remove sda number</span>
         <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
         </svg>
        </a>
       </span>
       <div class="flex space-x-4 grid grid-cols-10">
        <select
         v-model="form.nonWorkingTimeDestination.type"
         required
         id="type"
         name="type"
         class="col-span-5 mt-0 shadow-sm sm:text-sm border-gray-300 rounded-md"
        >
         <option
          v-for="element in filteredNonWorkingTimeDestinationList"
          :key="element.id"
          :value="element.id"
         >
          {{ element.desc }}
         </option>
        </select>

        <div
         v-if="
          form.nonWorkingTimeDestination.type == 0 ||
          form.nonWorkingTimeDestination.type == 1 ||
          form.nonWorkingTimeDestination.type == 2
         "
         class="col-span-4 mt-1"
        >
         <SearchSelector
          :key="$route.name"
          :selected="form.nonWorkingTimeDestination.number"
          :searchData="
           form.nonWorkingTimeDestination.type == 0
            ? users
            : form.nonWorkingTimeDestination.type == 2
            ? queues
            : svi
          "
          @addElement="form.nonWorkingTimeDestination.number = $event[0]"
         />
        </div>

        <!--   <div v-else-if="form.nonWorkingTimeDestination.type == 1" class="col-span-4">
         <select
          v-model="form.nonWorkingTimeChoice"
          id="type"
          name="type"
          class="shadow-sm sm:text-sm border-gray-300 rounded-md"
         >
          <option v-for="(msg, idx) in getRecordedMsgs()" :key="idx" :value="msg.message">
           {{ msg.name }}
          </option>
         </select>
         <p class="mt-1 text-sm text-gray-500">
          {{ $t("prehookDescription") }}
         </p>
        </div> -->
        <div
         v-else-if="
          form.nonWorkingTimeDestination.type == 3 || form.nonWorkingTimeDestination.type == 5
         "
         class="col-span-4"
        >
         <input
          v-model="form.nonWorkingTimeChoice"
          @change="
           checkValidatePhoneNumber(
            form.nonWorkingTimeChoice,
            form.nonWorkingTimeDestination.type === 5 ? 'extension' : null
           )
          "
          type="tel"
          name="choice-one"
          id="choice-one"
          :maxlength="form.nonWorkingTimeDestination.type === 5 ? '4' : '16'"
          :placeholder="
           form.nonWorkingTimeDestination.type === 5 ? this.$t('newSitePlaceholder') : '0198765432'
          "
          :class="[
           (errorPhoneLength || errorPhoneFormat) && form.nonWorkingTimeChoice
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-0 col-span-3 w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
        </div>

        <p
         v-show="(errorPhoneLength || errorPhoneFormat) && form.nonWorkingTimeChoice"
         class="col-start-6 col-span-4 pl-2 text-sm text-red-600"
         id="email-error"
        >
         {{
          $t("pleaseUseFormat", {
           n: form.nonWorkingTimeDestination.type === 5 ? "123 ou 1234" : "0198765432",
          })
         }}.
        </p>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("comment") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">{{ $t("callQueueAndSviComment") }}.</p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div>
       <textarea
        v-model="form.comment"
        rows="5"
        name="comment"
        id="comment"
        class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
        :placeholder="$t('addYourComment')"
       />
      </div>
     </div>
    </div>
   </div>

   <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6" ref="sviForm">
    <div class="mt-5 flex justify-end">
     <button
      @click="$emit('closeForm')"
      type="button"
      class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
     >
      {{ $t("cancel") }}
     </button>
     <button
      type="submit"
      class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white text-white body__button"
      :class="{ 'animate-bounce': bounceButton === 'sviForm' && unsavedChangesBool }"
     >
      {{ $t("validate") }}
     </button>
    </div>
   </div>
  </div>
 </form>

 <CollectTimeSlot
  @closeForm="openTimeSlotForm = false"
  v-if="openTimeSlotForm && isActive"
  :call_queue_time_spans="form.openingDaysAndTime"
  @pushTimeSlot="form.openingDaysAndTime.push($event)"
  @isActive="(isActive = $event), (openTimeSlotForm = $event)"
 />
</template>

<script>
import CollectTimeSlotTable from "./formTools/CollectTimeSlotTable.vue";
import CollectTimeSlot from "./formTools/CollectTimeSlot.vue";
import SdaLinkedSelect from "./formTools/SdaLinkedSelect.vue";
import SearchSelector from "../SearchSelector.vue";
import Tooltip from "./formTools/Tooltip.vue";

import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";
import {
 PlusIcon,
 InformationCircleIcon,
 ExclamationCircleIcon,
 XCircleIcon,
 TrashIcon,
} from "@heroicons/vue/solid";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";

const dialChoices = [
 { id: 2, desc: "0" },
 { id: 3, desc: "1" },
 { id: 4, desc: "2" },
 { id: 5, desc: "3" },
 { id: 6, desc: "4" },
 { id: 7, desc: "5" },
 { id: 8, desc: "6" },
 { id: 9, desc: "7" },
 { id: 10, desc: "8" },
 { id: 11, desc: "9" },
];

const choiceList = [
 { id: 0, desc: "File d'attente" },
 { id: 1, desc: "Extension" },
 { id: 2, desc: "Répondeur" },
 { id: 3, desc: "SVI" },
 { id: 4, desc: "Numéro externe" },
];
export default {
 props: [
  "editData",
  "users",
  "queues",
  "isEdit",
  "sviNames",
  "sdaTown",
  "svi",
  "customerFileId",
  "fileId",
  "contentData",
  "createdSda",
  "navIdx",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "collectType",
 ],
 components: {
  ExclamationCircleIcon,
  InformationCircleIcon,
  SearchExtensionsGroup,
  CollectTimeSlot,
  CollectTimeSlotTable,
  PlusIcon,
  SdaLinkedSelect,
  SearchSelector,
  Tooltip,
  TrashIcon,
  XCircleIcon,
  ToggleWithIcon,
 },
 data() {
  return {
   choiceList: [
    { id: 0, desc: "File d'attente" },
    { id: 1, desc: "Extension" },
    { id: 2, desc: "Répondeur" },
    { id: 3, desc: "SVI" },
    { id: 4, desc: "Numéro externe" },
   ],
   choiceForm: { dial: "", responseType: "", desc: "" },
   errorAddedSda: false,
   errorIsInside: false,
   errorSdaFound: false,
   errorPhoneFormat: false,
   errorPhoneLength: false,
   errorExistedDesc: false,
   errorExistedDial: false,
   dialChoices,
   formName: "",
   form: {
    sviName: "",
    associateNumber: [],
    openingDaysAndTime: [],
    nonWorkingTimeDestination: { number: "", type: 0 },
    nonWorkingTimeChoice: "",
    audioMessageNumber: "",
    bindingKeysOption: false,
    choice: [],
    comment: "",
    isComplete: true,
   },
   isError: false,
   isActive: true,
   messageNameInvalid: false,
   nonWorkingTimeDestinationList: [
    { id: 0, desc: "Répondeur" },
    { id: 1, desc: "Répondeur simple (SVI)" },
    { id: 2, desc: "File d'attente" },
    { id: 3, desc: "Transfert (numéro externe)" },
    { id: 4, desc: "Mettre fin à l'appel" },
   ],
   openTimeSlotForm: false,
   range: [],
   isolatedNumbers: [],
   savedForm: "",
   sdaNumber: null,
   sdaLeft: null,
   sdaMax: null,
   timeSpans: [],
   selected: "",
   recordedMsgList: [],
   otherNumbers: [],
  };
 },
 computed: {
  filteredNonWorkingTimeDestinationList() {
   const newList = [...this.nonWorkingTimeDestinationList];
   if (this.collectType === "newSite") {
    newList.splice(3, 0, { id: 5, desc: "Extension existante" });
   } else {
    return newList.filter((type) => type.id !== 5);
   }
   return newList;
  },
  filteredChoiceList() {
   const newList = [...this.choiceList];
   if (this.collectType === "newSite") {
    newList.splice(4, 0, { id: 5, desc: "Extension existante" });
   } else {
    return newList.filter((type) => type.id !== 5);
   }
   return newList;
  },
 },
 methods: {
  async onSubmit() {
   if (!this.messageNameInvalid && this.form.sviName.length > 0) {
    this.form.associateNumber.length > 0 &&
    this.form.openingDaysAndTime.length > 0 &&
    this.form.nonWorkingTimeDestination.number &&
    this.form.choice.length > 0
     ? (this.form.isComplete = true)
     : (this.form.isComplete = false);
    this.$emit("submitForm", this.form, this.sdaLeft, this.$t("informationSaved"));
   }

   //   this.form.name = "";
   //   this.form.type = "";
   //   this.form.associatedNumber = "";
   //   this.form.music = "";
   //   this.form.voice = "";
   //   this.form.message = "";
   //   this.form.phonetic = "";
   //   if (this.bankDetailId) {
   // this.put(id, msg);
   //   } else {
   //   }
  },

  deteleTimeSpan(id) {
   this.form.openingDaysAndTime.splice(id, 1);
  },
  formatDateForForm(date) {
   let formDate = new Date(date);
   let formDay = formDate.getDate();
   let formMonth = formDate.getMonth();
   let formYear = formDate.getFullYear();
   return formDay + "/" + formMonth + "/" + formYear;
  },
  getDataFromEdit() {
   if (this.editData) {
    let data = this.editData;
    this.formName = data.sviName;
    this.form.sviName = data.sviName;
    this.form.associateNumber = data.associateNumber;
    this.form.openingDaysAndTime = data.openingDaysAndTime;
    this.form.nonWorkingTimeDestination = {
     ...data.nonWorkingTimeDestination,
    };
    this.form.audioMessageNumber = data.audioMessageNumber;
    this.form.choice = data.choice;
    this.form.nonWorkingTimeChoice = data.nonWorkingTimeChoice;
    this.form.comment = data.comment;
    this.form.bindingKeysOption = data.bindingKeysOption;
   }
   this.savedForm = JSON.stringify(this.form);
  },
  getStoreVar() {
   if (this.contentData["numbers"] && this.contentData["numbers"].sdaUsed) {
    this.sdaLeft = this.contentData["numbers"].sdaUsed.sdaLeft;
    this.sdaMax = this.contentData["numbers"].sdaUsed.sdaMax;
    this.range = this.contentData["numbers"].number.range.map((a) => {
     return { ...a };
    });
    this.contentData["numbers"].number.rio.forEach((el) => {
     this.isolatedNumbers.push(el.number);
    });
   }
   if (this.contentData["numbers"].number && this.contentData["numbers"].number?.other) {
    this.otherNumbers = this.contentData["numbers"].number?.other.map((obj) => obj.number);
   }
  },
  removeSda(index) {
   this.$emit("removeSdaTown", this.form.associateNumber[index], () => {
    this.form.associateNumber.splice(index, 1);
   });
   this.sdaLeft >= this.sdaMax ? (this.sdaLeft = this.sdaMax) : (this.sdaLeft = this.sdaLeft + 1);
  },
  removeElement() {
   this.sdaLeft >= this.sdaMax
    ? (this.sdaLeft = this.sdaMax)
    : (this.sdaLeft = parseInt(this.sdaLeft) + 1);
  },
  checkValidateSda(value) {
   this.errorAddedSda = false;
   this.errorSdaFound = false;

   const validLength = 10;
   let absoluteValue = typeof value === "string" ? value.replace(/\s+/g, "") : "";
   const isNum = /^\d+$/.test(absoluteValue);

   if (absoluteValue.length !== validLength || !isNum) {
    this.errorAddedSda = true;
    return;
   }

   // Check existence in both createdSda and userSettings.sdaNumber
   const isExisted = this.createdSda.includes(absoluteValue);

   if (isExisted) {
    this.errorSdaFound = true;
    return;
   }
   this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = parseInt(this.sdaLeft) - 1);
   this.sdaNumber = "";
  },
  checkCarriedNumber(number) {
   this.errorAddedSda = false;
   this.errorIsInside = false;

   const isnum = /^\d+$/.test(number);
   if (isnum) {
    this.checkValidateSda(number);
    return true;
    /*  for (let index = 0; index < this.range.length; index++) {
     const range = this.range[index];
     if (absoluteNumb >= range.from && absoluteNumb <= range.to) {
      this.checkValidateSda(number);
      return true;
     }
    } */
    // this.errorIsInside = true;
   } else {
    this.errorAddedSda = true;
   }
  },
  checkValidatePhoneNumber(number, type) {
   this.errorPhoneLength = false;
   this.errorPhoneFormat = false;
   var absoluteNumb = number ? number.split(" ").join("") : "";
   const isnum = /^\d+$/.test(absoluteNumb);
   if (absoluteNumb.length > 0) {
    if (type === "extension") {
     if (absoluteNumb.length >= 3 && absoluteNumb.length <= 4 && isnum) {
      return true;
     } else if (!isnum) {
      this.errorPhoneFormat = true;
     } else {
      this.errorPhoneLength = true;
     }
    } else {
     if (absoluteNumb.length === 10 && isnum) {
      return true;
     } else if (!isnum) {
      this.errorPhoneFormat = true;
     } else {
      this.errorPhoneLength = true;
     }
    }
   }
  },
  createNewNumber(town) {
   this.$emit("updateSdaTown", town);
   this.form.associateNumber.push(town);
   this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = this.sdaLeft - 1);
  },
  getResponseType(id) {
   return id === 0
    ? "File d'attente"
    : id === 1
    ? "Extension"
    : id === 2
    ? "Répondeur"
    : id === 3
    ? "SVI"
    : "Numéro externe";
  },
  addNewChoice(form) {
   this.errorExistedDial = false;
   this.errorExistedDesc = false;

   const existedDesc = this.form.choice.filter((obj) => obj.desc === form.desc);
   const existedDial = this.form.choice.filter((obj) => obj.dial === form.dial);

   if (
    form.desc.length > 0 &&
    existedDesc.length === 0 &&
    existedDial.length === 0 &&
    !this.errorPhoneLength &&
    !this.errorPhoneFormat
   ) {
    var obj = {};

    obj["dial"] = form.dial;
    obj["responseType"] = form.responseType;
    obj["desc"] = form.desc;
    this.form.choice.push(obj);
    this.clearDialChoiceForm();
   } else if (existedDesc.length > 0 || existedDial.length > 0) {
    existedDesc.length > 0 ? (this.errorExistedDesc = true) : (this.errorExistedDial = true);
   }
  },
  clearDialChoiceForm() {
   this.choiceForm.dial = "";
   this.choiceForm.responseType = "";
   this.choiceForm.desc = "";
  },
  deleteNewRange(idx) {
   this.form.choice.splice(idx, 1);
  },
  getRecordedMsgs() {
   let msgList = [];
   if (this.contentData && this.contentData["studio"] && this.contentData["studio"].length > 0) {
    msgList = this.contentData["studio"];
   }
   return msgList;
  },
 },
 mounted() {
  this.getDataFromEdit();
  this.getStoreVar();
 },
 watch: {
  formName: function (val) {
   if (val && this.sviNames.includes(val) && !this.isEdit) {
    this.messageNameInvalid = true;
   } else {
    this.messageNameInvalid = false;
    this.form.sviName = val;
   }
  },
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.sviForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  sdaLeft: function (number) {
   this.$emit("sdaLeft", number);
  },
  form: {
   handler: function (object) {
    this.$emit("elementForm", object);
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "sviForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
  "form.bindingKeysOption": {
   handler: function (bool) {
    if (!bool) this.form.choice = [];
   },
   deep: true,
  },
 },
};
</script>

<style></style>
