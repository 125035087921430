<template>
 <div class="text-sm">
  <label for="comments" class="font-medium text-gray-700"> {{ $t("sdaLinked") }}. </label>
  <span v-show="this.sdaMax > 0" class="ml-2 text-xs">
   <span
    :class="[
     this.sdaLeft <= this.sdaMax && this.sdaLeft > this.sdaMax * 0.75
      ? 'text-green-500'
      : this.sdaLeft >= this.sdaMax * 0.5 && this.sdaLeft <= this.sdaMax * 0.75
      ? 'text-yellow-500'
      : this.sdaLeft < this.sdaMax * 0.5
      ? 'text-red-500'
      : 'text-gray-500',
     'font-semibold',
    ]"
   >
    {{ this.sdaLeft }}/{{ this.sdaMax }}
   </span>
   {{ $t("sdaOrderedLeft") }}.
  </span>
  <Tooltip
   tooltip1="Sélectionner parmi vos numéros demandés en portabilité le numéro que vous souhaitez attribué à la file."
   tooltip2="Sélectionnez « Nouveau Numéro » si vous souhaitez attribuer un numéro qui sera créé par Encom."
  />
 </div>
 <p v-for="(number, idx) in sdaNumberList" :key="idx" class="inline-flex mx-px">
  <span
   class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
  >
   {{ number }}
   <delete
    :title="$t('delete')"
    class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
    @click="removeSda(idx)"
   >
    <span class="sr-only">Remove sda number</span>
    <svg class="w-2 h-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
     <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
    </svg>
   </delete>
  </span>
 </p>
 <div v-if="this.sdaLeft > 0">
  <div class="relative">
   <select
    v-model="selected"
    id="location"
    name="location"
    :class="[
     parentFormError
      ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300'
      : 'border-gray-300',
     'mt-1 block w-full sm:text-sm rounded-md pl-3 pr-10 py-2 text-base',
    ]"
   >
    <option value="carriedNumber">{{ $t("carriedNumber") }}</option>
    <option v-if="sdaTown" value="newNumber">{{ $t("newNumber") }}</option>
    <option v-if="navIdx != 4 && navIdx != 2" value="ownNumber">{{ $t("ownNumber") }}</option>
    <option v-if="collectType === 'newSite'" value="notBundledNumber">
     {{ $t("notBundledNumber") }}
    </option>
   </select>
   <div
    v-show="parentFormError"
    class="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5"
   >
    <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
   </div>
  </div>
  <div v-if="selected === 'ownNumber'" class="flex">
   <div class="w-full">
    <div class="relative mt-1 rounded-md">
     <input
      v-model="sdaNumber"
      type="tel"
      name="postal-code"
      id="postal-code"
      maxlength="14"
      placeholder="05 12 34 56 67"
      aria-invalid="true"
      aria-describedby="email-error"
      :class="[
       sdaNumber && (this.errorAddedSda || this.errorSdaFound)
        ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 pr-10'
        : 'border-gray-300',
       'block w-full sm:text-sm rounded-md',
      ]"
     />
     <div
      v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound)"
      class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
     >
      <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
     </div>
    </div>
    <p
     v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound)"
     class="ml-1 text-sm text-red-600"
     id="email-error"
    >
     {{
      this.errorAddedSda
       ? $t("followFormat") + " 0134567890."
       : this.errorIsInside
        ? $t("errorIsInside")
        : $t("numbAlreadyAdded")
     }}
    </p>
   </div>
   <button
    type="button"
    title="Add number"
    class="capitalize h-8 inline-flex items-center p-1.5 mt-2 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="checkValidateSda(sdaNumber)"
   >
    {{ $t("add") }}
    <PlusIcon class="w-4 h-4 ml-1" aria-hidden="true" />
   </button>
  </div>

  <div v-else-if="selected === 'newNumber'" class="flex justify-between">
   <!--   <select
    v-model="sdaNumber"
    id="location"
    name="location"
    class="block w-10/12 py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none sm:text-sm"
   >
    <option v-for="(town, idx) in filteredSdaTown" :key="idx" :value="town">{{ town }}</option>
   </select>
   <button
    type="button"
    title="Add user"
    class="capitalize inline-flex items-center p-1.5 m-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="createNewNumber(sdaNumber, this.sdaLeft)"
   >
    {{ $t("add") }}
    <PlusIcon class="w-4 h-4 ml-1" aria-hidden="true" />
   </button> -->
   <div class="w-full mt-1 rounded-md">
    <SearchSelector
     :key="$route.name"
     :selected="sdaNumber ? sdaNumber : ''"
     :searchData="sdaTown"
     :has-deep-object="true"
     @addElement="createNewNumber($event[0])"
    />
    <div
     v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound || this.errorIsInside)"
     class="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5"
    >
     <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
    </div>
   </div>
  </div>
  <div v-else-if="selected === 'carriedNumber'" class="flex justify-between">
   <div class="w-full">
    <div class="relative mt-1 rounded-md">
     <SearchSelector
      :key="$route.name"
      :selected="sdaNumberList ? sdaNumberList : ''"
      :searchData="filteredGroupElements"
      @addElement="checkCarriedNumber($event[0])"
      @removeElement="this.$emit('removeElement')"
     />
     <div
      v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound || this.errorIsInside)"
      class="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5"
     >
      <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
     </div>
    </div>
    <p
     v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound || this.errorIsInside)"
     class="ml-2 text-sm text-red-600"
     id="email-error"
    >
     {{
      this.errorAddedSda
       ? $t("followFormat") + " 0134567890."
       : this.errorIsInside
       ? $t("errorIsInside")
       : $t("numbAlreadyAdded")
     }}
    </p>
    <!--   <span
     v-if="sdaNumber"
     class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
    >
     {{ sdaNumber }}
     <a
      :title="$t('delete')"
      class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
      @click="sdaNumber = ''"
     >
      <span class="sr-only">Remove sda number</span>
      <svg class="w-2 h-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
       <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
     </a>
    </span> -->
   </div>
   <!-- <button
    type="button"
    title="Add number"
    class="capitalize h-8 inline-flex items-center p-1.5 mt-2 ml-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
    @click="checkCarriedNumber(sdaNumber)"
   >
    {{ $t("add") }}
    <PlusIcon class="w-4 h-4 ml-1" aria-hidden="true" />
   </button>-->
  </div>
  <div v-else-if="selected === 'notBundledNumber'" class="flex justify-between">
   <div class="w-full">
    <div class="relative mt-1 rounded-md">
     <SearchSelector
      :key="$route.name"
      :selected="sdaNumberList ? sdaNumberList : ''"
      :searchData="filteredNotBundledNumbers"
      @addElement="checkCarriedNumber($event[0])"
     />
     <div
      v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound || this.errorIsInside)"
      class="absolute inset-y-0 flex items-center pr-3 pointer-events-none right-5"
     >
      <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
     </div>
    </div>
    <p
     v-show="sdaNumber && (this.errorAddedSda || this.errorSdaFound || this.errorIsInside)"
     class="ml-2 text-sm text-red-600"
     id="email-error"
    >
     {{
      this.errorAddedSda
       ? $t("followFormat") + " 0134567890."
       : this.errorIsInside
       ? $t("errorIsInside")
       : $t("numbAlreadyAdded")
     }}
    </p>
   </div>
  </div>
 </div>
 <div v-else class="p-2 mt-1 rounded-md bg-blue-50">
  <div class="flex">
   <div class="flex-shrink-0">
    <InformationCircleIcon class="w-4 h-4 text-blue-400" aria-hidden="true" />
   </div>
   <div class="flex-1 ml-3 md:flex md:justify-between">
    <p class="text-xs text-blue-700">
     {{
      this.sdaLeft === 0
       ? `Aucun numéro disponible. Vous pouvez en ajouter à la section 02/` +
         $t("numbersPortability") +
         "."
       : `Veuillez indiquer le nombre de numéro(s) voulu à la section 02/` +
         $t("numbersPortability") +
         "."
     }}
    </p>
    <p v-show="this.sdaMax < 1" class="mt-3 text-xs md:mt-0 md:ml-6">
     <a
      @click="this.$router.push(`/customer-files/collect/${this.customerFileId}/${this.fileId}/1`)"
      class="font-medium text-blue-700 cursor-pointer whitespace-nowrap hover:text-blue-500"
     >
      <span aria-hidden="true" class="mr-1">&larr;</span>
      {{ $t("numbersPortability") }}
     </a>
    </p>
   </div>
  </div>
 </div>
</template>

<script>
import SearchExtensionsGroup from "../SearchExtensionsGroup.vue";
import SearchSelector from "../../SearchSelector.vue";

import Tooltip from "./Tooltip.vue";
import { ExclamationCircleIcon, InformationCircleIcon, PlusIcon } from "@heroicons/vue/solid";

//27 Oct. I take off errorIsInside prop
export default {
 props: [
  "customerFileId",
  "errorSdaFound",
  "errorAddedSda",
  "fileId",
  "ipModelePhoneSelected",
  "isolatedNumbers",
  "range",
  "sdaNumberList",
  "sdaNumberListLength",
  "sdaLeft",
  "sdaMax",
  "sdaTown",
  "createdSda",
  "parentFormError",
  "navIdx",
  "collectType",
  "notBundledNumbers",
 ],
 components: {
  ExclamationCircleIcon,
  InformationCircleIcon,
  PlusIcon,
  Tooltip,
  SearchExtensionsGroup,
  SearchSelector,
 },

 data() {
  return {
   selected: "",
   sdaNumber: null,
   search: "",
   searchableTerm: "",
   isLoading: false,
  };
 },
 methods: {
  removeSda(index) {
   this.$emit("removeSda", index);
  },
  checkValidateSda(sdaNumber) {
   sdaNumber ? this.$emit("checkValidateSda", sdaNumber) : "";
  },
  createNewNumber(town) {
   town ? this.$emit("createNewNumber", town) : "";
  },
  handleSearchInput(event) {
   this.searchableTerm = event.target.value;
  },
  checkCarriedNumber(numb) {
   this.sdaNumber = numb;
   this.$emit("checkCarriedNumber", numb);
  },
 },
 computed: {
  filteredNotBundledNumbers() {
   let data = [];
   if (this.notBundledNumbers && this.notBundledNumbers.length > 0) {
    data = [...data, ...this.notBundledNumbers];
    data = data.filter((res) => {
     return this.createdSda.indexOf(res) < 0;
    });
   }
   return data;
  },
  filteredGroupElements() {
   let data = [];
   if (this.range && this.range.length > 0) {
    for (let index = 0; index < this.range.length; index++) {
     const element = this.range[index];
     const arr = Array.from(
      { length: parseInt(element.to) - parseInt(element.from) + 1 },
      (_, i) => "0" + (parseInt(element.from) + i)
     );
     if (!arr.includes(element.ndi) && !data.includes(element.ndi)) arr.push(element.ndi);
     data = [...data, ...arr];
    }
    data = data.filter((res) => {
     return this.createdSda.indexOf(res) < 0;
    });
   }
   if (this.isolatedNumbers && this.isolatedNumbers.length > 0) {
    data = [...data, ...this.isolatedNumbers];
    data = data.filter((res) => {
     return this.createdSda.indexOf(res) < 0;
    });
   }
   return data;
  },
  filteredSdaTown() {
   let data = [];
   let count;
   let sdaList = [...new Set(this.sdaTown)];
   data = [...data, ...this.sdaTown];

   sdaList.forEach((sda) => {
    count = this.createdSda.reduce((acc, curr) => {
     if (curr === sda) {
      acc++;
     }
     return acc;
    }, 0);

    for (let i = 0; i < count; i++) {
     let index = data.indexOf(sda);
     if (index !== -1) {
      data.splice(index, 1);
     }
    }
   });
   return data;
  },
 },
 watch: {
  sdaNumberListLength: function (val, oldVal) {
   val > oldVal ? (this.sdaNumber = null) : "";
  },
  selected: function () {
   this.sdaNumber = null;
  },
  searchableTerm: function (val) {
   if (this.searchMethod) {
    if (val.length > 2) {
     this.searchMethod(val);
    }
   }
  },
  search: function (val) {
   this.isLoading = false;
   this.$emit("action", val);
  },
  selectedSearch: function (val) {
   this.search = val;
  },
 },
 mounted() {},
};
</script>
