<template>
 <fieldset>
  <legend class="sr-only">Members</legend>
  <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
   <div
    v-for="(countries, continentIdx) in openDestinations"
    :key="continentIdx"
    class="relative items-start py-4"
   >
    <div class="flex">
     <div :key="isContinentChecked(continentIdx)" class="mr-3 flex items-center h-6 ">
      <input
       @change="setContinentValue(continentIdx)"
       id="flexCheckIndeterminate"
       :name="`continent-${continentIdx}`"
       type="checkbox"
       :checked="isContinentChecked(continentIdx) || continentHasCountriesChecked(continentIdx)"
       :class="[
        continentHasCountriesChecked(continentIdx) && !isContinentChecked(continentIdx)
         ? 'text-gray-400'
         : 'body__form--checkbox-focus body__form--checkbox',
        'h-4 w-4  border-gray-300 rounded',
       ]"
      />
     </div>
     <div
      @click="this.displayCountries(countries)"
      class="min-w-0 flex-1 text-base flex justify-between"
     >
      <div class="flex">
       <label
        :for="`continent-${continentIdx}`"
        class="font-semibold text-gray-700 select-none cursor-pointer"
       >
        {{ continentIdx }}
       </label>

       <a class="cursor-pointer">
        <svg
         v-if="this.clickedCountryObj === countries && isActive"
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4 mt-1 ml-1 text-gray-500"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         stroke-width="2"
        >
         <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
        <svg
         v-else
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4 mt-1 ml-1 text-gray-500"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         stroke-width="2"
        >
         <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
        </svg>
       </a>
      </div>
      <div class="flex">
       <ul class="inline-flex gap-1">
        <li v-for="(country, idx) in showSelectedElement(continentIdx).slice(0, 5)" :key="country">
         <span>{{ country }}</span>
         <span class="" v-show="idx < showSelectedElement(continentIdx).length - 1">,</span>
        </li>
       </ul>
       <span v-if="showSelectedElement(continentIdx).length > 5">...</span>
       <!-- <div>
                {{ showSelectedElement(continentIdx).length }}
              </div> -->
      </div>
     </div>
    </div>
    <div
     v-for="(country, countryIdx) in Object.keys(countries)"
     :key="countryIdx"
     class="hidden ml-5 mt-1"
     :class="{ display: this.clickedCountryObj === countries && isActive }"
    >
     <div class="mr-3 flex items-center h-5">
      <input
       :key="activeKey"
       @change="setCountryValue(country, $event, continentIdx)"
       v-model="Object.values(countries)[countryIdx]"
       :value="country"
       :id="`country-${country}`"
       :name="`country-${country}`"
       type="checkbox"
       class="body__form--checkbox-focus h-4 w-4 body__form--checkbox border-gray-300 rounded"
      />
     </div>
     <div class="min-w-0 flex-1 text-sm">
      <label :for="`country-${country}`" class="font-medium text-gray-700 select-none">
       {{ country }}
      </label>
     </div>
    </div>
   </div>
  </div>
 </fieldset>
</template>
<script>
import axios from "axios";

export default {
 name: "OpenDestinationList",
 props: ["data", "contentData"],
 components: {},
 data() {
  return {
   checked: false,
   continents: [],
   form: {},
   openDestinations: {},
   isActive: false,
   clickedCountryObj: {},
   countriesList: [],
   continentSelection: false,
   activeKey: false,
  };
 },
 computed: {},
 methods: {
  showSelectedElement(continent) {
   let arr = [];
   for (let index = 0; index < this.countriesList.length; index++) {
    const element = this.countriesList[index];
    if (this.openDestinations && this.openDestinations[continent]) {
     let keys = Object.keys(this.openDestinations[continent]);
     if (keys.indexOf(element) >= 0) {
      arr.push(element);
     }
    }
   }
   return arr;
  },
  continentHasCountriesChecked(continent) {
   let flag = false;
   let keys = Object.keys(this.openDestinations[continent]);
   if (!this.isContinentChecked(continent)) {
    for (let index = 0; index < this.countriesList.length; index++) {
     const element = this.countriesList[index];
     if (keys.indexOf(element) >= 0) {
      flag = true;
     }
    }
   }
   return flag;
  },
  async getOpenDestinations() {
   const res = await axios.get(
    `https://telecom0585-admin.bluerocktel.net/api/v1/helpers/3cxOpenDestinations`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   // this.setData();
   this.checkSelectedElements(res.data);
  },
  checkSelectedElements(data, continentChecked) {
   let destionations = data;
   let objValues = Object.values(data);
   let objKeys = Object.keys(data);

   if (
    this.contentData &&
    this.contentData["mainInfos"] &&
    this.contentData["mainInfos"]["restrictions"] &&
    this.contentData["mainInfos"]["restrictions"]["lists"] &&
    this.contentData["mainInfos"]["restrictions"]["lists"].length > 0
   ) {
    let selectedCountries = this.contentData["mainInfos"]["restrictions"]["lists"];

    for (let index = 0; index < objValues.length; index++) {
     const element = objValues[index];
     for (let indexKey = 0; indexKey < Object.keys(element).length; indexKey++) {
      const elementKey = Object.keys(element)[indexKey];
      if (selectedCountries.indexOf(elementKey) >= 0) {
       destionations[Object.keys(data)[index]][elementKey] = true;
      }
     }
    }
   } else {
    for (let index = 0; index < objKeys.length; index++) {
     const element = objKeys[index];

     let value = data[element];
     for (let indexKey = 0; indexKey < Object.keys(value).length; indexKey++) {
      const elementKey = Object.keys(value)[indexKey];
      if (element === continentChecked) {
       destionations[element][elementKey] = true;
      }
     }
    }
   }
   this.openDestinations = destionations;
   this.activeKey = !this.activeKey;
  },
  // setData() {
  //   if (Object.keys(this.data.lists).length > 0) {
  //     for (const key in this.data.lists) {
  //       if (Object.hasOwnProperty.call(this.data.lists, key)) {
  //         const element = this.data.lists[key];

  //         const continentObj = { name: key, checked: false };
  //         const countryObj = { [key]: element };
  //         this.form = { ...this.form, ...countryObj };
  //         this.continents.push(continentObj);
  //       }
  //     }
  //   } else {
  //     for (const key in this.openDestinations) {
  //       if (Object.hasOwnProperty.call(this.openDestinations, key)) {
  //         const element = this.openDestinations[key];
  //         const continentObj = { name: key, checked: false };
  //         const countryObj = { [key]: element };
  //         this.form = { ...this.form, ...countryObj };
  //         this.continents.push(continentObj);
  //       }
  //     }
  //   }
  // },
  isContinentChecked(continent) {
   let checked = true;
   let objValues = Object.values(this.openDestinations[continent]);
   for (let index = 0; index < objValues.length; index++) {
    const element = objValues[index];
    if (!element) {
     checked = false;
    }
   }
   return checked;
  },
  removeAllCountriesFromContinent(continent, data) {
   let destionations = data;

   let objKeysDestination = Object.keys(data[continent]);

   for (let index = 0; index < objKeysDestination.length; index++) {
    const element = objKeysDestination[index];
    destionations[continent][element] = false;
   }
   this.openDestinations = destionations;
  },
  removeCountryFromContinent(continent, country) {
   let destionations = this.openDestinations;

   let objKeysDestination = Object.keys(destionations[continent]);

   for (let index = 0; index < objKeysDestination.length; index++) {
    const element = objKeysDestination[index];
    if (element === country) {
     destionations[continent][element] = false;
    }
   }
   this.openDestinations = destionations;
  },
  checkIfAllCountriesAreSelected(continent) {
   let flag = true;
   for (let index = 0; index < Object.values(this.openDestinations[continent]).length; index++) {
    const element = Object.values(this.openDestinations[continent])[index];
    if (!element) {
     flag = false;
    }
   }
   return flag;
  },
  setContinentValue(continentChecked) {
   if (this.openDestinations) {
    if (!this.continentHasCountriesChecked(continentChecked)) {
     if (!this.checkIfAllCountriesAreSelected(continentChecked)) {
      for (
       let index = 0;
       index < Object.keys(this.openDestinations[continentChecked]).length;
       index++
      ) {
       const element = Object.keys(this.openDestinations[continentChecked])[index];

       let listIdx = this.countriesList.indexOf(element);
       if (listIdx < 0) {
        this.countriesList.push(element);
       }
      }
      this.checkSelectedElements(this.openDestinations, continentChecked);
     } else {
      for (
       let index = 0;
       index < Object.keys(this.openDestinations[continentChecked]).length;
       index++
      ) {
       const element = Object.keys(this.openDestinations[continentChecked])[index];

       let listIdx = this.countriesList.indexOf(element);
       if (listIdx >= 0) {
        this.countriesList.splice(listIdx, 1);
       }
      }
      this.removeAllCountriesFromContinent(continentChecked, this.openDestinations);
     }
    } else {
     for (
      let index = 0;
      index < Object.keys(this.openDestinations[continentChecked]).length;
      index++
     ) {
      const element = Object.keys(this.openDestinations[continentChecked])[index];

      let listIdx = this.countriesList.indexOf(element);
      if (listIdx >= 0) {
       this.countriesList.splice(listIdx, 1);
      }
     }
     this.removeAllCountriesFromContinent(continentChecked, this.openDestinations);
    }
   }
   this.activeKey = !this.activeKey;
   this.$emit("internationalForm", this.countriesList);
  },
  setCountryValue(countryChecked, event, continent) {
   let country = event.target.value;
   let listIdx = this.countriesList.indexOf(country);
   if (listIdx >= 0) {
    this.countriesList.splice(listIdx, 1);
    this.removeCountryFromContinent(continent, country);
   } else {
    this.countriesList.push(country);
   }
   // this.countriesList = [];
   this.$emit("internationalForm", this.countriesList);

   //loop into the form object, then I'm looking for the key equal
   //to the param country and i define the new value, event.target.checked

   // for (const [continent, countries] of Object.entries(this.form)) {
   //   for (const [country] of Object.entries(countries)) {
   //     if (country === countryChecked) {
   //       this.form[continent][country] = event.target.checked;
   //       this.checkContinentValue();
   //     }
   //   }
   // }
  },
  checkContinentValue() {
   for (let index = 0; index < this.continents.length; index++) {
    const element = this.continents[index];
    if (element.checked) {
     const checkbox = document.getElementById("flexCheckIndeterminate");
     checkbox.indeterminate = true;
     //this.continents[index].checked = undefined;
    }
   }
  },
  displayCountries(countryObj) {
   if (this.clickedCountryObj != countryObj) {
    this.clickedCountryObj = countryObj;
    this.isActive = true;
   } else {
    this.isActive = !this.isActive;
   }
  },
  setSelectedCountries() {
   if (
    this.contentData &&
    this.contentData["mainInfos"] &&
    this.contentData["mainInfos"]["restrictions"] &&
    this.contentData["mainInfos"]["restrictions"]["lists"] &&
    this.contentData["mainInfos"]["restrictions"]["lists"].length > 0
   ) {
    this.countriesList = this.contentData["mainInfos"]["restrictions"]["lists"];
   }
  },
 },
 mounted() {
  this.getOpenDestinations();
  this.setSelectedCountries();
 },
 watch: {
  // form: {
  //   handler: function(val) {
  //     this.$emit("internationalForm", val);
  //   },
  //   deep: true,
  // },
  // openDestinations: {
  //   handler: function(val) {
  //     for (const [continent, countries] of Object.entries(this.form)) {
  //       const asArray = Object.entries(countries);
  //       const filtered = asArray.filter(([key, value]) => value === true);
  //       const checkedCountries = Object.fromEntries(filtered);
  //       const newObj = { [continent]: checkedCountries };
  //       var checkedCountryObj = { ...checkedCountryObj, ...newObj };
  //     }
  //     console.log(checkedCountryObj);
  //     this.$emit("internationalForm", checkedCountryObj);
  //   },
  //   deep: true,
  // },
 },
};
</script>
<style scoped>
.display {
 display: flex;
}
</style>
