<template>
 <form @submit.prevent="onSubmit()" method="POST">
  <div class="space-y-6 mt-2">
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Site</h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="siteName" class="capitalize block text-sm font-semibold text-gray-700">
         {{ $t("name") }}*
        </label>
        <input
         v-model="form.siteName"
         type="text"
         required
         readonly
         name="siteName"
         id="siteName"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="siteAddress" class="block text-sm font-semibold text-gray-700">
         Adresse*
        </label>
        <input
         v-model="form.siteAddress"
         type="text"
         required
         readonly
         name="siteAddress"
         id="siteAddress"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Switch</h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="space-y-6">
       <fieldset>
        <div>
         <legend class="text-base font-medium text-gray-900">
          Fournissez-vous le switch pour raccorder la téléphonie sur l'accès internet?
         </legend>
        </div>
        <div class="mt-4 space-y-4">
         <div class="relative flex items-start">
          <div class="flex items-center h-5">
           <ToggleWithIcon
            :validated="form.customerProvideSwitch"
            @boolean-toggle="
             (form.customerProvideSwitch = $event),
              !$event
               ? ((form.switchPoE = false),
                 (form.switchVlan = false),
                 (form.switchType.brand = ''),
                 (form.switchType.model = ''))
               : ''
            "
           />
          </div>
          <div class="ml-3 text-sm">
           <label for="comments" class="font-medium text-gray-700">
            {{
             form.customerProvideSwitch
              ? $t("customerProvideSwitchYes")
              : $t("customerProvideSwitchNo")
            }}
           </label>
          </div>
         </div>
        </div>
       </fieldset>
       <fieldset v-if="form.customerProvideSwitch">
        <div class="md:gap-6">
         <div class="mt-5">
          <div class="space-x-6 flex">
           <fieldset>
            <legend class="text-base font-medium text-gray-900">
             Ce switch est-il PoE ?
             <Tooltip
              tooltip1="Un switch PoE (Power over Ethernet) alimente en électricité les postes téléphoniques qui y sont raccordés."
              tooltip2="Il évite de devoir équiper chaque poste IP avec un bloc d’alimentation autonome."
             />
            </legend>

            <div class="mt-2">
             <ToggleWithIcon
              :validated="form.switchPoE"
              @boolean-toggle="form.switchPoE = $event"
             />
            </div>
           </fieldset>
           <fieldset>
            <legend class="text-base font-medium text-gray-900">
             Ce switch gère-t-il les VLAN?
             <Tooltip
              tooltip1="VLAN: Virtual local area network ou réseau local virtuel."
              tooltip2="Un switch qui gère les VLAN permet de créer plusieurs réseaux indépendants et d’isoler les flux ne pouvant pas communiquer entre eux. Par exemple, un flux pour la voix et un flux pour la data."
             />
            </legend>
            <div class="mt-2">
             <ToggleWithIcon
              :validated="form.switchVlan"
              @boolean-toggle="form.switchVlan = $event"
             />
            </div>
           </fieldset>
          </div>
          <div v-if="form.switchVlan" class="bg-yellow-50 border-l-4 border-yellow-400 p-2 mt-2">
           <div class="flex">
            <div class="flex-shrink-0">
             <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
             <p class="text-sm text-yellow-700">
              Paramétrer sur votre switch un VLAN pour y accueillir tous les téléphones IP ainsi que
              le SBC : tous les équipements voix doivent être dans la même étendue réseau.
             </p>
            </div>
           </div>
          </div>
         </div>
         <div class="mt-5">
          <label for="switch-model" class="text-base font-medium text-gray-900">
           Marque et modèle du switch*
          </label>
          <div class="flex gap-1 mt-4">
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Marque
            </label>
            <input
             v-model="form.switchType.brand"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Modèle
            </label>
            <input
             v-model="form.switchType.model"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
          </div>
         </div>
        </div>
       </fieldset>
      </div>
     </div>
    </div>
   </div>
   <div ref="firewall" class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="flex text-lg font-medium leading-6 text-gray-900">Firewall</h3>
     </div>
     <div class="space-y-6 mt-5 md:mt-0 md:col-span-2">
      <div class="space-y-6">
       <fieldset>
        <div>
         <legend class="text-base font-medium text-gray-900">
          Allez-vous utiliser votre Firewall pour prioriser la voix ?

          <Tooltip
           tooltip1="Prioriser la voix sur votre accès internet permet de garantir la qualité de service. Nous serons à votre disposition pour vous accompagner sur ces paramétrages."
          />
         </legend>
        </div>
        <div class="mt-4 space-y-4">
         <fieldset class="col-span-2 col-start-2">
          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
           <div class="flex items-center">
            <input
             v-model="form.firewall"
             value="client"
             id="client-firewall"
             name="notification-method"
             type="radio"
             checked
             class="h-4 w-4 border-gray-300"
            />
            <label for="client-firewall" class="ml-3 block text-sm font-medium text-gray-700">
             Nous utiliserons notre Firewall
            </label>
           </div>
           <div class="flex items-center">
            <input
             v-model="form.firewall"
             value="encom"
             id="encom-firewall"
             name="notification-method"
             type="radio"
             class="h-4 w-4 border-gray-300"
            />
            <label for="encom-firewall" class="ml-3 block text-sm font-medium text-gray-700">
             Le Firewall sera fourni par Encom
            </label>
           </div>
           <div class="flex items-center">
            <input
             v-model="form.firewall"
             value="none"
             id="none-firewall"
             name="notification-method"
             type="radio"
             class="h-4 w-4 border-gray-300"
            />
            <label for="none-firewall" class="ml-3 block text-sm font-medium text-gray-700">
             Le site ne sera pas équipé de Firewall
             <Tooltip
              tooltip1="Pour garantir la qualité des communications, nous recommandons d’équiper votre site d’un Firewall permettant de gérer la priorisation des flux et de réserver un débit suffisant pour la voix."
             />
            </label>
           </div>
          </div>
         </fieldset>
        </div>

        <div
         v-show="form.firewall === 'client'"
         class="bg-yellow-50 border-l-4 border-yellow-400 p-2 mt-2"
        >
         <div class="flex">
          <div class="flex-shrink-0">
           <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
           <p class="text-sm text-yellow-700">
            Débit à reserver pour la voix (100Kb/appels simultanés).
           </p>
           <p class="text-sm text-yellow-700">
            Autoriser sur votre firewall le trafic sortant TCP/UDP sur le port 5090.
           </p>
          </div>
         </div>
        </div>
       </fieldset>

       <fieldset v-if="form.firewall === 'client'">
        <div class="md:grid md:grid-cols-3 md:gap-6">
         <div class="mt-5 md:mt-0 md:col-span-3">
          <label for="switch-model" class="text-base font-medium text-gray-900">
           Marque et modèle du Firewall*
          </label>
          <div class="flex gap-1 mt-4">
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Marque</label
            >
            <input
             v-model="form.firewallType.brand"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Modèle</label
            >
            <input
             v-model="form.firewallType.model"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
          </div>
         </div>
        </div>
       </fieldset>
       <div v-if="form.firewall !== 'encom'" class="mt-5 md:mt-0 md:col-span-2">
        <div class="space-y-6">
         <fieldset>
          <div>
           <legend class="text-base font-medium text-gray-900">
            La maintenance de votre firewall est-elle assurées par un prestataire externe ?
           </legend>
          </div>
          <div class="mt-4 space-y-4">
           <div class="relative flex items-start">
            <div class="flex items-center h-5">
             <ToggleWithIcon
              :key="form.firewallProvider"
              :validated="form.firewallProvider"
              @boolean-toggle="form.firewallProvider = $event"
             />
            </div>
            <div class="ml-3 text-sm">
             <label for="comments" class="font-medium text-gray-700">
              {{ form.firewallProvider ? $t("firewallProviderYes") : $t("firewallProviderNo") }}
             </label>
            </div>
           </div>
          </div>
         </fieldset>
         <fieldset v-if="form.firewallProvider">
          <div class="grid grid-cols-6 gap-6">
           <div class="col-span-6">
            <label for="society" class="block text-sm font-semibold text-gray-700">
             Société qui gère votre Firewall*
            </label>
            <input
             v-model="form.societyName"
             required
             type="text"
             name="society"
             id="society"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>

           <div class="col-span-6 sm:col-span-2">
            <label for="contactName" class="block text-sm font-semibold text-gray-700">
             Nom d'un contact responsable du dossier*
            </label>
            <input
             v-model="form.fileResponsibleName"
             required
             type="text"
             name="contactName"
             id="contactName"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
           <div class="col-span-6 sm:col-span-2">
            <label for="contactTel" class="block text-sm font-semibold text-gray-700">
             Téléphone du contact*
            </label>

            <div class="relative mt-1 rounded-md shadow-sm">
             <input
              v-model="form.fileResponsibleContact"
              required
              type="text"
              name="contactTel"
              id="contactTel"
              :class="[
               errorForm
                ? 'focus:ring-red-500 border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500'
                : 'border-gray-300',
               'block w-full rounded-md focus:outline-none sm:text-sm',
              ]"
             />
             <div
              v-if="errorForm"
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
             >
              <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
             </div>
            </div>
            <p v-if="errorForm" class="ml-1 text-xs text-red-600" id="email-error">
             Le numéro de téléphone doit être au format 01 98 76 54 32
            </p>
           </div>
           <div class="col-span-6 sm:col-span-2">
            <label for="contactEmail" class="block text-sm font-semibold text-gray-700">
             Email du contact*
            </label>
            <input
             v-model="form.fileResponsibleEmail"
             required
             type="email"
             name="contactEmail"
             id="contactEmail"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
          </div>
         </fieldset>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="flex text-lg font-medium leading-6 text-gray-900">App 3CX Smartphone & Wifi</h3>
     </div>
     <div class="space-y-6 mt-5 md:mt-0 md:col-span-2">
      <div class="space-y-6">
       <fieldset>
        <div>
         <legend class="text-base font-medium text-gray-900">
          Souhaitez vous utiliser les App 3CX sur Smartphone via vos bornes WiFi?
         </legend>
        </div>
        <div class="mt-4 space-y-4">
         <fieldset class="col-span-2 col-start-2">
          <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
           <div class="flex items-center">
            <input
             v-model="form.smartphoneWifi"
             value="client"
             id="client-wifi"
             name="smartphoneWifi-method"
             type="radio"
             checked
             class="h-4 w-4 border-gray-300"
            />
            <label for="client-wifi" class="ml-3 block text-sm font-medium text-gray-700">
             Nous utiliserons nos bornes WiFi
            </label>
           </div>
           <div class="flex items-center">
            <input
             v-model="form.smartphoneWifi"
             value="encom"
             id="encom-wifi"
             name="smartphoneWifi-method"
             type="radio"
             class="h-4 w-4 border-gray-300"
            />
            <label for="encom-wifi" class="ml-3 block text-sm font-medium text-gray-700">
             Les bornes WiFi seront fournies par Encom
            </label>
           </div>
           <div class="flex items-center">
            <input
             v-model="form.smartphoneWifi"
             value="none"
             id="none-wifi"
             name="smartphoneWifi-method"
             type="radio"
             class="h-4 w-4 border-gray-300"
            />
            <label for="none-wifi" class="ml-3 block text-sm font-medium text-gray-700">
             Le site ne sera pas équipé de bornes WiFi
            </label>
           </div>
          </div>
         </fieldset>
        </div>
        <div class="mt-4 space-y-2">
         <!--   <div class="relative flex items-start">
          <div class="flex items-center h-5">
           <ToggleWithIcon
            :validated="form.smartphoneWifi"
            @boolean-toggle="form.smartphoneWifi = $event"
           />
          </div>
          <div class="ml-3 text-sm">
           <label for="comments" class="font-medium text-gray-700">
            {{ form.smartphoneWifi ? $t("smartphoneWifiYes") : $t("smartphoneWifiNo") }}
           </label>
          </div>
         </div> -->
         <div
          v-show="form.smartphoneWifi !== 'encom'"
          class="bg-yellow-50 border-l-4 border-yellow-400 p-2 mt-2"
         >
          <div class="flex">
           <div class="flex-shrink-0">
            <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
           </div>
           <div class="ml-3">
            <p class="text-sm text-yellow-700">
             L’assistance technique relative au fonctionnement de l’application Smartphone 3CX sur
             un réseau WIFI n’est prise en charge par ENCOM que dans la cas où ENCOM fournit au
             Client à la fois les smartphones et le service WIFI dans l’entreprise.
            </p>
           </div>
          </div>
         </div>
        </div>
       </fieldset>
       <fieldset v-if="form.smartphoneWifi === 'client'">
        <div class="md:grid md:grid-cols-3 md:gap-6">
         <div class="mt-5 md:mt-0 md:col-span-3">
          <label for="switch-model" class="text-base font-medium text-gray-900">
           Marque et modèle des bornes WiFi*
          </label>
          <div class="flex gap-1 mt-4">
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Marque</label
            >
            <input
             v-model="form.wifiType.brand"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute -top-2 left-2 -mt-px inline-block px-1 rounded bg-white text-xs font-medium text-gray-900"
             >Modèle</label
            >
            <input
             v-model="form.wifiType.model"
             required
             type="text"
             name="switch-model"
             id="switch-model"
             class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
           </div>
          </div>
         </div>
        </div>
       </fieldset>
      </div>
     </div>
    </div>
   </div>
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="flex text-lg font-medium leading-6 text-gray-900">
       Résumé du nombre de matériels informatique
      </h3>
      <p class="mt-1 text-sm text-gray-500">
       Synthèse du nombre de Prises RJ45, Ports PoE et prises électriques à réserver pour Encom sur
       site.
      </p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 space-y-4">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-4">
        <label for="work-stations" class="block text-sm font-semibold text-gray-700">
         Confirmer le nombre de Postes Fixes*
        </label>
        <input
         v-model="form.numbFixedWorkStations"
         required
         @change="sumHardwares()"
         type="number"
         name="work-stations"
         min="0"
         id="work-stations"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="borne-wifi" class="block text-sm font-semibold text-gray-700">
         Confirmer le nombre de bornes Wifi Encom*
        </label>
        <input
         v-model="form.numbWifiAP"
         required
         @change="sumHardwares()"
         type="number"
         name="borne-wifi"
         id="borne-wifi"
         min="0"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="sbc" class="block text-sm font-semibold text-gray-700">
         Confirmer le nombre d’accès internet Encom*
        </label>
        <input
         v-model="form.numbInternetAccess"
         required
         @change="sumHardwares()"
         min="0"
         type="number"
         name="internet-access"
         id="internet-access"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="fourni-switch" class="block text-sm font-semibold text-gray-700">
         Confirmer le nombre de Switchs Encom*
        </label>
        <input
         v-model="form.numbSwitchesProvided"
         required
         @change="sumHardwares()"
         min="0"
         type="number"
         name="fourni-switch"
         id="fourni-switch"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="fourni-firewall" class="block text-sm font-semibold text-gray-700">
         Confirmer le nombre de Firewall fourni par Encom*
        </label>
        <input
         v-model="form.numbFirewallsProvided"
         required
         @change="sumHardwares()"
         type="number"
         name="fourni-firewall"
         id="fourni-firewall"
         min="0"
         class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
       </div>
      </div>

      <div class="grid grid-cols-6 gap-6 space-x-4">
       <div class="col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-2 mt-2">
        <div class="flex">
         <div class="flex-shrink-0">
          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
         </div>
         <div class="grid grid-cols-6 space-x-4">
          <div class="ml-3 col-span-6 sm:col-span-2">
           <label for="fourni-switch" class="block text-sm font-medium text-gray-900">
            Prise(s) Ethernet nécéssaire(s) pour raccorder les équipements hors baie de brassage
           </label>
           <input
            v-model="form.numbEthernetSockets"
            readonly
            type="number"
            name="ethernet"
            id="ethernet"
            min="0"
            class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
           />
          </div>
          <div class="ml-3 col-span-6 sm:col-span-2">
           <label for="fourni-firewall" class="block text-sm font-medium text-gray-900">
            Port(s) PoE à reserver pour Encom sur le switch accueillant la téléphonie
           </label>
           <input
            v-model="form.numbPOEPorts"
            readonly
            type="number"
            name="poe"
            id="poe"
            min="0"
            class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
           />
          </div>
          <div class="ml-3 col-span-6 sm:col-span-2">
           <label for="fourni-firewall" class="block text-sm font-medium text-gray-900">
            Prise(s) Electrique(s) à reserver pour Encom dans la baie de brassage
           </label>
           <input
            v-model="form.numbElectricOutlets"
            readonly
            type="number"
            name="prise-electric"
            id="prise-electric"
            min="0"
            class="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
           />
          </div>
          <p class="mt-2 col-span-6 text-sm text-yellow-700">
           Si vous avez confié à ENCOM l’installation sur site de votre 3CX, il est de votre
           responsabilité de vous assurer que la quantité de prises Ethernet RJ45, de ports POE et
           de prises électriques est suffisante pour accueillir vos nouveaux équipements.
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="pb-5 flex justify-end" ref="networkForm">
    <button
     @click="$emit('closeForm')"
     type="button"
     class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
    >
     {{ $t("cancel") }}
    </button>
    <button
     type="submit"
     class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white text-white body__button"
     :class="{ 'animate-bounce': bounceButton === 'networkForm' && unsavedChangesBool }"
    >
     {{ $t("validate") }}
    </button>
   </div>
  </div>
 </form>
</template>

<script>
import axios from "axios";
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import Tooltip from "./formTools/Tooltip.vue";

import { ExclamationIcon, ExclamationCircleIcon } from "@heroicons/vue/solid";

const date = new Date();

const nonWorkingTimeDestinationList = [
 { id: 0, desc: "Répondeur" },
 { id: 1, desc: "Répondeur simple (SVI)" },
 { id: 2, desc: "File" },
 { id: 3, desc: "Transfert" },
];
const choiceList = [
 { id: 0, desc: "File d'attente" },
 { id: 1, desc: "Extension" },
 { id: 2, desc: "Repondeur" },
 { id: 3, desc: "Numero externe" },
 { id: 3, desc: "SVI (arboresence supplementaire)" },
];
const strategyList = [
 { id: 0, desc: "SONNE TOUS" },
 { id: 1, desc: "1 à 1 DANS L'ORDRE" },
 { id: 2, desc: "1 à 1 AU HASARD" },
 { id: 3, desc: "AGENT AVEC LE MOINS D'APPEL" },
 { id: 4, desc: "TEMPS DE COMMUNICATION" },
 { id: 5, desc: "SUR COMPETENCE AVEC AGENT AYANT LE MOINS D APPEL" },
 { id: 6, desc: "SUR COMPETENCE EN SONNE TOUS" },
 { id: 7, desc: "SUR COMPETENCE DANS L ORDRE EN ROUND ROBIN" },
 { id: 8, desc: "SUR COMPETENCE AU HASARD" },
];
const yesOrNo = [
 { id: 0, desc: "NON" },
 { id: 1, desc: "OUI" },
];
export default {
 props: ["editData", "bounceButton", "unsavedChangesBool", "elementRef"],
 components: {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  ExclamationIcon,
  ExclamationCircleIcon,
  ToggleWithIcon,
  Tooltip,
 },
 data() {
  return {
   form: {
    siteName: "",
    siteAddress: "",
    customerProvideSwitch: false,
    switchPOE: false,
    switchVlan: false,
    switchType: { model: "", brand: "" },
    firewall: "client",
    firewallType: { model: "", brand: "" },
    firewallReservedSpeed: "",
    firewallAllowTrafic: false,
    firewallProvider: false,
    smartphoneWifi: "client",
    wifiType: { model: "", brand: "" },
    numbFixedWorkStations: 0,
    numbWifiAP: 0,
    wifiName: "",
    internetName: "",
    numbInternetAccess: 0,
    numbSwitchesProvided: 0,
    numbFirewallsProvided: 0,
    numbEthernetSockets: 0,
    numbPOEPorts: 0,
    numbElectricOutlets: 0,
    societyName: "",
    fileResponsibleName: "",
    fileResponsibleContact: "",
    fileResponsibleEmail: "",
    isComplete: false,
   },
   errorForm: false,
   savedForm: "",
   nonWorkingTimeDestinationList,
   choiceList,
   strategyList,
   yesOrNo,
  };
 },
 methods: {
  async onSubmit() {
   this.errorForm = false;

   if (
    this.form.firewallProvider &&
    this.form.fileResponsibleContact.replace(/\s+/g, "").length < 11
   ) {
    this.errorForm = true;
    const el = this.$refs.firewall;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   } else {
    this.form.isComplete = true;
    this.$emit("submitForm", this.form);
   }
   //   this.form.name = "";
   //   this.form.type = "";
   //   this.form.associatedNumber = "";
   //   this.form.music = "";
   //   this.form.voice = "";
   //   this.form.message = "";
   //   this.form.phonetic = "";
   //   if (this.bankDetailId) {
   // this.put(id, msg);
   //   } else {
   //   }
  },
  formatDateForForm(date) {
   let formDate = new Date(date);
   let formDay = formDate.getDate();
   let formMonth = formDate.getMonth();
   let formYear = formDate.getFullYear();
   return formDay + "/" + formMonth + "/" + formYear;
  },
  sumHardwares() {
   let a = this.form.numbFixedWorkStations;
   let b = this.form.numbWifiAP;
   let c = this.form.numbSwitchesProvided;
   let d = this.form.numbInternetAccess;
   let e = this.form.numbFirewallsProvided;

   this.form.numbEthernetSockets = a + b;
   this.form.numbPOEPorts = a + b + c + d + 1;
   this.form.numbElectricOutlets = c + d + e + 1;
  },
  getDataFromEdit() {
   if (this.editData) {
    let data = this.editData;
    console.log(data);
    this.form.siteName = data.siteName;
    this.form.siteAddress = data.siteAddress;
    this.form.customerProvideSwitch = data.customerProvideSwitch;
    this.form.switchPoE = data.switchPoE;
    this.form.switchVlan = data.switchVlan;
    data.firewall ? (this.form.firewall = data.firewall) : (this.form.firewall = "client");
    this.form.firewallProvider = data.firewallProvider;
    this.form.firewallReservedSpeed = data.firewallReservedSpeed;
    this.form.firewallAllowTrafic = data.firewallAllowTrafic;
    this.form.smartphoneWifi = data.smartphoneWifi;
    this.form.numbFixedWorkStations = data.numbFixedWorkStations;

    this.form.numbWifiAP = data.numbWifiAP;
    this.form.numbInternetAccess = data.numbInternetAccess;
    this.form.numbSwitchesProvided = data.numbSwitchesProvided;
    this.form.numbFirewallsProvided = data.numbFirewallsProvided;
    this.form.numbEthernetSockets = data.numbEthernetSockets;
    this.form.numbPOEPorts = data.numbPOEPorts;
    this.form.numbElectricOutlets = data.numbElectricOutlets;
    this.form.societyName = data.societyName;
    this.form.fileResponsibleName = data.fileResponsibleName;
    this.form.fileResponsibleContact = data.fileResponsibleContact;
    this.form.fileResponsibleEmail = data.fileResponsibleEmail;
    this.form.firewallType = data.firewallType;
    this.form.wifiType = data.wifiType;
    this.form.switchType = data.switchType;
   }
   this.savedForm = JSON.stringify(this.form);
  },
 },
 watch: {
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.networkForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  form: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "networkForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
 mounted() {
  this.getDataFromEdit();
 },
};
</script>

<style></style>
