<template>
 <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
  <div class="sm:flex sm:items-center">
   <div class="sm:flex-auto">
    <div class="flex justify-between">
     <h1 class="capitalize text-lg font-medium leading-6 text-gray-900">
      {{ $t("groups") }}
     </h1>
     <button
      v-show="groups && groups.length > 0"
      type="button"
      title="Add group"
      class="inline-flex items-center p-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
      @click="(showForm = !showForm), (isEdited = false), (groupIdx = null)"
     >
      <PlusIcon class="h-5 w-5" aria-hidden="true" />
     </button>
    </div>
    <p class="mt-2 text-sm text-gray-700">
     Les groupes permettent d’organiser les utilisateurs par
     <span class="font-bold">service</span> (commercial, technique, Administratif), par
     <span class="font-bold">site</span> (Paris, Toulouse, Londres) ou par
     <span class="font-fold">société</span>
     (Société A, société B, Société C). Un même utilisateur peut appartenir à un ou plusieurs
     groupes. Ces groupes sont visibles sur votre Web Client, votre client lourd (client PC ou Mac)
     ou votre smartphone.
    </p>
   </div>
  </div>
  <div v-show="showForm || isEdited">
   <CollectAddGroup
    :key="groupIdx"
    :groupChosen="groupChosen"
    :idx="groupIdx"
    :isEdited="isEdited"
    :stepName="stepName"
    :contentData="contentData"
    :userData="userData"
    :fileId="fileId"
    :bounceButton="bounceButton"
    :unsavedChangesBool="unsavedChangesBool"
    :elementRef="elementRef"
    :collectType="collectType"
    @close-form="(showForm = $event), (isEdited = $event), this.$emit('unsavedChanges', false, '')"
    @groups-form="(...args) => this.$emit('groupsForm', ...args)"
    @add-group="this.sendForm($event, this.$t('informationSaved'))"
    @edit-group="(...args) => this.editForm(...args)"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @unsaved-changes="(...args) => $emit('unsavedChanges', ...args)"
   />
  </div>

  <div
   v-if="groups && groups.length > 0"
   class="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
  >
   <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
     <li v-for="(group, idx) in filteredData" :key="group.id">
      <a
       @click="
        (isEdited = true),
         (showForm = false),
         (groupChosen = group),
         groupIdx === idx ? ((isEdited = false), (groupIdx = null)) : (groupIdx = idx)
       "
       class="block hover:bg-gray-50 cursor-pointer"
      >
       <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
         <p class="text-sm font-medium encom_primary_text truncate">
          {{ group.name }}
         </p>
        </div>
        <div class="mt-2 sm:flex sm:justify-between">
         <div class="sm:flex">
          <p class="flex items-center text-sm text-gray-500">
           <UsersIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
           {{ group.users.length }} {{ $t("users") }}
          </p>
         </div>

         <div class="-mt-5 text-sm text-gray-500">
          <button
           @click.stop="
            deleteGroup(
             idx,
             $t('wishToContinue'),
             $t('deleteGroup') + ' ' + `${group.name}`,
             $t('groupDeletedSuccessfully'),
             $t('continue'),
             $t('cancel')
            )
           "
           type="button"
           class="flex justify-center items-center w-8 h-8 text-red-400 rounded-full bg-transparent hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
           <TrashIcon class="w-5 h-5" aria-hidden="true" />
          </button>
         </div>
        </div>
       </div>
      </a>
     </li>
    </ul>
   </div>
  </div>
  <div v-else-if="!showForm" class="text-center max-w-lg mx-auto mt-5">
   <svg
    class="mx-auto h-12 w-12 text-gray-400"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 48 48"
    aria-hidden="true"
   >
    <path
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
    />
   </svg>
   <div class="mt-2">
    <button
     type="button"
     class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover focus:outline-none encom_ring_color"
     @click="showForm = true"
    >
     <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
     {{ $t("pbx.newGroup") }}
    </button>
   </div>
  </div>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

import CollectAddGroup from "./CollectAddGroup.vue";
import {
 CalendarIcon,
 ExclamationIcon,
 InformationCircleIcon,
 UsersIcon,
 PlusIcon,
 TrashIcon,
} from "@heroicons/vue/solid";

const account = localStorage.getItem("account");

export default {
 name: "CollectGroups",
 props: [
  "contentData",
  "fileId",
  "userData",
  "search",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "collectType",
 ],
 components: {
  AskConfirmationDialog,
  CalendarIcon,
  CollectAddGroup,
  ExclamationIcon,
  InformationCircleIcon,
  UsersIcon,
  PlusIcon,
  TrashIcon,
 },

 data() {
  return {
   account,
   groupChosen: [],
   groupIdx: null,
   groups: [],
   isEdited: false,
   thead: ["numero interne", "sda", "prenom", "nom", "email", "formation"],
   showForm: false,
   stepName: "groups",
   formFinal: {},
  };
 },

 methods: {
  getData() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.groups = this.contentData[this.stepName];
   }
  },
  sendForm(form, msg) {
   let content = this.contentData;
   if (content[this.stepName]) {
    content[this.stepName].push(form);
   } else {
    content[this.stepName] = [];
    content[this.stepName].push(form);
   }

   content = JSON.stringify(content);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  editForm(...args) {
   const idx = args[1];
   const newForm = args[0];
   const msg = args[2];
   this.groups[idx] = newForm;

   let content = this.contentData;
   content[this.stepName] = this.groups;
   content = JSON.stringify(content);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  async deleteGroup(idx, confirmationMessage, title, msg, confirmButton, goBack) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.groups.splice(idx, 1);
    var content = this.contentData;
    content[this.stepName] = this.groups;

    content = JSON.stringify(content);
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${content}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      console.error(error);
      this.errorHandling(error);
     });
   }
  },
 },
 mounted() {
  this.getData();
 },
 computed: {
  filteredData() {
   return this.groups.filter((res) => {
    if (this.search) {
     return res.name.toLowerCase().includes(this.search.toLowerCase());
    } else {
     return 1;
    }
   });
  },
 },
};
</script>
