<template>
 <div class="space-y-6 mb-5">
  <form @submit.prevent="sendForm()" method="POST">
   <div class="space-y-6 mt-2">
    <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
     <div class="sm:flex sm:items-center text-center mb-20">
      <div class="sm:flex-auto">
       <h1 class="text-lg font-medium leading-6 text-gray-900">
        <span class="mb-1 mr-2"> &#127881;</span>
        {{ $t("legalNoticeDetail") }}.
       </h1>
      </div>
     </div>
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("privacyGdpr") }}
       </h3>
      </div>
      <div class="space-y-6 mt-5 md:mt-0 md:col-span-2">
       <div class="bg-yellow-50 border-l-4 border-yellow-400 p-2">
        <div class="flex">
         <div class="flex-shrink-0">
          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
         </div>
         <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">
           Pour en savoir plus sur le RGPD et l'enregistrement des appels
          </h3>
          <div class="mt-2 text-sm text-yellow-700">
           <ul role="list" class="list-disc pl-5 space-y-1">
            <li>
             <a
              href="https://www.cnil.fr/fr/lecoute-et-lenregistrement-des-appels-sur-le-lieu-de-travail"
              target="_blank"
              class="flex"
             >
              L’écoute et l’enregistrement des appels sur le lieu de travail
              <svg
               xmlns="http://www.w3.org/2000/svg"
               class="h-4 w-4 mt-px ml-1 text-yellow-800"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               stroke-width="2"
              >
               <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
               />
              </svg>
             </a>
            </li>
            <li>
             <a
              href="https://www.cnil.fr/fr/les-bases-legales/consentement"
              class="flex"
              target="_blank"
             >
              Conformité RGPD
              <svg
               xmlns="http://www.w3.org/2000/svg"
               class="h-4 w-4 mt-px ml-1 text-yellow-800"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               stroke-width="2"
              >
               <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
               />
              </svg>
             </a>
            </li>
           </ul>
          </div>
         </div>
        </div>
       </div>
       <fieldset>
        <div>
         <legend class="text-base font-medium text-gray-900">
          {{ $t("privacyGdprToogleLegend") }}.
         </legend>
        </div>
        <div class="mt-2">
         <div class="flex items-center">
          <ToggleWithIcon @boolean-toggle="acceptGdpr = $event" :validated="acceptGdpr" />
          <label
           v-if="acceptGdpr"
           for="push-everything"
           class="ml-3 block text-sm font-medium text-gray-700"
          >
           J'ai pris connaissance de la réglementation RGPD,
           <span
            >le
            <span class="font-semibold capitalize"> {{ toLocaleTimeString(form.acceptedAt) }}</span>
           </span>
          </label>
         </div>
         <div v-show="isError" class="rounded-md bg-red-50 p-2 mt-3">
          <div class="flex">
           <div class="flex-shrink-0">
            <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
           </div>
           <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800 mt-px">{{ $t("privacyGdprDetail") }}.</h3>
            <div class="mt-2 text-sm text-red-700">
             <ul role="list" class="list-disc pl-5 space-y-1">
              <li>
               <a
                href="https://www.cnil.fr/fr/lecoute-et-lenregistrement-des-appels-sur-le-lieu-de-travail"
                target="_blank"
                class="flex"
               >
                L’écoute et l’enregistrement des appels sur le lieu de travail
                <svg
                 xmlns="http://www.w3.org/2000/svg"
                 class="h-4 w-4 mt-px ml-1 text-yellow-800"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 stroke-width="2"
                >
                 <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                 />
                </svg>
               </a>
              </li>
              <li>
               <a
                href="https://www.cnil.fr/fr/les-bases-legales/consentement"
                class="flex"
                target="_blank"
               >
                Conformité RGPD
                <svg
                 xmlns="http://www.w3.org/2000/svg"
                 class="h-4 w-4 mt-px ml-1 text-yellow-800"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 stroke-width="2"
                >
                 <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                 />
                </svg>
               </a>
              </li>
             </ul>
            </div>
           </div>
          </div>
         </div>
        </div>
       </fieldset>
      </div>
     </div>
    </div>
    <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("comment") }}
       </h3>
       <p class="mt-1 text-sm text-gray-500">{{ $t("privacyGdprComment") }}.</p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
       <div>
        <textarea
         v-model="this.form.comment"
         rows="4"
         name="comment"
         id="comment"
         class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
        />
       </div>
      </div>
     </div>
    </div>

    <div class="bg-white mx-5 px-4 py-5 sm:rounded-lg sm:p-6 shadow flex justify-end">
     <button
      type="submit"
      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white body__button"
     >
      {{ $t("confirmCollect") }}
     </button>
    </div>
   </div>
  </form>
 </div>
</template>

<script>
import axios from "axios";
import { xmlExtensionFile } from "../../views/Collect/xmlFiles";
import { ExclamationIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/vue/solid";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import { mapGetters } from "vuex";

export default {
 name: "CollectUsers",
 props: ["contentData", "dependencies", "fileId", "steps", "customerFileId"],
 components: {
  ExclamationIcon,
  InformationCircleIcon,
  ToggleWithIcon,
  XCircleIcon,
 },

 data() {
  return {
   acceptGdpr: false,
   isError: false,
   form: {
    comment: "",
    acceptGdpr: false,
    acceptedAt: null,
    isComplete: true,
   },
   stepName: "legalNotice",
  };
 },

 methods: {
  sendForm() {
   this.isError = false;
   !this.form.acceptGdpr
    ? (this.isError = true)
    : this.postForm(this.$t("collectSubmittedSuccessfully"));
  },
  postForm(msg) {
   let content = this.contentData;
   let incompleteForms = [];

   incompleteForms = this.checkCompletedForms(content);

   if (incompleteForms.length == 0) {
    content[this.stepName] = this.form;

    content = JSON.stringify(content);

    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      status: "submitted",
      content: `${content}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then((res) => {
      console.log(res);
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch(function (error) {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
    this.$router.push(`/customer-files`);
   } else {
    let goBackIndex = undefined;
    for (let index = 0; index < this.steps.length; index++) {
     const element = this.steps[index];

     if (element.objName === incompleteForms[0]) {
      goBackIndex = parseInt(element.id) - 1;
     }
    }
    if (goBackIndex >= 0) {
     this.$router.push(
      `/customer-files/collect/${this.customerFileId}/${this.fileId}/${goBackIndex}`
     );
     this.$emit("goBackToDependentStep", true);
    }
   }
  },
  checkCompletedForms(content) {
   console.log("file: CollectLegalNotice.vue ~ line 350 ~ checkCompletedForms ~ content", content);
   let incompleteForms = [];
   let keys = content ? Object.keys(content) : [];
   for (let index = 0; index < keys.length; index++) {
    const element = keys[index];
    if (content[element].length > 0) {
     for (let indexElement = 0; indexElement < content[element].length; indexElement++) {
      const elementContent = content[element][indexElement];
      if (Object.keys(content[element][indexElement]).indexOf("isComplete") >= 0) {
       !elementContent["isComplete"] ? incompleteForms.push(element) : "";
      }
     }
    } else {
     if (Object.keys(content[element]).indexOf("isComplete") >= 0) {
      !content[element]["isComplete"] ? incompleteForms.push(element) : "";
     }
     // !content[element]["isComplete"] &&
     // element !== "mainInfos" &&
     // element !== "relations" &&
     // element !== "legalNotice" &&
     // element !== "numbers"
     //   ? incompleteForms.push(element)
     //   : "";
    }
   }
   return incompleteForms;
  },
  OBJtoXML(obj) {
   var xml = "";
   for (var prop in obj) {
    xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
     for (var array in obj[prop]) {
      xml += "<" + prop + ">";
      xml += this.OBJtoXML(new Object(obj[prop][array]));
      xml += "</" + prop + ">";
     }
    } else if (typeof obj[prop] == "object") {
     xml += this.OBJtoXML(new Object(obj[prop]));
    } else {
     xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
   }
   var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
   return xml;
  },
  writeXML() {
   let collectExtensions = [
    {
     userSettings: {
      firstName: "test",
      lastName: "test",
      email: "test@test.com",
      mobile: "0511223300",
      physicalSite: "test",
      service: "",
      outputNumber: false,
      outputDisplayNumber: null,
      internalNumber: 100,
      sdaNumber: ["0173330000"],
     },
     globalSettings: {
      voiceMail: false,
      webClient: false,
      pushSmartphone: false,
      hotDesking: false,
      ipModelePhone: "",
     },
     notifications: {
      missedCalls: false,
      voiceMail: false,
     },
     restrictions: {
      internationalRestrictions: false,
      callRestrictions: false,
     },
     recording: "non",
     training: {
      user: "",
      admin: "",
     },
     others: {
      comment: "",
     },
     isComplete: true,
    },
   ];

   for (let indexExt = 0; indexExt < collectExtensions.length; indexExt++) {
    const elementExt = collectExtensions[indexExt];
    let objKey = xmlExtensionFile.Extensions.Extension;
    objKey.LastName = elementExt.userSettings.lastName;
    objKey.FirstName = elementExt.userSettings.firstName;
   }

   console.log("XML", this.OBJtoXML(xmlExtensionFile));
  },
 },
 watch: {
  acceptGdpr: function (val) {
   this.form.acceptGdpr = val;
   val ? (this.form.acceptedAt = new Date()) : "";
  },
 },
 mounted() {
  if (this.contentData && this.contentData.legalNotice) {
   this.acceptGdpr = this.contentData.legalNotice.acceptGdpr;
   this.form.acceptGdpr = this.contentData.legalNotice.acceptGdpr;
   this.form.comment = this.contentData.legalNotice.comment;
   this.form.acceptedAt = new Date(this.contentData.legalNotice.acceptedAt);
  }
 },
 computed: {
  ...mapGetters(["account"]),
 },
};
</script>
