<template>
 <form @submit.prevent="validateForm($t('informationSaved'))" method="POST">
  <div class="px-4 py-5 mx-5 mb-5 space-y-6 bg-white shadow sm:rounded-lg">
   <div
    class="py-5 space-y-10 border divide-y divide-gray-200 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:grid-rows-1" ref="numberForm">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("numbersPortabilityTitle") }}
      </h3>
      <p class="mt-1 mr-2 text-sm text-gray-500">
       {{ $t("numbersPortabilityDetail") }}.
      </p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="mb-5">
       <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
        {{ $t("rangeNumber") }}
       </h2>
       <ul
        role="list"
        class="grid grid-cols-1 gap-5 mt-3 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <li
         v-for="(range, index) in this.form.number.range"
         :key="index"
         class="flex col-span-1 rounded-md shadow-sm">
         <div
          class="flex items-center justify-between flex-1 bg-white border border-gray-200 rounded-md bg-gray-50">
          <div class="flex-1 py-2 pl-4 pr-2 text-sm">
           <a
            @click="this.editNewRange(range, index)"
            class="font-medium text-gray-900 cursor-pointer hover:text-gray-600">
            <span class="font-semibold"> ({{ range.ndi }}) </span>
            {{ range.from }} - {{ range.to }}
           </a>
           <p class="text-xs text-gray-500">
            {{ range.town }}
            {{
             range.portabilityDate
              ? "- " + $d(range.portabilityDate, "shortText")
              : ""
            }}
           </p>
          </div>

          <div class="flex-shrink-0 pr-2">
           <button
            @click="this.deleteNewRange(index)"
            type="button"
            class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500">
            <span class="sr-only">Open options</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </li>
       </ul>
      </div>

      <div class="grid grid-cols-6 gap-2">
       <div class="relative col-span-6 sm:col-span-4">
        <label for="ndi" class="text-sm font-semibold text-gray-700 capitalize">
         {{ $t("NDI") }}*
        </label>

        <input
         v-model="this.formNumber.ndi"
         type="text"
         name="ndi"
         id="ndi"
         :class="[
          errorFormNumberNdi || errorFormNumberNdiLength
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]" />
        <div
         v-show="errorFormNumberNdi || errorFormNumberNdiLength"
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormNumberNdi || errorFormNumberNdiLength"
         class="ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("pleaseUseFormat") + " 0123456789." }}
        </p>
       </div>
       <div class="relative col-span-6 sm:col-span-3">
        <label
         for="range-start"
         class="text-sm font-semibold text-gray-700 capitalize">
         {{ $t("start") }}*
        </label>
        <Tooltip
         tooltip1="Indiquer le 1er numéro d’une tranche de numéros consécutifs" />
        <input
         v-model="this.formNumber.from"
         type="text"
         name="range-start"
         id="range-start"
         :class="[
          errorFormNumberFrom ||
          errorFormNumberFromLength ||
          errorFormNumberFromSmallerThanTo ||
          errorFormNumberFromAndToEqual ||
          errorFormNumberInside
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]" />
        <div
         v-show="
          errorFormNumberFrom ||
          errorFormNumberFromLength ||
          errorFormNumberFromSmallerThanTo ||
          errorFormNumberFromAndToEqual ||
          errorFormNumberInside
         "
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="
          errorFormNumberFrom ||
          errorFormNumberFromLength ||
          errorFormNumberFromSmallerThanTo ||
          errorFormNumberFromAndToEqual ||
          errorFormNumberInside
         "
         class="ml-1 text-sm text-red-600"
         id="email-error">
         {{
          errorFormNumberFromSmallerThanTo
           ? $t("errorFormNumberFromSmallerThanTo")
           : errorFormNumberFromAndToEqual
           ? $t("errorFormNumberFromAndToEqual")
           : errorFormNumberInside
           ? $t("errorFormNumberInside")
           : $t("pleaseUseFormat") + " 0123456789."
         }}
        </p>
       </div>

       <div class="relative col-span-6 sm:col-span-3">
        <label for="range-end" class="text-sm font-semibold text-gray-700">
         {{ $t("end") }}*
        </label>

        <Tooltip
         tooltip1="Indiquer le dernier numéro d’une tranche de numéros consécutifs" />
        <input
         v-model="this.formNumber.to"
         type="text"
         name="range-end"
         id="range-end"
         :class="[
          errorFormNumberTo ||
          errorFormNumberToLength ||
          errorFormNumberFromSmallerThanTo ||
          errorFormNumberFromAndToEqual ||
          errorFormNumberInside
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]" />
        <div
         v-show="
          errorFormNumberTo ||
          errorFormNumberToLength ||
          errorFormNumberFromSmallerThanTo ||
          errorFormNumberFromAndToEqual ||
          errorFormNumberInside
         "
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormNumberTo || errorFormNumberToLength"
         class="ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("pleaseUseFormat") + " 0123456789." }}
        </p>
       </div>

       <div class="relative col-span-6 sm:col-span-3">
        <label for="range-end" class="text-sm font-semibold text-gray-700">
         {{ $t("portabilityDate") }}*
        </label>

        <v-date-picker
         v-model="this.formNumber.portabilityDate"
         :min-date="new Date()"
         :locale="$i18n.locale"
         mode="date"
         class="capitalize"
         color="teal">
         <template v-slot="{ inputValue, inputEvents }">
          <input
           :class="[
            errorFormNumberPortability
             ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',

            'p-2 mt-1 border focus:outline-none focus:border-blue-500 w-full shadow-sm sm:text-sm rounded-md',
           ]"
           :value="inputValue"
           v-on="inputEvents" />
         </template>
        </v-date-picker>
        <div
         v-show="errorFormNumberPortability"
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormNumberPortability"
         class="ml-1 text-sm text-red-600"
         id="errorFormNumberPortability-error">
         {{ $t("errorFormNumberPortability") }}
        </p>
       </div>

       <div class="relative col-span-6 sm:col-span-3">
        <label for="name" class="text-sm font-semibold text-gray-700">
         {{ $t("physicalSiteName") }}*
        </label>
        <select
         v-model="this.formNumber.town"
         id="location"
         name="location"
         :class="[
          errorFormNumberTown
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]">
         <option v-for="(city, idx) in cityList" :key="idx" :value="city">
          {{ city }}
         </option>
        </select>
        <div
         v-show="errorFormNumberTown"
         class="absolute inset-y-0 flex items-center pr-3 mt-1 pointer-events-none right-4">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormNumberTown"
         class="ml-1 text-sm text-red-600"
         id="errorFormNumberTown-error">
         {{ $t("errorFormNumberTown") }}
        </p>
       </div>

       <div class="flex justify-end col-span-6 px-4">
        <button
         v-if="this.isEditedRange"
         @click="this.clearRangeForm()"
         class="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
         {{ $t("cancel") }}
        </button>
        <button
         v-if="this.isEditedRange"
         @click="newRangeChecker(this.formNumber)"
         type="button"
         class="px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm encom_primary encom_primary_hover hover:bg-gray-50 focus:outline-none"
         :class="{
          'animate-bounce': bounceButton === 'numberForm' && unsavedChangesBool,
         }">
         {{ $t("save") }}
        </button>

        <button
         v-else
         @click="newRangeChecker(this.formNumber)"
         type="button"
         class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm encom_primary encom_primary_hover encom_ring_color"
         :class="{
          'animate-bounce': bounceButton === 'numberForm' && unsavedChangesBool,
         }">
         {{ $t("add") }}
        </button>
       </div>
      </div>
     </div>

     <div
      class="mt-5 md:mt-0 md:col-span-2 md:col-start-2 md:row-start-2"
      ref="numberIsolatedForm">
      <div class="mb-5">
       <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
        {{ $t("numberIsolated") }}
       </h2>
       <div class="p-1 pl-2 rounded-md bg-yellow-50">
        <div class="flex">
         <div class="flex-shrink-0">
          <ExclamationIcon class="w-5 h-5 text-yellow-400" aria-hidden="true" />
         </div>
         <div class="ml-3">
          <div class="text-sm text-yellow-700">
           <p>{{ $t("rioMandatoryWarning") }}.</p>
          </div>
         </div>
        </div>
       </div>
       <ul
        role="list"
        class="grid grid-cols-1 gap-5 mt-3 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <li
         v-for="(rio, index) in this.form.number.rio"
         :key="index"
         class="flex col-span-1 rounded-md shadow-sm">
         <div
          class="flex items-center justify-between flex-1 bg-white border border-gray-200 rounded-md bg-gray-50">
          <div class="flex-1 py-2 pl-4 pr-2 text-sm">
           <a
            @click="this.editNewRio(rio, index)"
            class="font-medium text-gray-900 cursor-pointer hover:text-gray-600">
            {{ rio.number }}
            {{ rio.rio ? "- " + rio.rio : "" }}
           </a>
           <p class="text-xs text-gray-500">
            {{ rio.town }}
            {{
             rio.portabilityDate
              ? "- " + $d(rio.portabilityDate, "shortText")
              : ""
            }}
           </p>
          </div>
          <div class="flex-shrink-0 pr-2">
           <button
            @click="this.deleteNewRio(index)"
            type="button"
            class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500">
            <span class="sr-only">Open options</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </li>
       </ul>
      </div>

      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label
         for="range-number"
         class="block text-sm font-semibold text-gray-700 capitalize">
         {{ $t("number") }}*
        </label>
        <div class="relative">
         <input
          v-model="phoneNumber"
          type="tel"
          name="range-number"
          id="range-number"
          :class="[
           invalidNumber
            ? 'focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300'
            : 'border-gray-300',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]" />
         <div
          v-if="invalidNumber"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <ExclamationCircleIcon
           class="w-5 h-5 text-red-500"
           aria-hidden="true" />
         </div>
        </div>
        <span v-if="invalidNumber" class="ml-1 text-sm text-red-600">
         {{
          errorNoNumber
           ? $t("errorNoNumber")
           : errorHasNdi
           ? $t("errorHasNdi")
           : $t("errorInvalidNumber")
         }}
        </span>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="range-rio" class="text-sm font-semibold text-gray-700">
         RIO*
        </label>
        <Tooltip
         tooltip1="Le Relevé d'Identité Opérateur (RIO) est un code indispensable aux échanges inter-opérateurs dans le cadre de la portabilité."
         tooltip2="Vous pouvez l’obtenir en appelant le 3179 depuis votre ligne fixe ou en contactant le service client de votre opérateur actuel." />
        <div class="relative">
         <input
          v-model="rio"
          @input="rio = rio.toUpperCase()"
          type="text"
          name="range-rio"
          id="range-rio"
          :class="[
           invalidRio
            ? 'focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300'
            : 'border-gray-300',
           'block  w-full shadow-sm sm:text-sm rounded-md',
          ]" />
         <div
          v-if="invalidRio"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <ExclamationCircleIcon
           class="w-5 h-5 text-red-500"
           aria-hidden="true" />
         </div>
        </div>
        <span v-if="invalidRio" class="ml-1 text-sm text-red-600">
         {{ $t(errorRioMsg) }}.
        </span>
       </div>

       <div class="relative col-span-6 sm:col-span-3">
        <label for="range-end" class="text-sm font-semibold text-gray-700">
         {{ $t("portabilityDate") }}*
        </label>

        <v-date-picker
         v-model="this.formRIO.portabilityDate"
         :min-date="new Date()"
         :locale="$i18n.locale"
         mode="date"
         class="capitalize"
         color="teal">
         <template v-slot="{ inputValue, inputEvents }">
          <input
           :class="[
            errorFormRIOPortability
             ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',
            'p-2 mt-1 border focus:outline-none focus:border-blue-500 w-full shadow-sm sm:text-sm rounded-md',
           ]"
           :value="inputValue"
           v-on="inputEvents" />
         </template>
        </v-date-picker>
        <div
         v-show="errorFormRIOPortability"
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormRIOPortability"
         class="ml-1 text-sm text-red-600"
         id="errorFormRIOPortability-error">
         {{ $t("errorFormNumberPortability") }}
        </p>
       </div>

       <div class="relative col-span-6 sm:col-span-3">
        <label for="name" class="text-sm font-semibold text-gray-700">
         {{ $t("physicalSiteName") }}*
        </label>
        <select
         v-model="this.formRIO.town"
         id="location"
         name="location"
         :class="[
          errorFormRIOTown
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]">
         <option v-for="(city, idx) in cityList" :key="idx" :value="city">
          {{ city }}
         </option>
        </select>
        <div
         v-show="errorFormRIOTown"
         class="absolute inset-y-0 flex items-center pr-3 mt-1 pointer-events-none right-4">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormRIOTown"
         class="ml-1 text-sm text-red-600"
         id="errorFormRIOTown-error">
         {{ $t("errorFormNumberTown") }}
        </p>
       </div>

       <div class="flex justify-end col-span-6 px-4">
        <button
         v-if="this.isEditedRio"
         @click="this.clearRioForm()"
         class="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
         {{ $t("cancel") }}
        </button>
        <button
         v-if="this.isEditedRio"
         @click="editRio(this.formRIO)"
         type="button"
         class="px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm encom_primary encom_primary_hover hover:bg-gray-50 focus:outline-none"
         :class="{
          'animate-bounce':
           bounceButton === 'numberIsolatedForm' && unsavedChangesBool,
         }">
         {{ $t("save") }}
        </button>

        <button
         v-else
         @click="addNewRio(this.formRIO)"
         type="button"
         class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm encom_primary encom_primary_hover encom_ring_color"
         :class="{
          'animate-bounce':
           bounceButton === 'numberIsolatedForm' && unsavedChangesBool,
         }">
         {{ $t("add") }}
        </button>
       </div>
      </div>
     </div>
    </div>

    <div
     class="pt-10 md:grid md:grid-cols-3 md:grid-rows-1"
     ref="sdaCreationForm">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("sdaCreation") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">{{ $t("sdaCreationDetail") }}.</p>
     </div>

     <div class="ml-5 md:col-start-2 md:row-start-1 md:col-span-2">
      <div>
       <div class="flex items-center">
        <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
         {{ $t("sdaCreate") }}

         <span class="tracking-normal lowercase">
          ({{ $t("sdaCreateDetail") }})
         </span>
        </h2>
        <button
         @click="this.addObject(this.form.sda.sda, 'sda')"
         type="button"
         class="ml-2 px-1 py-0.5 border border-transparent rounded-md text-white encom_primary encom_primary_hover">
         <PlusSmIcon class="w-5 h-5" aria-hidden="true" />
        </button>
       </div>
      </div>

      <ul
       role="list"
       class="grid grid-cols-1 gap-5 my-3 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
       <li
        v-for="(sda, index) in this.form.sda.sda"
        :key="sda.name"
        class="flex col-span-2 rounded-md shadow-sm">
        <div
         class="flex items-center justify-between flex-1 truncate bg-white border border-gray-200 rounded-md bg-gray-50">
         <div class="flex-1 px-4 py-2 text-sm">
          <div class="flex m-1 space-x-2 rounded isolate">
           <div class="relative focus-within:z-10">
            <label
             for="number"
             class="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white rounded -top-2 left-2">
             {{ $t("sda") }}
            </label>
            <input
             v-model="sda.number"
             @change="roundUp(sda.number, index)"
             @keypress="isNumber($event)"
             min="0"
             max="9999"
             step="5"
             type="number"
             class="w-full border-gray-300 rounded-md sm:text-sm" />
           </div>

           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white rounded -top-2 left-2">
             {{ $t("physicalSiteName") }}
            </label>
            <select
             v-model="sda.town"
             id="location"
             name="location"
             class="text-base border-gray-300 rounded-md sm:text-sm">
             <option v-for="(city, idx) in cityList" :key="idx" :value="city">
              {{ city }}
             </option>
            </select>
           </div>

           <div class="relative focus-within:z-10">
            <label
             for="name"
             class="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white rounded -top-2 left-2">
             {{ $t("numberIdentifier") }}
            </label>
            <select
             v-model="sda.numberIdentifier"
             id="location"
             name="location"
             class="text-base border-gray-300 rounded-md sm:text-sm">
             <option v-for="(code, idx) in numberCode" :key="idx" :value="code">
              {{ code }}
             </option>
            </select>
           </div>
          </div>
         </div>
         <div class="flex-shrink-0 pr-2">
          <button
           @click="this.deleteObject(this.form.sda.sda, 'sda', index)"
           type="button"
           class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500">
           <span class="sr-only">Open options</span>
           <TrashIcon class="w-5 h-5" aria-hidden="true" />
          </button>
         </div>
        </div>
       </li>
      </ul>
     </div>
    </div>

    <div
     class="pt-10 md:grid md:grid-cols-3 md:grid-rows-1"
     ref="notBundledForm">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("othersNumbersPortabilityTitle") }}
      </h3>
      <p class="mt-1 mr-2 text-sm text-gray-500">
       {{ $t("othersNumbersPortabilityDetail") }}.
      </p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="mb-5">
       <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
        {{ $t("othersNumbersPortabilityList") }}
       </h2>
       <ul
        role="list"
        class="grid grid-cols-1 gap-5 mt-3 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <li
         v-for="(number, index) in this.form.number.other"
         :key="index"
         class="flex col-span-1 rounded-md shadow-sm">
         <div
          class="flex items-center justify-between flex-1 bg-white border border-gray-200 rounded-md bg-gray-50">
          <div class="flex-1 py-2 pl-4 pr-2 text-sm">
           <a
            @click="this.editOtherNumber(number, index)"
            class="font-medium text-gray-900 cursor-pointer hover:text-gray-600">
            {{ number.number }}
           </a>
          </div>

          <div class="flex-shrink-0 pr-2">
           <button
            @click="this.deleteOtherNumber(index)"
            type="button"
            class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500">
            <span class="sr-only">Open options</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </li>
       </ul>
      </div>

      <div class="grid grid-cols-6 gap-2">
       <div class="relative col-span-6 sm:col-span-4">
        <label
         for="othersNumbersPortability"
         class="text-sm font-medium text-gray-700 capitalize">
         {{ $t("number") }}
        </label>

        <input
         v-model="this.formOther.number"
         type="text"
         name="othersNumbersPortability"
         id="othersNumbersPortability"
         :class="[
          errorFormOtherNumber
           ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
           : 'border-gray-300',
          'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
         ]" />
        <div
         v-show="errorFormOtherNumber"
         class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
         <ExclamationCircleIcon
          class="w-5 h-5 text-red-500"
          aria-hidden="true" />
        </div>
        <p
         v-show="errorFormOtherNumber"
         class="ml-1 text-sm text-red-600"
         id="othersNumbersPortability-error">
         {{ $t("pleaseUseFormat") + " 0123456789." }}
        </p>
       </div>

       <div class="flex justify-end col-span-6 px-4">
        <button
         v-if="this.isEditedNewNumber"
         @click="this.clearOtherNumberForm()"
         class="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
         {{ $t("cancel") }}
        </button>
        <button
         v-if="this.isEditedNewNumber"
         @click="newOtherNumber(this.formOther)"
         type="button"
         class="px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm encom_primary encom_primary_hover hover:bg-gray-50 focus:outline-none"
         :class="{
          'animate-bounce':
           bounceButton === 'notBundledForm' && unsavedChangesBool,
         }">
         {{ $t("save") }}
        </button>

        <button
         v-else
         @click="newOtherNumber(this.formOther)"
         type="button"
         class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm encom_primary encom_primary_hover encom_ring_color"
         :class="{
          'animate-bounce':
           bounceButton === 'notBundledForm' && unsavedChangesBool,
         }">
         {{ $t("add") }}
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="py-5 border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3">
     <div class="mr-5 md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("invoices") }}
      </h3>

      <p class="mt-1 text-sm text-gray-500">
       <span class="font-semibold"> Télécoms </span>
       :
       {{ $t("telephoneOperatorInvoiceDescription") }}.
      </p>
      <p class="mt-1 text-sm text-gray-500">
       {{ $t("telephoneOperatorInvoiceDescription2") }}.
      </p>
     </div>

     <div
      v-show="documents.length > 0"
      class="mt-1 sm:mt-0 sm:col-span-3 sm:col-start-2">
      <!--  <MandateTable :documents="documents" /> -->
      <InvoiceTable :documents="documents" />
     </div>

     <div class="mx-1 mt-5 sm:col-span-1 sm:col-start-2">
      <label
       for="document-description"
       class="block text-sm font-medium text-gray-700">
       Description
      </label>
      <input
       v-model="this.description"
       type="text"
       name="document-description"
       id="document-description"
       class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
     </div>
     <div class="mt-1 sm:col-span-3 sm:col-start-2">
      <div
       class="flex justify-center w-full px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
       @drop="dropHandler($event)"
       @dragover="dragOverHandler($event)">
       <div class="space-y-1 text-center">
        <svg
         class="w-12 h-12 mx-auto text-gray-400"
         fill="none"
         viewBox="0 0 48 48"
         stroke="currentColor"
         aria-hidden="true">
         <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        </svg>
        <div class="flex justify-center text-sm text-gray-600">
         <label
          for="file-upload"
          class="relative font-medium bg-white rounded-md cursor-pointer encom_ouvert_ticket_text">
          <span> {{ $t("uploadAFile") }}</span>
          <input
           id="file-upload"
           name="file-upload"
           type="file"
           ref="file"
           class="sr-only"
           accept="image/png, image/jpeg, image/jpg, application/pdf"
           @change="uploadFile($event)" />
         </label>
         <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
        </div>
        <div class="flex justify-center">
         <ExclamationCircleIcon
          v-show="errorFormMandateUpload"
          class="w-4 h-4 mr-1 text-red-500"
          aria-hidden="true" />
         <p
          :class="
           errorFormMandateUpload
            ? 'text-xs text-red-600'
            : 'text-xs text-gray-500'
          ">
          PNG, JPG, JPEG, {{ $t("or") }} PDF {{ $t("accepted") }}.
         </p>
        </div>
        <p v-show="this.file.name" class="text-sm text-gray-700">
         <font-awesome-icon
          icon="file-import"
          class="w-4 h-4 text-gray-500"
          aria-hidden="true" />
         {{ this.file.name }}
        </p>
       </div>
      </div>
     </div>
     <div class="col-span-4 mt-5">
      <div class="p-2 rounded-md bg-yellow-50">
       <div class="flex">
        <div class="flex-shrink-0">
         <ExclamationIcon class="w-6 h-6 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-base font-medium text-yellow-800">
          {{ $t("warningTitle") }}
         </h3>
         <div class="text-sm text-yellow-700">
          <p>{{ $t("warning.mandate") }}.</p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="flex justify-end px-4 pb-5">
    <div v-show="errorFormValidation" class="p-4 rounded-md bg-red-50">
     <div class="flex">
      <div class="flex-shrink-0">
       <XCircleIcon class="w-5 h-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
       <h3 class="text-sm font-medium text-red-800">
        {{ $t("thereWere") }} {{ $t("oneOrMore") }}
        {{ $t("errorWithYourSubmission") }}
       </h3>
       <p class="mt-1 text-sm text-red-700">
        Vous devez fournir au moins une tranche de numéros à porter ou ou au
        moins un numéros isolés ou une tranche de numéros à créer.
       </p>
      </div>
     </div>
    </div>
    <div
     :class="errorFormValidation ? 'flex justify-end mt-5' : 'flex justify-end'"
     ref="mainForm">
     <button
      type="submit"
      @click="nextStep = false"
      class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm body__header--button"
      :class="{
       'animate-bounce': bounceButton === 'mainForm' && unsavedChangesBool,
      }">
      {{ $t("save") }}
     </button>
     <button
      type="submit"
      @click="nextStep = true"
      class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm body__button">
      {{ $t("nextStep") }}
     </button>
    </div>
   </div>
  </div>
 </form>
 <ask-confirmation-dialog ref="askConfirmationDialog">
 </ask-confirmation-dialog>

 <Loading
  v-model:active="isLoading"
  :can-cancel="false"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import MandateTable from "./formTools/MandateTable.vue";
import InvoiceTable from "./formTools/InvoiceTable.vue";
import Tooltip from "./formTools/Tooltip.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
 PlusSmIcon,
 TrashIcon,
 ExclamationIcon,
 ExclamationCircleIcon,
 XCircleIcon,
} from "@heroicons/vue/solid";

const account = localStorage.getItem("account");

export default {
 name: "CollectNumbers",
 props: [
  "contentData",
  "fileId",
  "dependenciesToBeCleared",
  "savedForm",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "customerFileId",
 ],
 components: {
  AskConfirmationDialog,
  ExclamationIcon,
  ExclamationCircleIcon,
  Loading,
  MandateTable,
  InvoiceTable,
  Tooltip,
  PlusSmIcon,
  TrashIcon,
  XCircleIcon,
 },

 data() {
  return {
   account,
   dataDeleted: false,
   cities: [],
   cityList: [],
   errorFormOtherNumber: false,
   errorFormNumberTown: false,
   errorFormNumberNdi: false,
   errorFormNumberPortability: false,
   errorFormNumberFrom: false,
   errorFormNumberNdiLength: false,
   errorFormNumberFromLength: false,
   errorFormNumberTo: false,
   errorFormNumberToLength: false,
   errorFormNumberFromSmallerThanTo: false,
   errorFormNumberFromAndToEqual: false,
   errorFormNumberInside: false,
   errorFormMandate: false,
   errorFormMandateUpload: false,
   errorFormValidation: false,
   errorRioMsg: "wrongRioFormat",
   errorHasNdi: false,
   errorNoNumber: false,
   errorFormRIOTown: false,
   errorFormRIOPortability: false,
   documents: [],
   documentType: "invoice",
   description: "",
   file: "",
   formNumber: {
    ndi: "",
    from: "",
    to: "",
    town: "",
    portabilityDate: "",
   },
   formRIO: {
    number: "",
    rio: "",
    validCCC: "",
    town: "",
    portabilityDate: "",
   },
   formOther: {
    number: "",
   },
   form: {
    number: {
     range: [],
     rio: [],
     other: [],
    },
    sda: {
     sda: { 0: { number: null, town: "", numberIdentifier: "" } },
    },
    sdaUsed: { sdaMax: 0, sdaLeft: 0 },
    portability: [],
    isComplete: true,
   },
   numberCode: ["01", "02", "03", "04", "05", "09"],
   nextStep: false,
   fullPage: true,
   isEditedNewNumber: false,
   isEditedRange: false,
   isEditedRio: false,
   isLoading: false,
   invalidRio: false,
   invalidNumber: false,
   invoiceIdx: 0,
   phoneNumber: null,
   mandateIdx: 0,
   rangeIdx: null,
   rangeTotal: 0,
   rangeEditDiff: 0,
   rio: "",
   rioIdx: null,
   sdaTown: [],
   stepName: "numbers",
  };
 },

 methods: {
  getData() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.form = JSON.parse(JSON.stringify(this.contentData[this.stepName]));
   }
   if (this.contentData["mainInfos"]) {
    this.setCityList(this.contentData["mainInfos"].address);
   }
  },
  setCityList(address) {
   Object.keys(address).forEach(element => {
    let sitename = address[element].name + " (" + address[element].city + ")";
    this.cities.push(sitename);
   });

   this.cityList = [...new Set(this.cities)];
  },
  validateForm(msg) {
   this.errorFormValidation = false;

   let numberRange = this.form.number.range;

   let numberRio = this.form.number.rio;

   let sda = Object.keys(this.form.sda.sda);

   for (let index = 0; index < sda.length; index++) {
    const element = sda[index];
    if (this.form.sda.sda[element].number < 1) {
     delete this.form.sda.sda[element];
    }
   }
   if (numberRange.length > 0 || numberRio.length > 0 || sda.length > 0) {
    this.sendForm(msg);
   } else {
    this.errorFormValidation = true;
   }
  },
  sendForm(msg) {
   var totalSda = 0;
   var totalRange = 0;
   var townList = [];
   let sdaObj = {};
   let rangeObj = {};
   let isolatedObj = {};
   let sdaKeys = [];
   let rangeKeys = [];
   let isolatedKeys = [];
   let content = this.contentData;
   let depententKeys = this.dependenciesToBeCleared(this.stepName);

   if (this.dataDeleted) {
    for (let index = 0; index < depententKeys.length; index++) {
     const element = depententKeys[index];
     delete content[element];
    }
   }
   //Calcul the total of number to be carried
   rangeKeys = Object.keys(this.form.number.range);
   rangeObj = this.form.number.range;
   rangeKeys.forEach(element => {
    let diff = rangeObj[element].to - rangeObj[element].from + 1;
    totalRange += diff;
   });

   //Calcul the sum of isolated numbers added by the user
   totalSda += this.form.number.rio.length;

   //Calcul the sum of SDA chosen by the user
   sdaKeys = Object.keys(this.form.sda.sda);
   sdaObj = this.form.sda.sda;
   sdaKeys.forEach(element => {
    totalSda += sdaObj[element].number;
   });
   totalSda += totalRange;
   console.log("sendForm ~ totalSda:", totalSda);

   //Calcul the sum of not-bundled number created by the user

   this.form.sdaUsed ? "" : (this.form.sdaUsed = { sdaMax: 0, sdaLeft: 0 });
   console.log(
    "sendForm ~ this.form.sdaUsed.sdaMax:",
    this.form.sdaUsed.sdaMax
   );
   if (this.form.sdaUsed.sdaMax < totalSda) {
    const sdaDiff = this.form.sdaUsed.sdaMax - this.form.sdaUsed.sdaLeft;
    this.form.sdaUsed.sdaLeft = totalSda - sdaDiff;
    this.form.sdaUsed.sdaMax = totalSda;
   } else {
    const sdaDiff = this.form.sdaUsed.sdaMax - totalSda;
    this.form.sdaUsed.sdaLeft = totalSda;
    this.form.sdaUsed.sdaLeft < 0 ? (this.form.sdaUsed.sdaLeft = 0) : "";
    this.form.sdaUsed.sdaMax = totalSda;
   }
   console.log(
    "sendForm ~ this.form.sdaUsed.sdaLeft:",
    this.form.sdaUsed.sdaLeft
   );
   console.log(
    "sendForm ~ this.form.sdaUsed.sdaMax:",
    this.form.sdaUsed.sdaMax
   );
   content[this.stepName] = { ...this.form };
   content = JSON.stringify(content);

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then(res => {
     let nextFormIndex = parseInt(this.$route.params.formIndex) + 1;

     if (msg) {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     }
     this.$emit("sdaTown", this.sdaTown);
     if (this.nextStep) {
      window.scrollTo({
       top: 0,
       behavior: "smooth",
      });
      window.onscroll = () => {
       if (window.pageYOffset === 0) {
        window.onscroll = null;
        // Perform any additional actions here
        this.$router.push(
         `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
        );
       }
      };
     }
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  addNewRio(form) {
   this.invalidRio = false;
   this.errorFormRIOTown = false;
   this.errorFormRIOPortability = false;
   this.errorHasNdi = false;
   this.errorNoNumber = false;
   form.number = form.number.replace(/\s/g, "");
   let hasNdi = false;
   this.validCCC(this.rio, form.number);
   //RIO is not mandatory but might be change in the future
   //before the first condition was if(form.number && form.io)

   if (form.number)
    hasNdi = this.form.number.range.some(obj =>
     Object.values(obj).includes(form.number)
    );

   if (form.number && form.town && form.portabilityDate && !hasNdi) {
    if (this.validCCC(form.rio, form.number)) {
     var obj = {};
     obj["number"] = form.number;
     obj["rio"] = form.rio;
     obj["town"] = form.town;
     obj["portabilityDate"] = form.portabilityDate;
     obj["validCCC"] = "ok";

     this.form.number.rio.push(obj);
     this.phoneNumber = null;
     this.rio = "";
    } else {
     var obj = {};
     obj["number"] = form.number;
     obj["rio"] = form.rio;
     obj["town"] = form.town;
     obj["portabilityDate"] = form.portabilityDate;
     if (form.rio) obj["validCCC"] = "ko";

     this.form.number.rio.push(obj);
     this.phoneNumber = null;
     this.rio = "";
     this.clearRioForm();
    }
   } else {
    this.invalidNumber = true;
    form.rio ? (this.invalidRio = true) : (this.invalidRio = false);
    hasNdi ? (this.errorHasNdi = true) : (this.errorHasNdi = false);
    !form.number ? (this.errorNoNumber = true) : (this.errorNoNumber = false);
    !form.town
     ? (this.errorFormRIOTown = true)
     : (this.errorFormRIOTown = false);
    !form.portabilityDate
     ? (this.errorFormRIOPortability = true)
     : (this.errorFormRIOPortability = false);
    this.errorRioMsg = "provideCorrectNumberOrRio";
   }
  },
  newOtherNumber(form) {
   this.errorFormOtherNumber = false;

   form.number = form.number.replace(/\s/g, ""); // removes all spaces from 'number'

   const isnum = /^\d+$/.test(form.number);
   if (form.number && isnum && form.number.length === 10) {
    this.addNewOtherNumber(form);
   } else {
    this.errorFormOtherNumber = true;
    console.error("The value type is not a number");
    return false;
   }
  },

  newRangeChecker(form) {
   this.errorFormNumberNdi = false;
   this.errorFormNumberFrom = false;
   this.errorFormNumberTo = false;
   this.errorFormNumberFromSmallerThanTo = false;
   this.errorFormNumberFromAndToEqual = false;
   this.errorFormNumberInside = false;
   this.errorFormNumberTown = false;
   this.errorFormNumberPortability = false;

   form.ndi = form.ndi.replace(/\s/g, ""); // removes all spaces from 'from'
   form.from = form.from.replace(/\s/g, ""); // removes all spaces from 'from'
   form.to = form.to.replace(/\s/g, ""); // removes all spaces from 'to'

   const isnumFrom = /^\d+$/.test(form.from);
   const isnumTo = /^\d+$/.test(form.to);
   const isnumNdi = /^\d+$/.test(form.ndi);
   if (
    form.ndi &&
    isnumNdi &&
    form.from &&
    form.to &&
    isnumFrom &&
    isnumTo &&
    form.town &&
    form.portabilityDate
   ) {
    this.newRangeFormat(form);
   } else {
    !isnumNdi
     ? (this.errorFormNumberNdi = true)
     : (this.errorFormNumberNdi = false);
    !isnumFrom
     ? (this.errorFormNumberFrom = true)
     : (this.errorFormNumberFrom = false);
    !isnumTo
     ? (this.errorFormNumberTo = true)
     : (this.errorFormNumberTo = false);
    !form.town
     ? (this.errorFormNumberTown = true)
     : (this.errorFormNumberTown = false);
    !form.portabilityDate
     ? (this.errorFormNumberPortability = true)
     : (this.errorFormNumberPortability = false);
    console.error("The value type is not a number");
    return false;
   }
  },
  newRangeFormat(form) {
   if (
    form.ndi.length === 10 &&
    form.from.length === 10 &&
    form.to.length === 10 &&
    form.town &&
    form.portabilityDate
   ) {
    this.newRangeSmaller(form);
   } else {
    form.ndi.length !== 10
     ? (this.errorFormNumberNdiLength = true)
     : (this.errorFormNumberNdiLength = false);
    form.from.length !== 10
     ? (this.errorFormNumberFromLength = true)
     : (this.errorFormNumberFromLength = false);
    form.to.length !== 10
     ? (this.errorFormNumberToLength = true)
     : (this.errorFormNumberToLength = false);
    console.error("The number chosen doesn't use format 0123456789");
    return false;
   }
  },
  /* newRangeSmaller(form) {
   if (parseInt(form.from) > parseInt(form.to)) {
    this.errorFormNumberFromSmallerThanTo = true;
    console.error("The start of the range can't be smaller than the end");
    return false;
   } else if (parseInt(form.from) === parseInt(form.to)) {
    this.errorFormNumberFromAndToEqual = true;
    console.error(
     "The start of the range is equel to than the end. If you want to add a single phone number, please use Isolated Numbers instead."
    );
    return false;
   } else {
    this.newRangeBetween(form);
   }
  }, */
  newRangeSmaller(form) {
   if (parseInt(form.from) > parseInt(form.to)) {
    this.errorFormNumberFromSmallerThanTo = true;
    console.error("The start of the range can't be smaller than the end");
    return false;
   } else {
    this.newRangeBetween(form);
   }
  },
  newRangeBetween(form) {
   let existedRange = this.form.number.range;

   if (existedRange.length > 0 && !this.isEditedRange) {
    for (let index = 0; index < existedRange.length; index++) {
     const element = existedRange[index];

     if (form.from >= element.from && form.from <= element.to) {
      this.errorFormNumberInside = true;
      return false;
     } else if (form.from <= element.from && form.to >= element.from) {
      this.errorFormNumberInside = true;
      return false;
     } else {
      this.errorFormNumberInside = false;
      this.addNewRange(form);
      return true;
     }
    }
   } else if (existedRange.length > 0 && this.isEditedRange) {
    let filteredArray = JSON.parse(JSON.stringify(this.form.number.range));
    filteredArray.splice(this.rangeIdx, 1);
    if (filteredArray.length > 0) {
     for (let index = 0; index < filteredArray.length; index++) {
      const element = filteredArray[index];

      if (form.from >= element.from && form.from <= element.to) {
       this.errorFormNumberInside = true;
       return false;
      } else if (form.from <= element.from && form.to >= element.from) {
       this.errorFormNumberInside = true;
       return false;
      } else {
       this.errorFormNumberInside = false;
       this.editRange(form);
       return true;
      }
     }
    } else {
     this.editRange(form);

     return true;
    }
   } else {
    this.addNewRange(form);
   }
  },
  addNewOtherNumber(form) {
   var obj = {};

   obj["number"] = form.number;
   if (this.rangeIdx !== null && this.isEditedNewNumber) {
    this.form.number.other[this.rangeIdx].number = form.number;
   } else {
    if (this.form.number.other) {
     this.form.number.other.push(obj);
    } else {
     this.form.number.other = [];
     this.form.number.other.push(obj);
    }
   }

   this.clearOtherNumberForm();
  },
  addNewRange(form) {
   var obj = {};
   var range = parseInt(form.to) - parseInt(form.from);
   this.rangeTotal += range;

   obj["ndi"] = form.ndi;
   obj["from"] = form.from;
   obj["to"] = form.to;
   obj["town"] = form.town;
   obj["portabilityDate"] = form.portabilityDate;
   this.form.number.range.push(obj);

   this.formNumber.ndi = "";
   this.formNumber.from = null;
   this.formNumber.to = null;

   this.isEditedRange = false;
   this.errorFormNumberFrom = false;
   this.errorFormNumberFromLength = false;
   this.errorFormNumberTo = false;
   this.errorFormNumberToLength = false;
   this.errorFormNumberFromSmallerThanTo = false;
   this.errorFormNumberInside = false;
  },
  editOtherNumber(selectedNumber, idx) {
   this.isEditedNewNumber = true;
   this.formOther.number = selectedNumber.number;
   this.rangeIdx = idx;
  },
  editNewRange(selectedRange, idx) {
   this.rangeEditDiff =
    parseInt(selectedRange.to) - parseInt(selectedRange.from);

   this.isEditedRange = true;
   this.formNumber.ndi = selectedRange.ndi;
   this.formNumber.from = selectedRange.from;
   this.formNumber.to = selectedRange.to;
   this.formNumber.town = selectedRange.town;
   this.formNumber.portabilityDate = selectedRange.portabilityDate;
   this.rangeIdx = idx;
  },
  editNewRio(selectedRio, idx) {
   this.isEditedRio = true;
   this.phoneNumber = selectedRio.number;
   this.rio = selectedRio.rio;
   this.formRIO.town = selectedRio.town;
   this.formRIO.portabilityDate = selectedRio.portabilityDate;
   this.rioIdx = idx;
  },
  editRange(form) {
   if (form.ndi && form.from && form.to && form.town && form.portabilityDate) {
    var obj = {};
    var range = parseInt(form.to) - parseInt(form.from);
    var absRange = Math.abs(range - this.rangeEditDiff);

    this.rangeTotal += absRange;

    obj["ndi"] = form.ndi;
    obj["from"] = form.from;
    obj["to"] = form.to;
    obj["town"] = form.town;
    obj["portabilityDate"] = form.portabilityDate;
    this.form.number.range[this.rangeIdx].ndi = obj.ndi;
    this.form.number.range[this.rangeIdx].from = obj.from;
    this.form.number.range[this.rangeIdx].to = obj.to;
    this.form.number.range[this.rangeIdx].town = obj.town;
    this.form.number.range[this.rangeIdx].portabilityDate = obj.portabilityDate;

    this.formNumber.ndi = "";
    this.formNumber.from = null;
    this.formNumber.to = null;
    this.isEditedRange = false;
    this.errorFormNumberFrom = false;
    this.errorFormNumberFromLength = false;
    this.errorFormNumberTo = false;
    this.errorFormNumberToLength = false;
    this.errorFormNumberFromSmallerThanTo = false;
    this.errorFormNumberInside = false;
   } else {
    console.log("err");
   }
  },
  editRio(form) {
   //RIO is not mandatory but might be change in the future
   //before the first condition was if(form.number && form.io)
   this.invalidRio = false;

   if (form.number) {
    if (this.validCCC(form.rio, form.number)) {
     var obj = {};
     obj["number"] = form.number;
     obj["rio"] = form.rio;
     obj["town"] = form.town;
     obj["portabilityDate"] = form.portabilityDate;
     obj["validCCC"] = "ok";

     this.form.number.rio[this.rioIdx].number = obj.number;
     this.form.number.rio[this.rioIdx].rio = obj.rio;
     this.form.number.rio[this.rioIdx].town = obj.town;
     this.form.number.rio[this.rioIdx].portabilityDate = obj.portabilityDate;
     this.form.number.rio[this.rioIdx].validCCC = obj.validCCC;
     this.number = null;
     this.rio = "";
     this.isEditedRio = false;
     this.clearRioForm();
    } else {
     var obj = {};
     obj["number"] = form.number;
     obj["rio"] = form.rio;
     obj["town"] = form.town;
     obj["portabilityDate"] = form.portabilityDate;
     if (form.rio) obj["validCCC"] = "ko";

     this.form.number.rio[this.rioIdx].number = obj.number;
     this.form.number.rio[this.rioIdx].rio = obj.rio;
     this.form.number.rio[this.rioIdx].town = obj.town;
     this.form.number.rio[this.rioIdx].portabilityDate = obj.portabilityDate;
     this.form.number.rio[this.rioIdx].validCCC = obj.validCCC;
     this.number = null;
     this.rio = "";
     this.isEditedRio = false;
     this.clearRioForm();
    }
   } else {
    this.invalidNumber = true;
    this.invalidRio = true;

    this.errorRioMsg = "provideCorrectNumberOrRio";
   }
  },
  deleteOtherNumber(idx) {
   this.form.number.other.splice(idx, 1);
  },
  deleteNewRange(idx) {
   this.form.number.range.splice(idx, 1);
  },
  deleteNewRio(idx) {
   this.form.number.rio.splice(idx, 1);
  },
  addObject(formObject) {
   if (Object.keys(formObject).length < 1) {
    const objKey = 0;
    const objValue = { number: "", town: "", numberIdentifier: "" };
    const newObj = { [objKey]: objValue };

    this.form.sda.sda = { ...formObject, ...newObj };
   } else {
    for (const key in formObject) {
     if (Object.hasOwnProperty.call(formObject, key)) {
      const objKey = parseInt(key) + 1;

      const objValue = { number: "", town: "", numberIdentifier: "" };
      const newObj = { [objKey]: objValue };

      this.form.sda.sda = { ...formObject, ...newObj };
     }
    }
   }
  },
  async deleteObject(formObject, formProp, index) {
   for (const key in formObject) {
    if (Object.hasOwnProperty.call(formObject, key)) {
     if (formProp === "range") {
      delete this.form.number.range[index];
     } else if (formProp === "rio") {
      delete this.form.number.rio[index];
     } else {
      delete this.form.sda.sda[index];
     }
    }
   }
   this.dataDeleted = true;
  },
  clearOtherNumberForm() {
   this.formOther.number = "";
   this.isEditedNewNumber = false;
   this.errorFormOtherNumber = false;
   this.rangeIdx = null;
  },
  clearRangeForm() {
   this.formNumber.ndi = "";
   this.formNumber.from = "";
   this.formNumber.to = "";
   this.isEditedRange = false;
   this.rangeIdx = null;

   this.errorFormNumberNdi = false;
   this.errorFormNumberNdiLength = false;
   this.errorFormNumberFrom = false;
   this.errorFormNumberFromLength = false;
   this.errorFormNumberTo = false;
   this.errorFormNumberToLength = false;
   this.errorFormNumberFromSmallerThanTo = false;
   this.errorFormNumberInside = false;
  },
  clearRioForm() {
   this.formRIO.number = "";
   this.phoneNumber = "";
   this.formRIO.rio = "";
   this.isEditedRio = false;
  },
  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "application/pdf" ||
      type === "image/jpg" ||
      type === "image/jpeg" ||
      type === "image/png"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormMandateUpload = false;
     } else {
      this.errorFormMandateUpload = true;
     }
    }
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  uploadFile() {
   this.file = this.$refs.file.files[0];
   if (this.file) submitFile($t("noFileHasBeenUploaded"));
  },
  submitFile(noFileMsg) {
   this.sendForm();
   /* Initialize the form data */
   let formData = new FormData();
   if (this.file) {
    if (this.documentType) {
     let fileDescriptionIndex = Number.isNaN(this.invoiceIdx)
      ? this.documents.length - 1
      : this.invoiceIdx + 1;
     let fileDescription =
      this.documentType +
      "_" +
      fileDescriptionIndex +
      "_" +
      this.description.replace(/ /g, "_");

     formData.append("file", this.file);

     /* Add the form data we need to submit */
     this.isLoading = true;
     axios
      .post(`${this.$cookie.getCookie("API")}/api/v1/documents`, formData, {
       params: {
        customerAccount: this.account,
        documentable_type: "App\\InformationCollection",
        documentable_id: this.fileId,
        fileDescription: fileDescription,
       },
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      })
      .then(res => {
       this.errorFormMandate = false;
       this.isLoading = false;
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.description = "";

       const newObj = {
        id: res.data.id,
        type: res.data.fileDescription.split("_")[0],
       };

       this.form.portability.push(newObj);
       this.sendForm(this.$t("documentImportedSuccessfully"));
       this.getFile();
      })
      .catch(error => {
       this.isLoading = false;
       console.error(error);
      });
    } else {
     this.errorFormMandate = true;
    }
   } else {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", noFileMsg);
   }
  },
  async getFile() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/documents`,
     {
      params: {
       customerAccount: this.account,
       documentable_type: "App\\InformationCollection",
       documentable_id: this.fileId,
      },
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    const data = res.data;
    const arrWithData = [];
    for (let index = 0; index < data.length; index++) {
     const document = data[index];
     const documentName = document.fileDescription.split("_");
     const documentType = documentName[0];
     const documentIndex = documentName[1];

     const obj = {
      name: "",
      people: [],
     };

     if (data.fileDescription !== "message studio") {
      obj.name = "Dossier" + " " + documentIndex;

      //look into the data object and get the object with the condition :  documentIndex === obj.name.split(" ")[1])
      documentIndex === obj.name.split(" ")[1] ? obj.people.push(document) : "";

      arrWithData.push(obj);
      documentType === "mandate"
       ? (this.mandateIdx = parseInt(documentIndex))
       : (this.invoiceIdx = parseInt(documentIndex));
     }
    }

    var output = new Array();
    arrWithData.forEach(elem => {
     var found = false;
     for (var i = 0; i < output.length; i++) {
      if (output[i].name == elem.name) {
       output[i].people = [...output[i].people, ...elem.people];
       var found = true;
       break;
      }
     }
     if (!found) {
      output.push(elem);
     }
    });

    this.documents = output;
   } catch (error) {
    console.error(error);
    this.errorHandling(error);
   }
  },
  isNumber(evt) {
   evt = evt ? evt : window.event;
   var charCode = evt.which ? evt.which : evt.keyCode;
   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
   } else {
    return true;
   }
  },
  roundUp(numb, idx) {
   numb = Math.ceil(numb / 5) * 5;

   this.form.sda.sda[idx].number = numb;
  },
 },
 mounted() {
  this.getData();
  this.getFile();
 },
 watch: {
  rio: function (val) {
   let rioSpaceLess = val.replace(/\s+/g, "");

   //  if (val && this.rioRegexMatch(val) && rioSpaceLess.length == 12) {
   if (val && rioSpaceLess.length == 12) {
    this.formRIO.rio = val;
    this.invalidRio = false;
   } else if (!val || val.length == 0) {
    this.invalidRio = false;
   } else {
    this.invalidRio = true;
   }
  },
  phoneNumber: function (val) {
   let phoneNumberSpaceLess = val.replace(/\s+/g, "");
   if (phoneNumberSpaceLess && phoneNumberSpaceLess.length == 0) {
    this.invalidNumber = false;
   } else if (
    phoneNumberSpaceLess &&
    (phoneNumberSpaceLess.length < 10 || phoneNumberSpaceLess.length > 13)
   ) {
    this.invalidNumber = true;
   } else {
    this.formRIO.number = val;
    this.invalidNumber = false;
   }
  },
  elementRef: function (ref) {
   if (ref === "mainForm") {
    const el = this.$refs.mainForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   } else if (ref === "contactForm") {
    const el = this.$refs.contactForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   } else if (ref === "addressForm") {
    const el = this.$refs.addressForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },

  form: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "mainForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
  formNumber: {
   handler: function (object) {
    if (object.from !== "" || object.to !== "") {
     this.$emit("unsavedChanges", true, "numberForm");
    } else {
     // this.$emit("unsavedChanges", false, "");
    }
   },
   deep: true,
  },

  formRIO: {
   handler: function (object) {
    if (object.number !== "" || object.rio !== "") {
     this.$emit("unsavedChanges", true, "numberIsolatedForm");
    } else {
     //this.$emit("unsavedChanges", false, "");
    }
   },
   deep: true,
  },
 },
};
</script>

<style>
.form-leave-active,
.form-enter-active {
 transition: all 0.3s;
}

.form-leave-to /* .form-leave-active below version 2.1.8 */ {
 opacity: 0;
 transform: translateY(-30px);
}
.form-enter-from /* .form-leave-active below version 2.1.8 */ {
 opacity: 100;
 transform: translateY(-30px);
}
</style>
