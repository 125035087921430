<template>
 <body class="flex items-center justify-center">
  <div
   class="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
  >
   <table
    class="w-full divide-y divide-gray-300 flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
   >
    <thead class="bg-gray-100 divide-y divide-gray-200">
     <tr
      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase flex flex-col flex-no-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
     >
      <th
       v-for="header in this.tableHeaders"
       :key="header.name"
       scope="col"
       :class="header.name === thead[0] ? 'pl-4 sm:pl-6 pr-3' : 'p-3 truncate'"
      >
       <div
        class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
        @click="this.setSorting(header.sort, header.name), (header.isSort = !header.isSort)"
       >
        <span :class="[header.name === this.activeSorting ? `underline` : '', 'mr-1']">
         {{ $t(header.name) }}
        </span>
       </div>
      </th>
     </tr>
    </thead>
    <tbody
     v-show="this.tablePaginatedData.length > 0"
     class="flex-1 sm:flex-none divide-y divide-gray-200 text-sm"
    >
     <tr
      v-for="(msg, idx) in this.tablePaginatedData"
      :key="msg.id"
      :class="[
       idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
       'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0',
      ]"
     >
      <td
       class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6"
      >
       {{ msg.name }}
      </td>
      <td class="px-3 py-4 text-sm truncate text-gray-500">
       {{ msg.messageOption === "import-message" ? $t("customImportedFile") : msg.music }}
      </td>

      <td class="px-3 py-4 text-sm truncate text-gray-500">
       {{ msg.messageOption === "import-message" ? $t("customImportedFile") : msg.voice }}
      </td>
      <td class="px-3 py-4 text-sm truncate text-gray-500" :title="msg.message">
       {{ msg.messageOption === "import-message" ? $t("customImportedFile") : msg.message }}
      </td>
      <!-- <td class="p-3 truncate">
              {{ msg.phonetic }}
            </td> -->
      <td class="px-6 py-4 whitespace-nowrap text-right justify-end text-sm font-medium flex">
       <button
        @click="$emit('editForm', idx)"
        :class="`p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`"
        :title="$t('edit')"
       >
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor"
        >
         <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
         <path
          fill-rule="evenodd"
          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
          clip-rule="evenodd"
         />
        </svg>
       </button>
       <a
        @click="
         $emit(
          'deleteItem',
          idx,
          $t('deleteMessage'),
          $t('deleteStudioMessage') + ' ' + `${msg.name}`,
          $t('studioMessageDeletedSuccessfully'),
          $t('delete'),
          $t('cancel')
         )
        "
        href="javascript:void(0)"
        :class="`p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`"
        :title="$t('delete')"
       >
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor"
        >
         <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
         /></svg
       ></a>
      </td>
     </tr>
    </tbody>
   </table>
   <!-- <div v-show="this.tablePaginatedData.length < 1" class="text-center py-5">
        <svg
          class="mx-auto h-16 w-16 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>

        <h3 class="mt-2 text-xl font-medium text-gray-900">
          No Results
        </h3>
        <p class="mt-1 text-base text-gray-500">
          You don't have any results for this filter.
        </p>
      </div> -->
  </div>
 </body>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["tablePaginatedData"],
 components: {
  Loading,
 },
 data() {
  return {
   thead: ["name", "type", "associatedTo", "music", "voice", "message"],
   tableHeaders: [
    { name: "name", sort: "", isSort: false },
    { name: "music", sort: "", isSort: false },
    { name: "voice", sort: "", isSort: false },
    { name: "message", sort: "", isSort: false },
    { name: "", sort: "", isSort: false },
   ],
   isLoading: false,
   fullPage: true,
   activeSorting: "",
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
