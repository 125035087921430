<template>
 <div class="min-h-screen flex flex-col bg-gray-50 -m-10">
  <main
   class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8"
  >
   <div class="mx-auto max-w-max">
    <main class="sm:flex">
     <p class="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
      <a href="/" class="inline-flex">
       <span class="sr-only">Workflow</span>
       <img class="h-20 w-auto bg-gray-200 hover:bg-gray-300 p-2 rounded-lg" :src="logo" alt="" />
      </a>
     </p>
     <div class="sm:ml-6">
      <div class="sm:border-l sm:border-gray-200 sm:pl-6">
       <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {{ !isSubmitted ? $t("collectLocked") : $t("collectCompleted") }}
       </h1>
       <p class="mt-1 text-base text-gray-500">
        {{
         collectStatus === "ready"
          ? $t("collectReadyDescription")
          : !isSubmitted
          ? $t("collectLockedDescription")
          : $t("collectCompletedDescription")
        }}
       </p>
       <p v-if="!isSubmitted && collectStatus !== 'ready'" class="mt-1 text-sm text-gray-400">
        {{ locked_at ? $t("collectLockedAt", { date: $d(locked_at, "longNumb") }) : "" }}.
       </p>
       <!-- <p v-else class="mt-1 text-sm text-gray-400">
        {{ new Date() ? $t("collectSubmittedAt", { date: $d(new Date(), "longNumb") }) : "" }}.
       </p> -->
      </div>
      <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
       <a
        href="javascript:void(0)"
        @click="this.$router.push(`/customer-files/collectList/${collectId}`)"
        class="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium encom_primary encom_primary_hover text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
       >
        {{ $t("goBackCollectList") }}
       </a>
       <a
        v-if="!isSubmitted"
        href="javascript:void(0)"
        @click="this.$emit('refresh')"
        @mouseenter="isHover = true"
        @mouseleave="isHover = false"
        class="space-x-2 inline-flex items-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
       >
        <font-awesome-icon
         icon="arrows-rotate"
         class="flex-shrink-0 h-4 w-4"
         :class="{ 'fa-spin': isHover }"
        />

        <span>
         {{ $t("refresh") }}
        </span>
       </a>
      </div>
     </div>
    </main>
   </div>
  </main>
 </div>
</template>

<script>
const logo = localStorage.getItem("logo");
export default {
 name: "CollectLock",
 props: ["collectId", "locked_at", "isSubmitted", "collectStatus"],
 data() {
  return {
   logo,
   isHover: false,
  };
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   this.$store.dispatch("user", {});
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
};
</script>

<style></style>
