<template>
  <div class="flex">
    <div class="flex-shrink-0">
      <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-red-800 mt-px">
        {{ message }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style></style>
