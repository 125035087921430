<template>
 <div class="mt-5 bg-yellow-50 border-l-4 border-yellow-400 p-2">
  <div class="flex items-center">
   <div class="flex-shrink-0">
    <font-awesome-icon
     icon="exclamation-triangle"
     class="h-5 w-5 text-yellow-400 fa-fade"
     aria-hidden="true"
    />
   </div>
   <div class="ml-2">
    <p class="text-sm font-semibold text-yellow-700">{{ $t("importUserWarning") }}.</p>
   </div>
  </div>
 </div>
 <form @submit.prevent="formValidation()" method="POST">
  <div class="space-y-6 mt-2">
   <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("importFile") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">{{ $t("importFileDetail") }}.</p>
      <p class="mt-1 text-sm text-gray-500">
       {{ $t("download") }}
       <a
        href="/documents/users_collect.xlsx"
        class="text-gray-800 hover:text-gray-500 underline"
        download
       >
        {{ $t("here") }}
       </a>
       {{ $t("importFileDetail2") }}.
      </p>
     </div>

     <div class="sm:col-span-3 sm:col-start-2">
      <div
       v-if="errors.isError"
       :class="[showAll ? 'h-auto' : 'h-36', 'overflow-auto rounded-md bg-red-50 pt-4 my-2']"
      >
       <div class="flex px-4">
        <div class="flex-shrink-0">
         <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-sm font-semibold text-red-800">
          {{ $t("errorImportUsers", { number: errors.count }) }}
         </h3>
         <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc pl-5 space-y-1">
           <li v-for="(error, idx) in errors.lists" :key="idx">
            {{ error }}
           </li>
          </ul>
         </div>
        </div>
       </div>
       <div v-show="errors.lists.length > 3" class="relative sticky bottom-0 text-center">
        <button
         type="button"
         @click="showAll = !showAll"
         class="mb-1 z-10 sticky inline-flex items-center px-2.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
        >
         {{ !showAll ? $t("show") + " " + `${errors.count}` + " " + $t("errors") : $t("showLess") }}
        </button>

        <div class="z-0 absolute inset-0 bg-red-100 filter blur-sm"></div>
       </div>
      </div>
      <div v-else-if="isSuccess" class="rounded-md bg-green-50 p-4 my-2">
       <div class="flex">
        <div class="flex-shrink-0">
         <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-sm font-semibold text-green-800">
          {{ $t("successfullyUploaded") }}
         </h3>
         <div class="text-sm text-green-700">
          <p>
           {{ $t("clickToValidateImportation", { users: usersImported }) }}

           <span
            class="capitalize inline-flex justify-center p-1 border border-transparent text-sm font-medium rounded-md text-white body__button"
           >
            {{ $t("validate") }}
           </span>
           {{ $t("below") }}.
          </p>
         </div>
        </div>
       </div>
      </div>
      <div
       class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-500"
       @drop="dropHandler($event)"
       @dragover="dragOverHandler($event)"
      >
       <div class="space-y-1 text-center">
        <svg
         class="mx-auto h-12 w-12 text-gray-400"
         fill="none"
         viewBox="0 0 48 48"
         stroke="currentColor"
         aria-hidden="true"
        >
         <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
         />
        </svg>
        <div class="flex justify-center text-sm text-gray-600">
         <label
          for="file-upload"
          class="relative cursor-pointer bg-white rounded-md font-medium text-main-color-dark text-main-color-dark--hover"
         >
          <span class="underline"> {{ $t("uploadAFile") }}</span>
          <input
           id="file-upload"
           name="file-upload"
           type="file"
           ref="file"
           class="sr-only"
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
           @change="uploadFile($event)"
          />
         </label>
         <p class="pl-1">{{ $t("or") }} {{ $t("dragAndDrop") }}</p>
        </div>
        <div class="flex justify-center">
         <ExclamationCircleIcon
          v-show="errorFormImport"
          class="h-4 w-4 text-red-500 mr-1"
          aria-hidden="true"
         />
         <p :class="errorFormImport ? 'text-xs text-red-600' : 'text-xs text-gray-500'">
          Format .xls {{ $t("or") }} .xlsx {{ $t("accepted") }}.
         </p>
        </div>
        <p v-show="this.file.name" class="text-sm text-gray-700">
         <font-awesome-icon icon="file-import" class="h-4 w-4 text-gray-500" aria-hidden="true" />
         {{ this.file.name }}
        </p>
       </div>
      </div>

      <div v-show="unvalidate" class="mt-3 rounded-md bg-red-50 p-4">
       <div class="flex">
        <div class="flex-shrink-0">
         <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-sm font-medium text-red-800">
          Oups... {{ $t("somethingWentWrongWhileImporting") }}.
         </h3>
        </div>
       </div>
      </div>
     </div>
     <!-- <div class="col-span-6 flex justify-end px-4 mt-2">
      <button
       @click="submitFile('No file has been added')"
       type="button"
       class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white encom_primary encom_primary_hover encom_ring_color"
      >
       {{ $t("upload") }}
      </button>
     </div> -->
    </div>
   </div>

   <div class="pb-5 flex justify-end">
    <button
     type="button"
     class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none encom_ring_color"
     @click="this.$emit('closeForm', false), (unvalidate = false)"
    >
     {{ $t("cancel") }}
    </button>
    <!-- <button
     type="submit"
     class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none encom_ring_color"
    >
     {{ $t("validate") }}
    </button> -->
   </div>
  </div>
 </form>

 <Loading
  v-model:active="isLoading"
  :can-cancel="false"
  loader="dots"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";

import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Listbox from "./formTools/Listbox.vue";
import {
 CheckCircleIcon,
 ExclamationCircleIcon,
 ExclamationIcon,
 XCircleIcon,
} from "@heroicons/vue/solid";

const account = localStorage.getItem("account");

export default {
 name: "CollectImportUsers",
 props: ["contentData", "fileId"],
 components: {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  Listbox,
  Loading,
  SearchExtensionsGroup,
  ToggleWithIcon,
  XCircleIcon,
 },

 data() {
  return {
   account,
   errors: { isError: false, count: null, lists: [] },
   fullPage: true,
   form: {},
   file: "",
   isLoading: false,
   isSuccess: false,
   newUsers: [],
   sdaMax: null,
   sdaLeft: null,
   sdaUsed: null,
   showAll: false,
   unvalidate: false,
   usersImported: null,
  };
 },

 methods: {
  uploadFile() {
   this.file = this.$refs.file.files[0];
   this.submitFile("No file has been added");
  },
  dragOverHandler(ev) {
   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();
  },
  dropHandler(ev) {
   console.log("File(s) dropped");

   // Prevent default behavior (Prevent file from being opened)
   ev.preventDefault();

   if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
     // If dropped items aren't files, reject them
     const type = ev.dataTransfer.items[i].type;

     if (
      type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      type === "application/vnd.ms-excel"
     ) {
      var file = ev.dataTransfer.items[i].getAsFile();
      this.file = file;
      this.errorFormImport = false;
     } else {
      this.errorFormImport = true;
     }
    }
   } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
     console.log("... file[" + i + "].name = " + ev.dataTransfer.files[i].name);
    }
   }
   this.submitFile("No file has been added");
  },
  submitFile(noFileMsg) {
   /* Initialize the form data */
   if (this.file) {
    let formData = new FormData();
    formData.append("file", this.file);
    formData.append("information_collection_id", this.fileId);
    /* Add the form data we need to submit */
    this.isLoading = true;
    axios
     .post(`${this.$cookie.getCookie("API")}/api/v1/informationCollections/extensions`, formData, {
      params: {
       customerAccount: this.account,
      },
      headers: {
       "Content-Type": "multipart/form-data",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     })
     .then((res) => {
      this.isLoading = false;
      this.newUsers = res.data.rows;
      this.errorChecking(res.data);
     })
     .catch((error) => {
      this.isLoading = false;
      console.error(error);
     });
   } else {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", noFileMsg);
   }
  },
  errorChecking(data) {
   this.errors.lists = [];
   if (data.status === "error") {
    this.errors.isError = true;
    this.isSuccess = false;
    this.errors.count = data.errors.length;
    for (let index = 0; index < data.errors.length; index++) {
     const error = data.errors[index];
     this.errors.lists.push(error);
    }
    this.file = "";
   } else if (data.status === "success") {
    this.errors.isError = false;
    this.isSuccess = true;
    this.usersImported = data.rows_count;
    this.countingSdaUsed(data.rows);
   } else {
    this.unvalidate = true;
   }
  },

  /* Check here !! */
  countingSdaUsed(users) {
   var sdaUsedArray = [];
   let userSda = 0;
   let sviSda = 0;
   let callqueueSda = 0;
   users.forEach((user) => {
    if (user.userSettings.sdaNumber.length > 1) {
     let sdaNumbers = user.userSettings.sdaNumber;
     for (let index = 0; index < sdaNumbers.length; index++) {
      const sdaNumber = sdaNumbers[index];
      sdaUsedArray.push(sdaNumber);
     }
    } else if (user.userSettings.sdaNumber.length === 1) {
     sdaUsedArray.push(user.userSettings.sdaNumber);
    }
   });
   /* total sdaNumber for 03/USERS */
   if (this.contentData["users"] && this.contentData["users"].users.length > 0) {
    this.contentData["users"].users.forEach((user) => {
     userSda += user.userSettings.sdaNumber.length;
    });
   }
   /* total sdaNumber for 04/CALLQUEUE */
   if (this.contentData["callQueue"] && this.contentData["callQueue"].length > 0) {
    this.contentData["callQueue"].forEach((callqueue) => {
     callqueueSda += callqueue.associateNumber.length;
    });
   }

   /* total sdaNumber for 05/SVI */
   if (this.contentData["svi"] && this.contentData["svi"].length > 0) {
    this.contentData["svi"].forEach((svi) => {
     sviSda += svi.associateNumber.length;
    });
   }

   this.sdaLeft =
    this.contentData["numbers"].sdaUsed.sdaMax - (sdaUsedArray.length + sviSda + callqueueSda);
   this.sdaLeft < 0 ? (this.sdaLeft = 0) : "";

   this.formValidation();
  },
  formValidation() {
   if (this.usersImported > 0) {
    this.unvalidate = false;

    for (let index = 0; index < this.newUsers.length; index++) {
     const element = this.newUsers[index];
     element["isComplete"] = true;
    }
    this.$emit("newUsers", this.newUsers, this.sdaLeft, this.$t("userUploadedSuccessfully"));
   } else {
    this.unvalidate = true;
   }
  },
 },
 mounted() {},

 watch: {},
};
</script>
