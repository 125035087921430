<template>
 <div class="space-y-6">
  <div class="bg-white shadow mx-5 px-4 py-5 sm:rounded-lg sm:p-6">
   <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">Relations clients</h3>
   </div>

   <form @submit.prevent="sendForm($t('informationSaved'))" method="POST">
    <div class="space-y-6 mt-5">
     <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-3 md:gap-6">
       <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
         {{ $t("CustomerRelationModules") }}
        </h3>
        <div>
         <p class="mt-1 text-sm text-gray-700">Intégrer 3CX à votre CRM vous permet de :</p>
         <ul class="ml-3 text-sm text-gray-500 list-disc">
          <li v-for="feature in featuresCRM" :key="feature.name">
           {{ feature.description }}
          </li>
         </ul>
        </div>
        <div>
         <p class="mt-1 text-sm text-gray-700">Intégrer 3CX à MS365 vous permet de :</p>
         <ul class="ml-3 text-sm text-gray-500 list-disc">
          <li v-for="feature in featuresMS365" :key="feature.name">
           {{ feature.description }}
          </li>
         </ul>
        </div>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="grid grid-cols-6 gap-6">
         <div class="col-span-6 sm:col-span-3">
          <div class="relative flex items-start">
           <div class="flex items-center">
            <ToggleWithIcon
             :validated="this.form.crmIntegration"
             @boolean-toggle="integration = $event"
            />
           </div>
           <div class="ml-3 text-base">
            <label for="comments" class="font-medium text-gray-700">
             {{ integration ? $t("CRMIntegrationActivate") : $t("CRMIntegrationDesactivate") }}
            </label>
           </div>
          </div>
         </div>
         <div v-show="showModules || formIsNotEmpty(form.crmType)" class="col-span-6 sm:col-span-4">
          <div>
           <div class="flex items-center">
            <h2 class="text-gray-500 text-sm font-medium uppercase tracking-wide">
             Sélectionner une ou plusieurs applications à intégrer avec 3CX
            </h2>
           </div>

           <fieldset>
            <div class="mt-1 space-y-1" v-for="(crm, idx) in crmList" :key="crm.id">
             <div class="relative flex items-start">
              <div class="flex items-center h-5">
               <input
                :checked="crm.active"
                @input="crmList[idx].active = !crmList[idx].active"
                :id="crm.name"
                :key="crm.name"
                type="checkbox"
                class="h-4 w-4 body__form--radio border-gray-300 rounded"
               />
              </div>
              <div class="ml-3 text-sm">
               <label :for="crm.name" class="font-medium text-gray-700">{{ $t(crm.name) }}</label>
              </div>
             </div>
            </div>
           </fieldset>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-3 md:gap-6">
       <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Services Experts 3CX</h3>
        <p class="mt-1 text-sm text-gray-500">
         Améliorez simplement la satisfaction clients grâce aux Services Experts 3CX.
        </p>
        <p class="text-sm text-gray-500 font-semibold">Option payante.</p>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="grid grid-cols-6 gap-6">
         <div class="col-span-6 sm:col-span-3">
          <div class="relative flex items-start">
           <div class="flex items-center">
            <ToggleWithIcon
             :validated="this.form.expertServices3cx"
             @boolean-toggle="expertServices3cx = $event"
            />
           </div>
           <div class="ml-3 text-base">
            <label for="comments" class="font-medium text-gray-700">
             {{
              expertServices3cx
               ? $t("expertServices3cxActivate")
               : $t("expertServices3cxDesactivate")
             }}
            </label>
           </div>
          </div>
         </div>
         <div
          v-show="showExpertServices3cx || formIsNotEmpty(form.modules)"
          class="col-span-6 sm:col-span-4"
         >
          <div>
           <div class="flex items-center">
            <h2 class="text-gray-500 text-sm font-medium uppercase tracking-wide">
             Sélectionner un ou plusieurs Services Experts 3CX
            </h2>
           </div>

           <fieldset>
            <div class="mt-1 space-y-1" v-for="(module, idx) in modules" :key="module.id">
             <div class="relative flex items-start">
              <div class="flex items-center h-5">
               <input
                :checked="module.active"
                @input="modules[idx].active = !modules[idx].active"
                :id="module.name"
                :key="module.name"
                type="checkbox"
                class="h-4 w-4 body__form--radio border-gray-300 rounded"
               />
              </div>
              <div class="ml-3 text-sm">
               <label :for="module.name" class="font-medium text-gray-700">
                {{ $t(module.name) }}
               </label>
              </div>
             </div>
            </div>
           </fieldset>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div class="bg-white border px-4 py-5 sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-3 md:gap-6">
       <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Service SMS</h3>
        <p class="mt-1 text-sm text-gray-500">
         Les services SMS permettent d’intégrer l’envoi SMS à vos outils métier et de notifier
         automatiquement vos clients.
        </p>
        <p class="text-sm text-gray-500 font-semibold">Option payante.</p>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="grid grid-cols-6 gap-6">
         <div class="col-span-6 sm:col-span-3">
          <div class="relative flex items-start">
           <div class="flex items-center">
            <ToggleWithIcon :validated="form.sms" @boolean-toggle="form.sms = $event" />
           </div>
           <div class="ml-3 text-base">
            <label for="comments" class="font-medium text-gray-700">
             {{ form.sms ? $t("smsActivate") : $t("smsDesactivate") }}
            </label>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div class="pb-5 flex justify-end" ref="customerRelationForm">
      <button
       type="submit"
       @click="nextStep = false"
       class="capitalize ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button"
       :class="{ 'animate-bounce': bounceButton === 'customerRelationForm' && unsavedChangesBool }"
      >
       {{ $t("save") }}
      </button>
      <button
       type="submit"
       @click="nextStep = true"
       class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button"
      >
       {{ $t("nextStep") }}
      </button>
     </div>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";

import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
const modules = [
 { id: 0, name: "SmartRouting", active: 0 },
 { id: 1, name: "CustomerSatisfacionSurvey", active: 0 },
 { id: 2, name: "VoiceRecognition", active: 0 },
 { id: 3, name: "LiveWebChat", active: 0 },
 { id: 4, name: "WebCallBack", active: 0 },
];
const crmList = [
 { id: 1, name: "Microsoft Office 365", active: false },
 { id: 2, name: "MS Exchange (Plugin)", active: false },
 { id: 3, name: "Microsoft Dynamics 365", active: false },
 { id: 4, name: "Microsoft Outlook (Plugin)", active: false },
 { id: 5, name: "Salesforce", active: false },
 { id: 6, name: "Zendesk", active: false },
 { id: 7, name: "Zoho", active: false },
 { id: 8, name: "Vtiger", active: false },
 { id: 9, name: "Freshdesk", active: false },
 { id: 10, name: "Nutshell", active: false },
 { id: 11, name: "Hubspot", active: false },
];
const form = {
 crmIntegration: false,
 crmType: [],
 modules: [],
 expertServices3cx: false,
 sms: false,
 isComplete: true,
};

const featuresCRM = [
 {
  name: "Démarrage rapide d'appel",
  description: "Lancer des appels en un clic droit depuis votre CRM.",
 },
 {
  name: "Ouverture automatique",
  description: "Gagner du temps avec l’ouverture automatique des fiches clients.",
 },
 {
  name: "Registres des appels",
  description: "Logger automatiquement les appels dans le journal des appels.",
 },
];
const featuresMS365 = [
 {
  name: "Synchronisation de statut",
  description: "Synchroniser votre statut depuis le calendrier.",
 },
 {
  name: "Synchronisation de contacts",
  description: "Synchronisez les contacts avec l’annuaire 3CX.",
 },
 {
  name: "Appels depuis Microsoft 365",
  description: "Passer des appels depuis Microsoft 365.",
 },
];
export default {
 props: ["fileId", "contentData", "bounceButton", "unsavedChangesBool", "elementRef"],
 components: {
  CheckIcon,
  ToggleWithIcon,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
 },
 data() {
  return {
   crmList,
   form,
   featuresCRM,
   featuresMS365,
   integration: false,
   expertServices3cx: false,
   modules,
   nextStep: true,
   showModules: false,
   showExpertServices3cx: false,
   stepName: "relations",
  };
 },
 methods: {
  getData() {
   let keys = Object.keys(this.contentData);

   if (keys.includes(this.stepName)) {
    this.form = this.contentData[this.stepName];

    this.crmList =
     this.form.crmType && this.form.crmType.length > 0 ? this.form.crmType : this.crmList;
    this.modules =
     this.form.modules && this.form.modules.length > 0 ? this.form.modules : this.modules;
   }
   this.savedForm = JSON.stringify(this.form);
  },
  sendForm(msg) {
   this.form.modules = this.modules;
   this.form.crmType = this.crmList;

   let stepContent = this.form;

   let content = this.contentData;

   let contentKeys = Object.keys(content);

   // if (this.formIsNotEmpty()) {
   //   content[this.stepName] = stepContent;
   // } else {
   //   if (contentKeys.includes(this.stepName)) {
   //     delete content[this.stepName];
   //   }
   // }
   content[this.stepName] = stepContent;
   content = JSON.stringify(content);

   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
     this.fileId
    }?customerAccount=${this.account}`,
    data: {
     content: `${content}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     let nextFormIndex = parseInt(this.$route.params.formIndex) + 1;
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
     if (this.nextStep) {
      let scrollTriggered = false;
      if (window.pageYOffset === 0) {
       scrollTriggered = true;
      } else {
       window.scrollTo({
        top: 0,
        behavior: "smooth",
       });
       window.onscroll = () => {
        if (!scrollTriggered && window.pageYOffset === 0) {
         scrollTriggered = true;
         window.onscroll = null;
         // Perform any additional actions here
         this.$router.push(
          `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
         );
        }
       };
      }
      if (scrollTriggered) {
       // Perform any additional actions here
       this.$router.push(
        `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
       );
      }
     }
    })
    .catch(function (error) {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  formIsNotEmpty(data) {
   let flag = false;
   if (data) {
    for (let index = 0; index < data.length; index++) {
     const element = data[index];
     if (element.active) {
      flag = true;
     }
    }
   }
   return flag;
  },
  clearFormCRM() {
   for (var i in this.crmList) {
    this.crmList[i].active = false;
   }
  },
  clearFormExpertServices3cx() {
   for (var i in this.modules) {
    this.modules[i].active = false;
   }
  },
  clearForm() {
   this.form.crmType = undefined;
   for (let index = 0; index < this.modules.length; index++) {
    const element = this.modules[index];
    element.active = 0;
   }
  },
 },
 mounted() {
  this.getData();
 },
 watch: {
  integration: function (val) {
   this.form.crmIntegration = val;
   if (val) {
    this.showModules = true;
   } else {
    this.showModules = false;
    this.clearFormCRM();
   }
  },
  expertServices3cx: function (val) {
   this.form.expertServices3cx = val;
   if (val) {
    this.showExpertServices3cx = true;
   } else {
    this.showExpertServices3cx = false;
    this.clearFormExpertServices3cx();
   }
  },

  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.customerRelationForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  form: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "customerRelationForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
 computed: {
  ...mapGetters(["account"]),
 },
};
</script>

<style></style>
