<template>
  <form @submit.prevent="formValidation()" method="POST">
   <div class="mt-2 space-y-6">
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("personalInformation") }}
       </h3>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
       <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
         <label for="first-name" class="block text-sm font-semibold text-gray-700">
          {{ $t("firstName") }}*
         </label>
         <input
          v-model="this.form.userSettings.firstName"
          type="text"
          name="first-name"
          required
          id="first-name"
          autocomplete="first-name"
          class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
         />
        </div>

        <div class="col-span-6 sm:col-span-3">
         <label for="last-name" class="block text-sm font-semibold text-gray-700">
          {{ $t("lastName") }}*
         </label>
         <input
          v-model="this.form.userSettings.lastName"
          type="text"
          name="last-name"
          required
          id="last-name"
          autocomplete="family-name"
          class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
         />
        </div>

        <div class="col-span-6 sm:col-span-4">
         <label for="email-address" class="block text-sm font-semibold text-gray-700">
          {{ $t("emailAddress") }}*
         </label>
         <div class="relative">
          <input
           v-model="this.form.userSettings.email"
           @change="checkEmailValid(this.form.userSettings.email)"
           type="email"
           required
           name="email-address"
           id="email-address"
           autocomplete="email"
           placeholder="example@domain.com"
           :class="[
            errorEmail
             ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 pr-10'
             : 'border-gray-300',
            'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
           ]"
           :aria-invalid="!emailValidator(this.form.userSettings.email)"
           aria-describedby="email-error"
          />
          <div
           class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
           v-show="errorEmail"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p v-show="errorEmail" class="ml-1 text-sm text-red-600" id="email-error">
          {{ $t("emailErrorFormat") }} johndoe@example.com
         </p>
        </div>

        <div class="col-span-6 sm:col-span-3">
         <label for="country" class="block text-sm font-medium text-gray-700">
          {{ $t("mobileNumber") }}
         </label>
         <input
          v-model="this.form.userSettings.mobile"
          type="tel"
          name="mobile"
          id="mobile"
          minlength="10"
          placeholder="06 12 34 56 78"
          class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
         />
        </div>
        <div class="grid grid-cols-6 col-span-6 gap-6">
         <div class="col-span-6 sm:col-span-3">
          <label for="first-name" class="block text-sm font-medium text-gray-700"
           >{{ $t("physicalSiteName") }}
          </label>

          <span class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm">
           <SearchSelector
            :key="$route.name"
            :selected="addressSelected"
            :searchData="companyAddress"
            @addElement="setPhysicalSite($event[0])"
           />
          </span>
          <span
           v-if="form.userSettings.physicalSite"
           class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
          >
           {{ form.userSettings.physicalSite }}
           <a
            :title="$t('delete')"
            class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
            @click="form.userSettings.physicalSite = ''"
           >
            <span class="sr-only">Remove sda number</span>
            <svg class="w-2 h-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
             <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
           </a>
          </span>
         </div>

         <div class="col-span-6 sm:col-span-3">
          <label for="last-name" class="block text-sm font-medium text-gray-700"> Service </label>
          <input
           v-model="this.form.userSettings.service"
           type="text"
           name="last-name"
           id="last-name"
           autocomplete="family-name"
           class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("globalSettings") }}
       </h3>
      </div>

      <div class="mt-5 md:mt-0 md:col-span-2">
       <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 lg:col-span-4">
         <SdaLinkedSelect
          :sdaNumberList="this.form.userSettings.sdaNumber"
          :sdaNumberListLength="this.form.userSettings.sdaNumber.length"
          :createdSda="createdSda"
          :sdaLeft="sdaLeft"
          :sdaMax="sdaMax"
          :range="range"
          :isolatedNumbers="isolatedNumbers"
          :sdaTown="sdaTown"
          :customerFileId="customerFileId"
          :fileId="fileId"
          :navIdx="navIdx"
          :errorAddedSda="errorAddedSda"
          :errorSdaFound="errorSdaFound"
          :errorIsInside="errorIsInside"
          :collectType="collectType"
          :notBundledNumbers="otherNumbers"
          @remove-sda="this.removeSda($event)"
          @remove-element="removeElement()"
          @create-new-number="this.createNewNumber($event)"
          @check-validate-sda="this.checkValidateSda($event)"
          @check-carried-number="this.checkCarriedNumber($event)"
         />
        </div>
        <div class="col-span-6 sm:col-span-3 lg:col-span-4">
         <div class="flex space-x-2">
          <!-- <ToggleWithIcon
           :validated="this.form.userSettings.outputNumber"
           @boolean-toggle="this.form.userSettings.outputNumber = $event"
          /> -->

          <label for="street-address" class="block mt-px text-sm font-medium text-gray-600">
           {{ $t("outputDisplayNumber") }}
          </label>
          <Tooltip
           tooltip2="Choisissez le numéro à présenter lors de vos appels sortants : le numéro d’accueil de votre entreprise ou tout autre numéro que vous avez demandé en portabilité."
           tooltip3="Laissez ce champs vide si vous souhaitez présenter un numéro qui sera créé par Encom."
          />
         </div>
         <p v-if="outputDisplayNumberSelected" class="block -mt-2 text-xs font-medium text-gray-600">
          Numéro sélectionné à présenter en sortie :
          <span
           class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mb-1"
          >
           {{ outputDisplayNumberSelected }}
          </span>
         </p>
         <div>
          <div class="w-3/4">
           <SearchExtensionsGroup
            :data="filteredGroupElements"
            :placeholder="$t('search')"
            :isMultiple="false"
            :closeOnSelect="true"
            @action="setOutputDisplayNumber($event)"
            :selected="outputDisplayNumberSelected"
           />
          </div>
         </div>
        </div>

        <div class="col-span-6 sm:col-span-3 lg:col-span-4">
         <div class="text-sm">
          <div>
           <label for="extension" class="font-semibold text-gray-700">
            {{ $t("internalNumber") }}*
           </label>
           <p class="text-xs text-gray-500">{{ $t("extensionMandateDetail") }}.</p>
          </div>
          <div class="relative mt-1 rounded-md shadow-sm">
           <input
            v-model="this.form.userSettings.internalNumber"
            @change="checkCreatedExtension(this.form.userSettings.internalNumber)"
            type="number"
            name="region"
            required
            id="region"
            placeholder="100"
            min="100"
            max="99999"
            aria-invalid="true"
            aria-describedby="email-error"
            :class="[
             errorCreatedExtensions ||
             errorCreatedExtensionsLength ||
             errorCreatedExtensionsForbidden
              ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 pr-10'
              : 'border-gray-300',
             'block w-full sm:text-sm rounded-md',
            ]"
           />
           <div
            v-show="
             errorCreatedExtensions ||
             errorCreatedExtensionsLength ||
             errorCreatedExtensionsForbidden
            "
            class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
           >
            <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
           </div>
          </div>
          <p
           v-show="
            errorCreatedExtensions || errorCreatedExtensionsLength || errorCreatedExtensionsForbidden
           "
           class="ml-1 text-sm text-red-600"
           id="email-error"
          >
           {{
            errorCreatedExtensions
             ? $t("extensionAlreadyUsed")
             : errorCreatedExtensionsLength
             ? $t("errorCreatedExtensionsLength")
             : $t("forbiddenExtension")
           }}
          </p>
         </div>
        </div>
       </div>
      </div>
      <div class="md:mt-0 md:col-span-2 md:col-start-2">
       <fieldset class="pb-5 space-y-4">
        <legend class="sr-only">Main Settings</legend>
        <div class="relative flex items-start">
         <div class="flex items-center h-5">
          <ToggleWithIcon
           :validated="this.form.globalSettings.voiceMail"
           @boolean-toggle="this.form.globalSettings.voiceMail = $event"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="comments" class="font-medium text-gray-700">{{ $t("voiceMailbox") }} </label>
         </div>
        </div>
        <div class="relative flex items-start">
         <div class="flex items-center h-5 mt-1">
          <ToggleWithIcon
           :validated="this.form.globalSettings.webClient"
           @boolean-toggle="this.form.globalSettings.webClient = $event"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="comments" class="font-medium text-gray-700">
           {{ $t("webClient") }}
          </label>
          <Tooltip
           tooltip1="A travers une simple page web, le client web permet à l’utilisateur d’accéder à toutes les fonctionnalités 3CX. A la maison, en déplacement ou au bureau, le client web permet de passer et de recevoir des appels, visualiser la présence des collègues, envoyer des chats et faire vos des web-meeting."
          />
         </div>
        </div>
        <div class="relative flex items-start">
         <div class="flex items-center h-5 mt-1">
          <ToggleWithIcon
           :validated="this.form.globalSettings.pushSmartphone"
           @boolean-toggle="this.form.globalSettings.pushSmartphone = $event"
          />
         </div>
         <div class="ml-3 text-sm">
          <label for="candidates" class="font-medium text-gray-700">Push smartphone </label>
          <Tooltip
           tooltip1="Le Push smartphone permet à l’utilisateur de répondre depuis un iPhone ou un Android comme si il était au bureau. Il permet de passer les appels en présentant le numéro pro."
           tooltip2="Toutes les fonctionnalités de collaboration 3CX sont disponibles sur le smartphone : Présence, Chat, Web-meeting. Les notifications Push 3CX économisent la batterie."
          />
         </div>
        </div>
        <div class="relative flex items-start">
         <div class="flex items-center h-5 mt-1">
          <ToggleWithIcon
           :validated="this.form.globalSettings.hotDesking"
           @boolean-toggle="this.form.globalSettings.hotDesking = $event"
          />
         </div>
         <div class="w-full ml-3 text-sm">
          <label for="offers" class="font-medium text-gray-700">Hotdesking </label>
          <Tooltip
           tooltip1="Licence Pro ou Entreprise nécessaire."
           tooltip2="Le hot desking permet à plusieurs utilisateurs d’utiliser les mêmes téléphones IP. Pour se connecter à un appareil en hot desking, l’utilisateur doit taper *77*[numéro d’extension]* (e.g. *77*100*)."
           tooltip3="Le serveur vocal répondra à l’appel et invitera l’utilisateur à entrer le code PIN de sa messagerie vocale. Une fois ceci effectué le serveur vocal confirmera la connexion et l’appareil sera reprovisionné."
          />
         </div>
        </div>
       </fieldset>
       <div class="w-1/2 mt-5">
        <label for="voip-phone" class="block text-sm font-medium text-gray-700">
         {{ $t("ipPhoneModel") }}
        </label>

        <Listbox
         :items="items"
         @ip-modele-phone="this.form.globalSettings.ipModelePhone = $event.item_id"
         :ipModelePhoneSelected="ipModelePhoneSelected"
        />
       </div>
      </div>
     </div>
    </div>
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
       <p class="mt-1 text-sm text-gray-500">{{ $t("notificationMandate") }}.</p>
       <p class="text-sm text-gray-500">{{ $t("notificationMandate2") }}.</p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
       <div class="space-y-2">
        <fieldset>
         <div class="mt-4 space-y-4">
          <div class="flex items-center">
           <ToggleWithIcon
            :validated="this.form.notifications.missedCalls"
            @boolean-toggle="this.form.notifications.missedCalls = $event"
           />
           <label
            v-if="this.form.notifications.missedCalls"
            for="push-everything"
            class="block ml-3 text-sm font-medium text-gray-900"
           >
            {{ $t("missedCallsBeNotified") }}
           </label>
           <label v-else for="push-nothing" class="block ml-3 text-sm font-medium text-gray-900">
            {{ $t("missedCallsNotToBeNotified") }}
           </label>
          </div>
         </div>
        </fieldset>

        <fieldset v-show="this.form.globalSettings.voiceMail">
         <div class="mt-4 space-y-4">
          <div class="flex items-center">
           <ToggleWithIcon
            :validated="this.form.notifications.voiceMail"
            @boolean-toggle="this.form.notifications.voiceMail = $event"
           />

           <label
            v-if="this.form.notifications.voiceMail"
            for="push-everything"
            class="block ml-3 text-sm font-medium text-gray-900"
           >
            {{ $t("voicemailBeNotified") }}
           </label>

           <label v-else for="push-nothing" class="block ml-3 text-sm font-medium text-gray-900">
            {{ $t("voicemailNotToBeNotified") }}
           </label>
           <Tooltip
            tooltip1="La notification des messages vocaux permet à l’utilisateur de recevoir ses messages vocaux directement dans sa boîte de réception mail. Une fois que l’appelant a laissé un message sur l’extension, celui-ci est converti en fichier audio .wav et est envoyé en pièce jointe. Le message email est envoyé accompagné de la date et heure du message, ainsi que du numéro de téléphone de la personne qui a laissé le message. L’utilisateur peut ensuite écouter le fichier wav depuis son ordinateur ou son smartphone Android ou iOS."
           />
          </div>
         </div>
        </fieldset>
       </div>
      </div>
     </div>
    </div>
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">Restrictions</h3>
       <p class="mt-1 text-sm text-gray-500">{{ $t("restrictionsDetail") }}.</p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
       <div class="space-y-6">
        <fieldset>
         <div>
          <legend class="text-base font-medium text-gray-900">
           {{ $t("restrictionsInternationals") }}
          </legend>
         </div>
         <div class="mt-4 space-y-4">
          <div class="flex items-center">
           <ToggleWithIcon
            :validated="this.form.restrictions.internationalRestrictions"
            @boolean-toggle="this.form.restrictions.internationalRestrictions = $event"
           />
           <label
            v-if="this.form.restrictions.internationalRestrictions"
            for="push-everything"
            class="block ml-3 text-sm font-medium text-gray-700"
           >
            {{ $t("restrictionsActivated") }}.
           </label>
           <label v-else for="push-nothing" class="block ml-3 text-sm font-medium text-gray-700">
            {{ $t("restrictionsDesactivated") }}
            <span class="font-medium text-gray-500"> {{ $t("mainInfos") }}. </span>
           </label>
          </div>
         </div>
        </fieldset>
        <fieldset>
         <div>
          <legend class="text-base font-medium text-gray-900">
           {{ $t("restrictionsMissCalls") }}
           <Tooltip
            tooltip1="Interdisez les appels vers certains numéros ou destinations. Par exemple, interdisez les appels sortants vers les 08 ou les numéros courts."
           />
          </legend>
         </div>
         <div class="mt-4 space-y-4">
          <div class="flex items-center">
           <ToggleWithIcon
            :validated="this.form.restrictions.callRestrictions"
            @boolean-toggle="this.form.restrictions.callRestrictions = $event"
           />
           <label
            v-if="this.form.restrictions.callRestrictions"
            for="push-everything"
            class="block ml-3 text-sm font-medium text-gray-700"
           >
            {{ $t("callsRestrictions") }}.
           </label>
           <label v-else for="push-nothing" class="block ml-3 text-sm font-medium text-gray-700">
            {{ $t("noCallsRestrictions") }}.
           </label>
          </div>
         </div>
        </fieldset>
       </div>
      </div>
     </div>
    </div>
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="flex text-lg font-medium leading-6 text-gray-900">
        {{ $t("recording") }}
       </h3>
       <div class="w-11/12 py-2 pl-2 -ml-2 rounded-md bg-yellow-50">
        <div class="flex">
         <div class="flex-shrink-0">
          <ExclamationIcon class="w-5 h-5 text-yellow-400" aria-hidden="true" />
         </div>
         <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">
           {{ $t("recordingDisclaimer") }}
           <span class="font-semibold"> "{{ $t("recordingDisclaimerSentence") }}" </span>
           {{ $t("isMandatory") }}.
          </h3>
         </div>
        </div>
       </div>
       <p class="mt-1 text-sm text-gray-500">
        Les enregistrements sont conservés 1 mois. Une durée supérieure à 1 mois fait l’objet d’une
        option payante et doit être précisée dans les commentaires ci-dessous.
       </p>
      </div>
      <div class="mt-5 space-y-6 md:mt-0 md:col-span-2">
       <div role="group" aria-labelledby="label-notifications">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
         <div class="sm:col-span-3">
          <div class="space-y-4">
           <div class="flex items-center">
            <input
             v-model="this.form.recording"
             value="non"
             id="push-desactivate"
             name="push-notifications"
             type="radio"
             class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text"
            />
            <label for="push-desactivate" class="block ml-3 text-sm font-medium text-gray-700">
             {{ $t("desactivateRecording") }}.
            </label>

            <p class="ml-1 text-sm text-gray-500">{{ $t("desactivateRecordingDescription") }}.</p>
           </div>
           <div class="flex items-center">
            <input
             v-model="this.form.recording"
             value="oui"
             id="push-activate"
             name="push-notifications"
             type="radio"
             class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text"
            />
            <label for="push-activate" class="block ml-3 text-sm font-medium text-gray-700">
             {{ $t("activateRecording") }}.
            </label>

            <p class="ml-1 text-sm text-gray-500">{{ $t("activateRecordingDescription") }}.</p>
           </div>

           <div>
            <div class="flex items-center">
             <input
              v-model="this.form.recording"
              value="manuel"
              id="push-manual"
              name="push-notifications"
              type="radio"
              class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text"
             />
             <label for="push-manual" class="block ml-3 text-sm font-medium text-gray-700">
              {{ $t("manualRecording") }}.
             </label>

             <p class="ml-1 text-sm text-gray-500">{{ $t("manualRecordingDescription") }}.</p>
             <Tooltip tooltip1="Licence Entreprise nécessaire." />
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="flex text-lg font-medium leading-6 text-gray-900">
        {{ $t("trainings") }}
       </h3>
       <p class="mt-1 text-sm text-gray-500">
        {{ $t("trainingMandateHeadline") }}
       </p>
      </div>
      <div class="mt-5 space-y-6 md:mt-0 md:col-span-2">
       <div>
        <div role="group" aria-labelledby="label-notifications">
         <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
          <div class="sm:col-span-2">
           <div class="max-w-lg">
            <fieldset class="space-y-4">
             <div class="flex items-center">
              <input
               v-model="this.form.training.user"
               id="training-user"
               name="training"
               type="checkbox"
               class="w-4 h-4 border-gray-300 rounded encom_border_color encom_primary_text"
              />
              <label for="training-user" class="block ml-3 text-sm font-medium text-gray-700">
               {{ $t("trainingUser") }}
              </label>
             </div>
             <div class="flex items-center">
              <input
               v-model="this.form.training.admin"
               id="training-admin"
               name="training"
               type="checkbox"
               class="w-4 h-4 border-gray-300 rounded encom_border_color encom_primary_text"
              />
              <label for="training-admin" class="block ml-3 text-sm font-medium text-gray-700">
               {{ $t("trainingAdmin") }}
              </label>
             </div>
            </fieldset>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
     <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
       <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ $t("comment") }}
       </h3>
      </div>
      <div class="mt-5 space-y-6 md:mt-0 md:col-span-2">
       <div>
        <div>
         <textarea
          v-model="this.form.others.comment"
          placeholder="Ajouter un commentaire..."
          rows="4"
          name="comment"
          id="comment"
          class="block w-full border-gray-300 rounded-md shadow-sm encom_border_color sm:text-sm"
         />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="flex justify-end pb-5" ref="userForm">
     <button
      type="button"
      class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none encom_ring_color"
      @click="this.$emit('closeForm', false)"
     >
      {{ $t("cancel") }}
     </button>
     <button
      type="submit"
      class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm body__button focus:outline-none encom_ring_color"
      :class="{ 'animate-bounce': bounceButton === 'userForm' && unsavedChangesBool }"
     >
      {{ $t("validate") }}
     </button>
    </div>
   </div>
  </form>
 </template>

 <script>
 import axios from "axios";

 import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";
 import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
 import SearchSelector from "../SearchSelector.vue";

 import SdaLinkedSelect from "./formTools/SdaLinkedSelect.vue";
 import Tooltip from "./formTools/Tooltip.vue";

 import Listbox from "./formTools/Listbox.vue";
 import {
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  PlusIcon,
 } from "@heroicons/vue/solid";

 export default {
  name: "CollectAddUsers",
  props: [
   "dependencies",
   "createdExtensions",
   "companyAddress",
   "fileId",
   "userChosen",
   "isEdited",
   "showForm",
   "idx",
   "navIdx",
   "sdaTown",
   "phoneData",
   "customerFileId",
   "contentData",
   "createdSda",
   "bounceButton",
   "unsavedChangesBool",
   "elementRef",
   "collectType",
  ],
  components: {
   ExclamationCircleIcon,
   ExclamationIcon,
   InformationCircleIcon,
   SearchExtensionsGroup,
   ToggleWithIcon,
   PlusIcon,
   Listbox,
   SdaLinkedSelect,
   SearchSelector,
   Tooltip,
  },

  data() {
   return {
    addressSelected: "",
    errorCreatedExtensions: false,
    errorCreatedExtensionsLength: false,
    errorCreatedExtensionsForbidden: false,
    errorAddedSda: false,
    errorSdaFound: false,
    errorIsNum: false,
    errorIsInside: false,
    errorEmail: false,
    frenchPhoneNumber:
     "^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$",
    form: {
     userSettings: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      physicalSite: "",
      service: "",
      outputDisplayNumber: "",
      internalNumber: null,
      sdaNumber: [],
     },
     globalSettings: {
      voiceMail: true,
      webClient: true,
      pushSmartphone: true,
      hotDesking: false,
      ipModelePhone: "",
     },
     notifications: {
      missedCalls: false,
      voiceMail: true,
     },
     restrictions: {
      internationalRestrictions: false,
      callRestrictions: false,
     },
     recording: "non",
     training: { user: "", admin: "" },
     others: {
      comment: "",
     },
     isComplete: false,
    },
    items: [{ item_id: 0, name: "Aucun", quantity: 1 }],
    ipModelePhoneSelected: "",
    outputDisplayNumberSelected: "",
    otherNumbers: [],
    range: [],
    isolatedNumbers: [],
    savedForm: "",
    selected: "",
    sdaNumber: null,
    sdaNumberList: [],
    sdaLeft: null,
    sdaMax: null,
    showMore: false,
    isMounted: false,
   };
  },

  methods: {
    getStoreVar() {
    if (this.contentData["numbers"] && this.contentData["numbers"].sdaUsed) {
     this.sdaLeft = this.contentData["numbers"].sdaUsed.sdaLeft;
     this.sdaMax = this.contentData["numbers"].sdaUsed.sdaMax;
     this.range = this.contentData["numbers"].number.range.map((a) => {
      return { ...a };
     });
     if (this.contentData["numbers"].number && this.contentData["numbers"].number?.other) {
      this.otherNumbers = this.contentData["numbers"].number?.other.map((obj) => obj.number);
     }
     if (this.contentData["numbers"].number && this.contentData["numbers"].number.other) {
      this.otherNumbers = this.contentData["numbers"].number.other.map((obj) => obj.number);
     }

     this.contentData["numbers"].number.rio.forEach((el) => {
      this.isolatedNumbers.push(el.number);
     });
    }

    let sda = this.form.userSettings.sdaNumber;
    sda.forEach((el, index) => {
     if (/^\d+$/.test(el)) {
      if (sda[index] === 9) {
       sda[index] = "0" + el;
      } else if (sda[index] > 10) {
       sda[index] = "0" + String(el).slice(-9);
      }
     }
    });
   },
   async getPhoneData() {
    let keys = Object.keys(this.phoneData);

    for (let index = 0; index < keys.length; index++) {
     const element = keys[index];
     const newObj = { item_id: "", name: "", quantity: null };
     newObj.item_id = this.phoneData[element].item_id;
     newObj.quantity = parseInt(this.phoneData[element].quantity);
     newObj.name = await this.getItems(this.phoneData[element].item_id);
     this.items.push(newObj);

     //Look into the array and get object back
     /* if (
           this.items[index].item_id ===
           this.userChosen.globalSettings.ipModelePhone
         ) {
           this.ipModelePhoneSelected = this.items[index];
         } */
     this.isEdited && this.items[index].item_id === this.userChosen.globalSettings.ipModelePhone
      ? (this.ipModelePhoneSelected = this.items[index])
      : "";
    }
   },
   async getItems(item_id) {
    try {
     const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/items/${item_id}`, {
      params: {
       customerAccount: this.account,
      },
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     });

     return res.data.description;
    } catch (error) {
     console.error(error);
     this.errorHandling(error);
    }
   },
   formValidation() {
    if (
     this.isEdited &&
     !this.errorCreatedExtensions &&
     !this.errorCreatedExtensionsForbidden &&
     !this.errorCreatedExtensionsLength &&
     !this.errorEmail
    ) {
     this.emitEditUser();
    } else if (
     !this.errorCreatedExtensions &&
     !this.errorCreatedExtensionsForbidden &&
     !this.errorCreatedExtensionsLength &&
     !this.errorEmail
    ) {
     this.emitUser();
    }
   },
   emitEditUser() {
    this.$emit("editUser", this.form, this.sdaLeft, this.$t("informationSaved"));
   },
   emitUser() {
    this.$emit("users", this.form, this.sdaLeft, this.$t("informationSaved"));
   },
   clearForm() {
    this.form.address.name = "";
    this.form.address.street = "";
    this.form.address.city = "";
    this.form.address.state = "";
    this.form.address.postal = "";
   },
   parseBooleanValues(val) {
    if (val === true || val === 'true') {
      return "oui";
    }
    if (val === false || val === 'false') {
      return "non";
    }
    return val;
   },
   editSelectedUser() {
    if (this.isEdited) {
      //userSettings
      this.form.userSettings.firstName = this.userChosen.userSettings.firstName;
      this.form.userSettings.lastName = this.userChosen.userSettings.lastName;
      this.form.userSettings.email = this.userChosen.userSettings.email;
      this.form.userSettings.mobile = this.userChosen.userSettings.mobile;
      this.form.userSettings.physicalSite = this.userChosen.userSettings.physicalSite;
      this.addressSelected = this.userChosen.userSettings.physicalSite;
      this.form.userSettings.service = this.userChosen.userSettings.service;
      // this.form.userSettings.outputNumber = this.userChosen.userSettings.outputNumber;
      this.form.userSettings.outputDisplayNumber = this.userChosen.userSettings.outputDisplayNumber;
      this.outputDisplayNumberSelected = this.userChosen.userSettings.outputDisplayNumber;
      this.form.userSettings.internalNumber = this.userChosen.userSettings.internalNumber;
      this.form.userSettings.sdaNumber = [...this.userChosen.userSettings.sdaNumber];

      //globalSettings
      this.form.globalSettings.voiceMail = this.userChosen.globalSettings.voiceMail;
      this.form.globalSettings.webClient = this.userChosen.globalSettings.webClient;
      this.form.globalSettings.pushSmartphone = this.userChosen.globalSettings.pushSmartphone;
      this.form.globalSettings.hotDesking = this.userChosen.globalSettings.hotDesking;
      this.form.globalSettings.ipModelePhone = this.userChosen.globalSettings.ipModelePhone;

      //notifications
      this.form.notifications.missedCalls = this.userChosen.notifications.missedCalls;
      this.form.notifications.voiceMail = this.userChosen.notifications.voiceMail;

      //restrictions
      this.form.restrictions.internationalRestrictions = this.userChosen.restrictions.internationalRestrictions;
      this.form.restrictions.callRestrictions = this.userChosen.restrictions.callRestrictions;

      //recording
      this.form.recording = this.parseBooleanValues(this.userChosen.recording)

      //training
      this.form.training = this.userChosen.training;

      //others
      this.form.others.comment = this.userChosen.others.comment;
    }
    this.savedForm = JSON.stringify(this.form);
   },
   setPhysicalSite(event) {
    this.addressSelected = event;
    this.form.userSettings.physicalSite = event;
   },
   setOutputDisplayNumber(event) {
    this.outputDisplayNumberSelected = event;
    this.form.userSettings.outputDisplayNumber = event;
   },
   checkEmailValid(email) {
    email && !this.emailValidator(email) ? (this.errorEmail = true) : (this.errorEmail = false);
   },

   checkCreatedExtension(extension) {
    const extensionLength = extension.toString().length;

    const isForbidden = [12, 15, 17, 18, 112, 999].includes(extension);
    const isExisting = this.createdExtensions.some((createdExtension, index) => {
     return parseInt(createdExtension) === parseInt(extension) && index !== this.idx;
    });
    const isNotGoodLength = this.createdExtensions.some((createdExtension, index) => {
     return createdExtension.toString().length !== extensionLength && index !== this.idx;
    });
    this.errorCreatedExtensionsForbidden = isForbidden;
    this.errorCreatedExtensionsLength = isNotGoodLength;
    this.errorCreatedExtensions = isExisting;
   },

   checkValidateSda(value) {
    this.errorAddedSda = false;
    this.errorSdaFound = false;

    const validLength = 10;
    let absoluteValue = typeof value === "string" ? value.replace(/\s+/g, "") : "";
    const isNum = /^\d+$/.test(absoluteValue);

    if (absoluteValue.length !== validLength || !isNum) {
     this.errorAddedSda = true;
     return;
    }

    // Check existence in both createdSda and userSettings.sdaNumber
    const isExisted = this.createdSda.includes(absoluteValue);
    const isNew = this.form.userSettings.sdaNumber.includes(absoluteValue);

    if (isExisted) {
     this.errorSdaFound = true;
     return;
    }

    this.setOutputDisplayNumber(this.form.userSettings.sdaNumber[0]);
    this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = parseInt(this.sdaLeft) - 1);
    this.sdaNumber = null;
   },
   checkCarriedNumber(number) {
    this.errorAddedSda = false;
    this.errorIsInside = false;

    const isnum = /^\d+$/.test(number);
    if (isnum) {
     this.checkValidateSda(number);
     return true;
     /*   for (let index = 0; index < this.range.length; index++) {
      const range = this.range[index];
      if (number >= range.from && number <= range.to) {
       this.checkValidateSda(number);
       return true;
      }
     } */
     //this.errorIsInside = true;
    } else {
     this.errorAddedSda = true;
    }
   },
   removeSda(index) {
    this.$emit("removeSdaTown", this.form.userSettings.sdaNumber[index], () => {
     this.form.userSettings.sdaNumber.splice(index, 1);
    });
    this.sdaLeft >= this.sdaMax
     ? (this.sdaLeft = this.sdaMax)
     : (this.sdaLeft = parseInt(this.sdaLeft) + 1);
   },

   removeElement() {
    this.sdaLeft >= this.sdaMax
     ? (this.sdaLeft = this.sdaMax)
     : (this.sdaLeft = parseInt(this.sdaLeft) + 1);
   },
   createNewNumber(town) {
    this.form.userSettings.sdaNumber.push(town);
    this.$emit("updateSdaTown", town);
    this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = this.sdaLeft - 1);
   },
   getRandomInt(max) {
    return Math.floor(Math.random() * max);
   },
  },
  mounted() {
   this.$nextTick(() => {
    this.isMounted = true;
   });
   this.editSelectedUser();
   this.getPhoneData();
   this.getStoreVar();
  },
  computed: {
   filteredGroupElements() {
    let data = [];
    let arr = [];
    if (this.range && this.range.length > 0) {
     for (let index = 0; index < this.range.length; index++) {
      const element = this.range[index];
      arr = Array.from(
       { length: parseInt(element.to) - parseInt(element.from) + 1 },
       (_, i) => "0" + (parseInt(element.from) + i)
      );
      data = [...data, ...arr];
     }
    }
    if (this.isolatedNumbers && this.isolatedNumbers.length > 0) {
     data = [...data, ...this.isolatedNumbers];
    }
    if (this.otherNumbers && this.otherNumbers.length > 0) {
     data = [...data, ...this.otherNumbers];
    }
    if (this.sdaTown.length > 0) data = [...data, ...this.sdaTown];
    return data;
   },
  },
  watch: {
   /* selected: {
    handler: function() {
     this.sdaNumber = "";
    },
   }, */
   "form.userSettings.outputNumber": {
    handler: function (bool) {
     bool && this.form.userSettings.sdaNumber.length > 0
      ? this.setOutputDisplayNumber(this.form.userSettings.sdaNumber[0])
      : this.setOutputDisplayNumber("");
    },

    deep: true,
   },

   "form.userSettings.sdaNumber": {
    handler: function (arr) {
     if (!this.isMounted) return;
     if (arr.length > 0) this.setOutputDisplayNumber(arr[0]);
    },
    deep: true,
   },
   elementRef: function (ref) {
    if (ref) {
     const el = this.$refs.userForm;
     el.scrollIntoView({ behavior: "smooth", block: "end" });
    }
   },
   form: {
    handler: function (object) {
     this.savedForm !== JSON.stringify(object)
      ? this.$emit("unsavedChanges", true, "userForm")
      : this.$emit("unsavedChanges", false, "");
    },
    deep: true,
   },
  },
 };
 </script>