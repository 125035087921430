<template>
 <div class="flex flex-col">
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
     <table class="min-w-full">
      <thead class="bg-white">
       <tr>
        <th
         scope="col"
         class="capitalize py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
        >
         {{ $t("name") }}
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
         {{ $t("uploadedBy") }}
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
         {{ $t("uploadedAt") }}
        </th>

        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
         <span class="sr-only">Edit</span>
        </th>
       </tr>
      </thead>
      <tbody class="bg-white">
       <template v-for="document in documents" :key="document.name">
        <tr class="border-t border-gray-200">
         <th
          colspan="5"
          scope="colgroup"
          class="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
         >
          {{ document.name }}
         </th>
        </tr>
        <tr
         v-for="(document, documentIdx) in document.people"
         :key="documentIdx"
         :class="[documentIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t']"
        >
         <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {{ document.fileDescription }}
         </td>
         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ document.fileDescription.split("_")[0] === "mandate" ? "Mandat" : $t("invoice") }}
         </td>
         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ document.user.email }}
         </td>
         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {{ toLocaleTimeString(document.created_at) }}
         </td>

         <td
          class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
         >
          <a
           @click="getFile(document.id, document.mimeType)"
           class="text-main-color text-main-color--hover cursor-pointer"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
           </svg>
          </a>
         </td>
        </tr>
       </template>
      </tbody>
     </table>
    </div>
   </div>
  </div>
 </div>
 <loading
  v-model:active="isLoading"
  :can-cancel="false"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
const account = localStorage.getItem("account");

export default {
 name: "MandateTable",
 props: ["documents"],
 components: {
  Loading,
 },
 data() {
  return {
   account,
   fullPage: true,
   isLoading: false,
  };
 },

 methods: {
  async getFile(id, mimeType) {
   try {
    this.isLoading = true;
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/documents/${id}/content`, {
     params: {
      customerAccount: this.account,
     },
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     responseType: "arraybuffer",
    });
    const file = new Blob([await res.data], { type: mimeType });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
   } catch (error) {
    console.error(error);
    this.errorHandling(error);
    /* this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", noFileMsg); */
   } finally {
    this.isLoading = false;
   }
  },
 },
 mounted() {},
 watch: {},
};
</script>
