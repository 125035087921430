<template>
 <form @submit.prevent="sendForm($t('informationSaved'), true)" method="POST">
  <div class="px-4 py-5 mx-5 mb-5 space-y-6 bg-white shadow sm:rounded-lg">
   <div class="border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("globalSettingsDetail") }}
      </h3>
     </div>

     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="company-name" class="block text-sm font-semibold text-gray-700">
         {{ $t("company") }}*
        </label>

        <input
         v-model="this.formFinal.globalSettings.company"
         required
         type="text"
         name="company-name"
         id="company-name"
         autocomplete="company-name"
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
        />
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="3cx-domain" class="text-sm font-semibold text-gray-700">
         {{ $t("3cxDomainType") }}* (FQDN)
        </label>
        <Tooltip
         :tooltip2="
          `Sélectionnez par défaut 3CX. Votre URL de connexion sera du type ` +
          `https://` +
          this.formFinal.globalSettings.domain3cxRoot +
          '.' +
          this.formFinal.globalSettings.domain3cx
         "
        />

        <select
         v-model="this.formFinal.globalSettings.fqdn"
         required
         id="3cx-domain"
         name="3cx-domain"
         class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        >
         <option disabled="disabled">Sélectionnez</option>
         <option value="3cx">3CX</option>
         <option value="perso">Perso</option>
        </select>
       </div>

       <div v-show="this.formFinal.globalSettings.fqdn === '3cx'" class="col-span-6 sm:col-span-3">
        <label
         for="3cx-domain-root"
         class="relative flex block text-sm font-semibold text-gray-700"
        >
         {{ $t("3cxDomainRoot") }}*
        </label>
        <input
         v-model="this.formFinal.globalSettings.domain3cxRoot"
         required
         type="text"
         name="3cx-domain-root"
         id="3cx-domain-root"
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
        />
       </div>

       <div
        v-show="this.formFinal.globalSettings.fqdn === '3cx' && domain3cx.length > 0"
        class="col-span-6 sm:col-span-3"
       >
        <Listbox as="div" v-model="selectedDomain3cx">
         <ListboxLabel class="block text-sm font-semibold text-gray-700">
          {{ $t("3cxDomain") }}*
         </ListboxLabel>
         <div class="relative mt-1">
          <ListboxButton
           class="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 sm:text-sm"
          >
           <span class="block truncate">{{ selectedDomain3cx.domain }}</span>
           <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
           </span>
          </ListboxButton>

          <transition
           leave-active-class="transition duration-100 ease-in"
           leave-from-class="opacity-100"
           leave-to-class="opacity-0"
          >
           <ListboxOptions
            class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
           >
            <ListboxOption
             as="template"
             v-for="domain in domain3cx"
             :key="domain.code"
             :value="domain"
             v-slot="{ active, selected }"
            >
             <li
              :class="[
               active ? 'text-white encom_primary' : 'text-gray-900',
               'cursor-default select-none relative py-2 pl-3 pr-9 mx-2 rounded',
              ]"
             >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
               <i>subdomain</i>.{{ domain.domain }}
              </span>

              <span
               v-if="selected"
               :class="[
                active ? 'text-white' : 'encom_ouvert_ticket_text',
                'absolute inset-y-0 right-0 flex items-center pr-4',
               ]"
              >
               <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
             </li>
            </ListboxOption>
           </ListboxOptions>
          </transition>
         </div>
        </Listbox>
       </div>
       <div v-show="this.formFinal.globalSettings.fqdn === 'perso'" class="col-span-6">
        <label for="country" class="block text-sm font-semibold text-gray-700">
         {{ $t("yourDomain") }}*
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
         <input
          v-model="this.formFinal.globalSettings.customDomain"
          type="text"
          name="customDomain"
          id="customDomain"
          :class="[
           errorOnFinalFormFqdn && !this.formFinal.globalSettings.customDomain
            ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300',
           'block w-full border sm:text-sm rounded-md py-2 px-3',
          ]"
          placeholder="my-3cx.domain.com"
          :aria-invalid="errorOnFinalFormFqdn"
          aria-describedby="email-error"
         />
         <div
          v-if="errorOnFinalFormFqdn && !this.formFinal.globalSettings.customDomain"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="errorOnFinalFormFqdn && !this.formFinal.globalSettings.customDomain"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ $t("errorOnFinalFormFqdn") }}.
        </p>
       </div>
       <div class="col-span-6">
        <label for="country" class="text-sm font-semibold text-gray-700">
         {{ $t("opco") }}
        </label>
        <Tooltip :tooltip1="$t('tooltipOPCO')" />

        <select
         v-model="this.formFinal.globalSettings.opco"
         name="general_opco"
         class="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
        >
         <option disabled="disabled">Sélectionnez</option>
         <option value="none" selected="selected">Aucun OPCO</option>
         <option value="3719">Opco AFDAS</option>
         <option value="3720">Opco ATLAS</option>
         <option value="3721">Opco OCAPIAT</option>
         <option value="3722">Opco Cohésion sociale</option>
         <option value="3723">Opco de la construction</option>
         <option value="3724">Opcommerce</option>
         <option value="3725">Opco Akto</option>
         <option value="3726">Opco 2i</option>
         <option value="3727">Opco Mobilités</option>
         <option value="3729">Opco Santé</option>
         <option value="3728">Opco des entreprises de proximité</option>
        </select>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="border sm:rounded-lg sm:p-6" ref="addressForm">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("address") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">{{ $t("addressDetail") }}.</p>
     </div>

     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <div class="mb-5">
       <ul role="list" class="grid grid-cols-1 gap-5 mt-3 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <li
         v-for="(address, index) in this.formFinal.address"
         :key="index"
         class="flex col-span-1 rounded-md shadow-sm"
        >
         <div
          class="flex items-center justify-between flex-1 bg-white border border-gray-200 rounded-md bg-gray-50"
         >
          <div class="flex-1 py-2 pl-4 pr-2 text-sm">
           <a
            @click="this.editNewAddress(address, index)"
            class="font-medium text-gray-900 cursor-pointer hover:text-gray-600"
           >
            {{ address.name }}
           </a>
           <p class="text-xs text-gray-500">
            {{ address.street }}, {{ address.postal }}.
            {{ address.city }}
           </p>
          </div>
          <div class="flex-shrink-0 pr-2">
           <button
            @click="this.deleteNewAddress(index)"
            type="button"
            class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
           >
            <span class="sr-only">Delete</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </li>
       </ul>
      </div>

      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="site-name" class="block text-sm font-semibold text-gray-700">
         {{ $t("companyName") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.address.name"
          type="text"
          name="site-name"
          id="site-name"
          autocomplete="site-name"
          :class="[
           addressErrors.lists.includes('name')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
         <div
          v-show="addressErrors.lists.includes('name')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-show="addressErrors.lists.includes('name')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ $t("companyName") }} {{ $t("cantBeEmpty") }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="site-name" class="block text-sm font-semibold text-gray-700">
         {{ $t("street") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.address.street"
          type="text"
          name="street-address"
          id="street-address"
          autocomplete="street-address"
          :class="[
           addressErrors.lists.includes('street')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
         <div
          v-show="addressErrors.lists.includes('street')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-show="addressErrors.lists.includes('street')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ $t("street") }} {{ $t("cantBeEmpty") }}
        </p>
       </div>
       <div class="col-span-6 sm:col-span-3 lg:col-span-3">
        <label for="postal-code" class="block text-sm font-semibold text-gray-700">
         {{ $t("postal") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.address.postal"
          type="text"
          name="postal-code"
          id="postal-code"
          autocomplete="postal-code"
          :class="[
           addressErrors.lists.includes('postal')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
         <div
          v-show="addressErrors.lists.includes('postal')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-show="addressErrors.lists.includes('postal')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ $t("postal") }} {{ $t("cantBeEmpty") }}
        </p>
       </div>
       <div class="col-span-6 sm:col-span-6 lg:col-span-3">
        <label for="city" class="block text-sm font-semibold text-gray-700"
         >{{ $t("city") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.address.city"
          type="text"
          name="city"
          id="city"
          :class="[
           addressErrors.lists.includes('city')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
            : '',
           'mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
          ]"
         />
         <div
          v-show="addressErrors.lists.includes('city')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-show="addressErrors.lists.includes('city')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ $t("city") }} {{ $t("cantBeEmpty") }}
        </p>
       </div>
       <div class="col-span-6 sm:col-span-3">
        <label for="country" class="block text-sm font-medium text-gray-700">
         {{ $t("country") }}
        </label>
        <input
         v-model="this.form.address.country"
         type="text"
         name="country"
         id="country"
         autocomplete="country"
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
        />
       </div>
       <div
        v-show="this.form.address.country !== 'France'"
        class="col-span-6 sm:col-span-3 lg:col-span-2"
       >
        <label for="state" class="block text-sm font-medium text-gray-700">{{ $t("state") }}</label>
        <input
         v-model="this.form.address.state"
         type="text"
         name="state"
         id="state"
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
        />
       </div>

       <div class="flex justify-end col-span-6 px-4">
        <button
         v-if="this.isEdited"
         @click="this.clearForm()"
         class="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
        >
         {{ $t("cancel") }}
        </button>
        <button
         v-if="this.isEdited"
         @click="editAddress()"
         type="button"
         class="px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm encom_primary encom_primary_hover hover:bg-gray-50 focus:outline-none"
        >
         {{ $t("save") }}
        </button>

        <button
         v-else
         @click="addAddress()"
         type="button"
         class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm encom_primary encom_primary_hover encom_ring_color"
         :class="{ 'animate-bounce': bounceButton === 'addressForm' && unsavedChangesBool }"
        >
         {{ $t("add") }}
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="border sm:rounded-lg sm:p-6" ref="contactForm">
    <div class="md:grid md:grid-cols-3 md:gap-4">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Contacts</h3>
      <p class="mt-1 text-sm text-gray-600">{{ $t("contactDetail") }}:</p>
      <ul class="text-sm text-gray-500 list-disc">
       <li class="ml-3">{{ $t("contactDetailList1") }}.</li>
       <li class="ml-3">{{ $t("contactDetailList2") }}.</li>
      </ul>
     </div>

     <div class="mb-5 md:col-span-2 md:col-start-2">
      <ul role="list" class="grid grid-cols-1 gap-2 mt-3 sm:gap-4 sm:grid-cols-2 lg:grid-cols-3">
       <li
        v-for="(contact, index) in this.formFinal.contacts"
        :key="contact.email"
        class="flex col-span-1 rounded-md shadow-sm"
       >
        <div
         class="flex items-center justify-between flex-1 truncate bg-white border border-gray-200 rounded-md bg-gray-50"
        >
         <div class="flex-1 px-4 py-2 text-sm truncate">
          <a
           @click="this.editNewContact(contact, index)"
           class="font-medium text-gray-900 cursor-pointer hover:text-gray-600"
           >{{ contact.firstName }} {{ contact.lastName }}
          </a>
          <p class="space-x-2 text-gray-500 truncate">
           <span
            v-if="contact.is_signer_contract"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("isSignerContract") }}
           </span>
           <span
            v-if="contact.is_signer_iban"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("isSignerIban") }}
           </span>
           <span
            v-if="contact.is_collect_contact"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-gray-800"
           >
            {{ $t("collectContact") }}
           </span>
           <span
            v-if="contact.legalRepresentative"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("legalRepresentative") }}
           </span>
           <span
            v-if="contact.technicalContact"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("technical") }}
           </span>
           <span
            v-if="contact.accountantContact"
            class="sm:truncate inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800"
           >
            {{ $t("accounting") }}
           </span>
          </p>
         </div>

         <div
          v-show="
           !contact.is_signer_contract && !contact.is_signer_iban && !contact.is_collect_contact
          "
          class="flex-shrink-0 pr-2"
         >
          <button
           @click="this.deleteNewContact(index)"
           type="button"
           class="inline-flex items-center justify-center w-8 h-8 text-red-400 bg-transparent rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
           <span class="sr-only">Delete Contact</span>
           <TrashIcon class="w-5 h-5" aria-hidden="true" />
          </button>
         </div>
        </div>
       </li>
      </ul>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-3">
        <label for="firstName" class="block text-sm font-semibold text-gray-700">
         {{ $t("firstName") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.contacts.firstName"
          @change="checkFormElementValid(this.form.contacts.firstName, 'firstName')"
          type="text"
          name="signatory-firstName"
          id="signatory-firstName"
          :class="[
           this.returnContactError('firstName')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
            : ' border-gray-300 ',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
         />
         <div
          v-show="this.returnContactError('firstName')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.returnContactError('firstName')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ this.returnContactError("firstName").error }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="lastName" class="block text-sm font-semibold text-gray-700">
         {{ $t("lastName") }}*
        </label>
        <div class="relative">
         <input
          v-model="this.form.contacts.lastName"
          @change="checkFormElementValid(this.form.contacts.lastName, 'lastName')"
          type="text"
          name="signatory-firstName"
          id="signatory-firstName"
          :class="[
           this.returnContactError('lastName')
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
            : ' border-gray-300 ',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
         />
         <div
          v-show="this.returnContactError('lastName')"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.returnContactError('lastName')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{ this.returnContactError("lastName").error }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-4">
        <label for="email" class="block text-sm font-semibold text-gray-700"> E-mail* </label>
        <div class="relative">
         <input
          v-model="this.form.contacts.email"
          @change="checkEmailValid(this.form.contacts.email)"
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          :class="[
           errorEmail || this.returnContactError('email')
            ? 'placeholder-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 pr-10'
            : 'border-gray-300',
           'mt-1 block w-full shadow-sm sm:text-sm rounded-md',
          ]"
          :aria-invalid="!emailValidator(this.form.contacts.email)"
          aria-describedby="email-error"
         />
         <div
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
          v-show="errorEmail || this.returnContactError('email')"
         >
          <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="errorEmail || this.returnContactError('email')"
         class="ml-1 text-sm text-red-600"
         id="email-error"
        >
         {{
          errorEmail
           ? $t("emailErrorFormat") + " johndoe@example.com"
           : this.returnContactError("email").error
         }}
        </p>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="phone-home" class="block text-sm font-medium text-gray-700">
         {{ $t("phoneNumber") }}
        </label>
        <div class="relative mt-1 rounded-md">
         <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center">
           <label for="country" class="sr-only">Country</label>
           <select
            id="country"
            name="country"
            class="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md pr-7 sm:text-sm"
           >
            <option>FR</option>
           </select>
          </div>

          <input
           v-model="this.form.contacts.phone"
           @change="checkFormElementValid(this.form.contacts.phone, 'phone')"
           type="tel"
           name="phone-home"
           id="phone-home"
           :class="[
            this.returnContactError('phone')
             ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',
            'block w-full pl-20 sm:text-sm rounded-md shadow-sm',
           ]"
          />
          <div
           v-show="this.returnContactError('phone')"
           class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="this.returnContactError('phone')"
          class="ml-1 text-sm text-red-600"
          id="email-error"
         >
          {{ this.returnContactError("phone").error }}
         </p>
        </div>
       </div>

       <div class="col-span-6 sm:col-span-3">
        <label for="phone-home" class="block text-sm font-medium text-gray-700">
         {{ $t("mobileNumber") }}
        </label>
        <div class="relative mt-1 rounded-md">
         <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center">
           <label for="country" class="sr-only">Country</label>
           <select
            id="country"
            name="country"
            class="h-full py-0 pl-3 text-gray-500 bg-transparent border-transparent rounded-md pr-7 sm:text-sm"
           >
            <option>FR</option>
           </select>
          </div>

          <input
           v-model="this.form.contacts.mobile"
           @change="checkFormElementValid(this.form.contacts.mobile, 'mobile')"
           type="tel"
           name="phone-home"
           id="phone-home"
           :class="[
            this.returnContactError('mobile')
             ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
             : 'border-gray-300',
            'block w-full pl-20 sm:text-sm rounded-md shadow-sm',
           ]"
          />
          <div
           v-show="this.returnContactError('mobile')"
           class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
          >
           <ExclamationCircleIcon class="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="this.returnContactError('mobile')"
          class="ml-1 text-sm text-red-600"
          id="email-error"
         >
          {{ this.returnContactError("mobile").error }}
         </p>
        </div>
       </div>

       <div class="col-span-6">
        <div class="relative flex items-start space-x-6">
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.contacts.legalRepresentative"
            @change="
             checkFormElementValid(this.form.contacts.legalRepresentative, 'legalRepresentative')
            "
            id="legalRepresentative"
            aria-describedby="legalRepresentative-description"
            name="legalRepresentative"
            type="checkbox"
            class="w-4 h-4 border-gray-300 rounded encom_ouvert_ticket_text"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="legalRepresentative" class="font-medium text-gray-700"
            >{{ $t("legalRepresentative") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.contacts.technicalContact"
            @change="checkFormElementValid(this.form.contacts.technicalContact, 'technicalContact')"
            id="technicalContact"
            aria-describedby="technicalContact-description"
            name="technicalContact"
            type="checkbox"
            class="w-4 h-4 border-gray-300 rounded encom_ouvert_ticket_text"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="technicalContact" class="font-medium text-gray-700">
            {{ $t("technicalContact") }}
           </label>
          </div>
         </div>
         <div class="flex items-center h-5">
          <div>
           <input
            v-model="this.form.contacts.accountantContact"
            @change="
             checkFormElementValid(this.form.contacts.accountantContact, 'accountantContact')
            "
            id="accountantContact"
            aria-describedby="accountantContact-description"
            name="accountantContact"
            type="checkbox"
            class="w-4 h-4 border-gray-300 rounded encom_ouvert_ticket_text"
           />
          </div>
          <div class="mt-1 ml-3 text-sm">
           <label for="accountantContact" class="font-medium text-gray-700"
            >{{ $t("accountingContact") }}
           </label>
          </div>
         </div>
        </div>
        <p
         v-if="this.returnContactError('contact')"
         class="flex mt-2 text-sm text-red-600"
         id="email-error"
        >
         <ExclamationCircleIcon class="w-5 h-5 mr-2 text-red-500" aria-hidden="true" />
         {{ this.returnContactError("contact").error }}
        </p>
       </div>

       <div class="relative flex items-start col-span-6">
        <div class="flex items-center h-5">
         <ToggleWithIcon
          :validated="this.form.contacts.invoice"
          @boolean-toggle="this.form.contacts.invoice = $event"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="offers" class="font-medium text-gray-700">{{ $t("invoicesDetail") }}. </label>
        </div>
       </div>
       <div class="relative flex items-start col-span-6">
        <div class="flex items-center h-5">
         <ToggleWithIcon
          :validated="this.form.contacts.admin"
          @boolean-toggle="this.form.contacts.admin = $event"
         />
        </div>
        <div class="ml-3 text-sm">
         <label for="admin" class="font-medium text-gray-700">
          {{ $t("contactAdminDetail") }}.
         </label>
         <Tooltip
          tooltip1="L’administrateur gère la liste de vos salariés autorisés à demander des modifications techniques ou administratives pour le compte de votre société."
         />
        </div>
       </div>
      </div>

      <div class="flex justify-end px-4">
       <button
        v-if="this.isContactEdited"
        @click="this.clearContactForm()"
        class="px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
       >
        {{ $t("cancel") }}
       </button>
       <button
        v-if="this.isContactEdited"
        @click="editContact()"
        type="button"
        class="px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm encom_primary encom_primary_hover hover:bg-gray-50 focus:outline-none"
        :class="{ 'animate-bounce': bounceButton === 'contactForm' && unsavedChangesBool }"
       >
        {{ $t("save") }}
       </button>

       <button
        v-else
        @click="addContact()"
        type="button"
        class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm encom_primary encom_primary_hover encom_ring_color"
        :class="{ 'animate-bounce': bounceButton === 'contactForm' && unsavedChangesBool }"
       >
        {{ $t("add") }}
       </button>
      </div>
     </div>
    </div>
   </div>

   <div class="border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-4">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("internationalCall") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">{{ $t("internationalCallDetail") }}</p>
      <p class="mt-2 text-sm text-gray-500">{{ $t("internationalCallDetail2") }}</p>
      <p class="mt-2 text-sm text-gray-500">{{ $t("internationalCallDetail3") }}</p>
      <p class="mt-2 text-sm text-gray-500">{{ $t("internationalCallDetail4") }}</p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="space-y-6">
       <fieldset>
        <div ref="internationalCall">
         <legend class="text-base font-medium text-gray-900">
          {{ $t("internationals") }}
         </legend>
        </div>
        <div class="mt-4 space-y-4">
         <div class="flex items-center">
          <ToggleWithIcon
           :validated="this.formFinal.restrictions.internationalCall"
           @boolean-toggle="this.formFinal.restrictions.internationalCall = $event"
          />
         </div>
        </div>

        <div v-show="this.formFinal.restrictions.internationalCall">
         <OpenDestinationList
          @internationalForm="this.formFinal.restrictions.lists = $event"
          :data="this.formFinal.restrictions"
          :contentData="contentData"
         />
        </div>
       </fieldset>
      </div>
     </div>
     <div class="mb-5 md:col-span-2 md:col-start-2"></div>
     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2"></div>
    </div>
   </div>
   <div class="flex justify-end px-4 pb-5">
    <div
     v-show="errorOnFinalFormAddress || errorOnFinalFormContact"
     class="w-3/5 p-4 rounded-md bg-red-50"
    >
     <div class="flex">
      <div class="flex-shrink-0">
       <XCircleIcon class="w-5 h-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
       <p class="text-sm font-semibold text-red-800">
        {{ $t("thereIs") }} {{ $t("oneOrMore") }} {{ $t("errorWithYourSubmission") }}.
        <span class="font-normal">
         {{ errorOnFinalFormContact ? $t("needAtLeastOneContact") : $t("needAtLeastOneAddress") }}
        </span>
       </p>
      </div>
     </div>
    </div>
    <div class="flex justify-end" ref="mainForm">
     <button
      type="submit"
      @click="nextStep = false"
      class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm body__header--button"
      :class="{ 'animate-bounce': bounceButton === 'mainForm' && unsavedChangesBool }"
     >
      {{ $t("save") }}
     </button>
     <button
      type="submit"
      @click="nextStep = true"
      class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm body__button"
     >
      {{ $t("nextStep") }}
     </button>
    </div>
   </div>
  </div>
 </form>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import Tooltip from "./formTools/Tooltip.vue";
import OpenDestinationList from "./formTools/OpenDestinationList.vue";

import {
 Listbox,
 ListboxButton,
 ListboxLabel,
 ListboxOption,
 ListboxOptions,
 Switch,
 SwitchDescription,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";
import {
 CheckIcon,
 ExclamationCircleIcon,
 SelectorIcon,
 PlusSmIcon,
 MinusSmIcon,
 TrashIcon,
 XCircleIcon,
} from "@heroicons/vue/solid";

const account = localStorage.getItem("account");

const stepName = "mainInfos";

export default {
 name: "CollectMainInfos",
 props: [
  "contactsLoaded",
  "contentData",
  "fileId",
  "savedForm",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "domain3cx",
  "customerFileId",
 ],
 components: {
  CheckIcon,
  ExclamationCircleIcon,
  MinusSmIcon,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  OpenDestinationList,
  PlusSmIcon,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  SelectorIcon,
  TrashIcon,
  ToggleWithIcon,
  XCircleIcon,
  Tooltip,
 },
 setup() {
  const enabledSignatory = ref(false);
  const enabledTechinal = ref(true);
  const enabledAccountance = ref(true);
  return {
   enabledSignatory,
   enabledTechinal,
   enabledAccountance,
  };
 },
 data() {
  return {
   account,
   addressEditIndex: null,
   addressError: false,
   addressErrors: {
    number: null,
    lists: [],
   },
   contactEditIndex: null,
   contactError: false,
   contactErrors: {
    number: null,
    lists: [],
   },
   errorOnFinalFormAddress: false,
   errorOnFinalFormContact: false,
   errorOnFinalFormFqdn: false,
   errorEmail: false,
   form: {
    address: {
     name: "",
     street: "",
     city: "",
     state: "",
     postal: "",
     country: "France",
    },
    contacts: {
     invoice: false,
     firstName: "",
     lastName: "",
     email: "",
     phone: "",
     mobile: "",
     legalRepresentative: false,
     technicalContact: false,
     accountantContact: false,
     is_signer_contract: false,
     is_signer_iban: false,
     is_collect_contact: false,
     admin: false,
    },
   },
   formFinal: {
    globalSettings: {
     company: "",
     fqdn: "3cx",
     customDomain: "",
     domain3cx: "",
     domain3cxRoot: "",
     opco: "",
    },
    address: [],
    contacts: {},
    restrictions: { internationalCall: false, lists: {} },
    isComplete: true,
   },
   hints: {
    opco:
     "Un OPCO est un organisme agréé par l'Etat chargé de financer vos formations. Vous devez obligatoirement Sélectionner votre OPCO si votre formation ENCOM est prise en charge par votre OPCO.",
   },
   isEdited: false,
   isContactEdited: false,
   nextStep: false,
   network: {
    siteName: "",
    siteAddress: "",
    customerProvideSwitch: false,
    switchPOE: false,
    switchVlan: false,
    switchType: { model: "", brand: "" },
    firewall: "client",
    firewallType: { model: "", brand: "" },
    firewallReservedSpeed: "",
    firewallAllowTrafic: false,
    firewallProvider: false,
    smartphoneWifi: false,
    wifiType: { model: "", brand: "" },
    numbFixedWorkStations: 0,
    numbWifiAP: 0,
    wifiName: "",
    internetName: "",
    numbInternetAccess: 0,
    numbSwitchesProvided: 0,
    numbFirewallsProvided: 0,
    numbEthernetSockets: 0,
    numbPOEPorts: 0,
    numbElectricOutlets: 0,
    societyName: "",
    fileResponsibleName: "",
    fileResponsibleContact: "",
    fileResponsibleEmail: "",
    isComplete: false,
   },
   objKey: 0,
   objKeyContact: 0,
   openDestinations: {},
   saveForm: [],
   showForm: false,
   stepName,
   selectedDomain3cx: "",
  };
 },

 methods: {
  async loadDataAndContacts() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.formFinal = { ...this.contentData[this.stepName] };
   }

   if (Object.keys(this.formFinal.contacts).length === 0) {
    for (let index = 0; index < this.contactsLoaded.length; index++) {
     const contact = this.contactsLoaded[index];
     const objValue = {
      invoice: contact.invoice,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.emailAddress,
      phone: contact.landlinePhone,
      mobile: contact.mobilePhone,
      legalRepresentative: contact.legalRepresentative,
      technicalContact: contact.technicalContact,
      accountantContact: contact.invoicesRecipient,
      is_signer_contract: contact.is_signer_contract,
      is_signer_iban: contact.is_signer_iban,
      is_collect_contact: contact.is_collect_contact,
      admin: contact.admin,
     };
     let objKeyContact = 0;
     const newObj = { [index]: objValue };
     this.formFinal.contacts = { ...this.formFinal.contacts, ...newObj };
     objKeyContact = objKeyContact + 1;
    }

    /*

    this.contactsLoaded.forEach((contact) => {
      const objValue = {
        invoice: contact.invoice,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.emailAddress,
      phone: contact.landlinePhone,
      mobile: contact.mobilePhone,
      legalRepresentative: contact.legalRepresentative,
      technicalContact: contact.technicalContact,
      accountantContact: contact.invoicesRecipient,
      is_signer_contract: contact.is_signer_contract,
      is_signer_iban: contact.is_signer_iban,
      is_collect_contact: contact.is_collect_contact,
      admin: contact.admin,
     };
     let objKeyContact = 0;
     const newObj = { [objKeyContact]: objValue };
     this.formFinal.contacts = { ...this.formFinal.contacts, ...newObj };
     objKeyContact = objKeyContact + 1;
    }); */
   }
   this.getDomain3cxSelected();
  },
  getDomain3cxSelected() {
   const filtered = this.domain3cx.filter(
    (domain) => domain.code === this.formFinal.globalSettings.domain3cx
   );
   filtered.length > 0
    ? (this.selectedDomain3cx = filtered[0])
    : (this.selectedDomain3cx = this.domain3cx[0]);
  },
  async getData() {
   let keys = Object.keys(this.contentData);
   if (keys.includes(this.stepName)) {
    this.formFinal = { ...this.contentData[this.stepName] };
   }
  },

  isTraversable(obj) {
    return Array.isArray(obj) || (obj !== null && ['function', 'object'].includes(typeof obj));
  },

  sendForm(msg, bool) {
   this.errorOnFinalFormAddress = false;
   this.errorOnFinalFormContact = false;
   this.errorOnFinalFormFqdn = false;
   this.objKeyContact = Object.keys(this.formFinal.contacts).length;
   this.objKey = Object.keys(this.formFinal.address).length;

   if (
    this.formFinal.globalSettings.fqdn === "perso" &&
    !this.formFinal.globalSettings.customDomain
   ) {
    this.errorOnFinalFormFqdn = true;
   } else if (Object.keys(this.formFinal.address).length < 1) {
    if (
     this.form.address.name &&
     this.form.address.street &&
     this.form.address.postal &&
     this.form.address.city
    ) {
     var address = {
      0: { name: "", street: "", city: "", state: "", postal: "" },
     };

     for (const key in address) {
      if (Object.hasOwnProperty.call(address, key)) {
       const objValue = {
        name: this.form.address.name,
        street: this.form.address.street,
        city: this.form.address.city,
        state: this.form.address.state,
        postal: this.form.address.postal,
       };
       const newObj = { [this.objKey]: objValue };
       this.formFinal.address = { ...this.formFinal.address, ...newObj };
       this.objKey = this.objKey + 1;
      }
     }

     this.clearForm();
     this.sendForm(this.$t("informationSaved"), bool);
    } else {
     this.errorOnFinalFormAddress = true;
    }
   } else if (
    this.form.address.name &&
    this.form.address.street &&
    this.form.address.postal &&
    this.form.address.city
   ) {
    const objValue = {
     name: this.form.address.name,
     street: this.form.address.street,
     city: this.form.address.city,
     state: this.form.address.state,
     postal: this.form.address.postal,
    };
    const newObj = { [this.objKey]: objValue };
    this.formFinal.address = { ...this.formFinal.address, ...newObj };
    this.objKey = this.objKey + 1;
    this.clearForm();
    this.sendForm(this.$t("informationSaved"), bool);
   } else if (
    this.form.contacts.firstName &&
    this.form.contacts.lastName &&
    this.form.contacts.email &&
    /^\d+$/.test(this.form.contacts.phone && this.form.contacts.phone.replace(/\s/g, "")) &&
    (this.form.contacts.legalRepresentative ||
     this.form.contacts.technicalContact ||
     this.form.contacts.accountantContact) &&
    this.emailValidator(this.form.contacts.email)
   ) {
    const objValue = {
     invoice: this.form.contacts.invoice,
     firstName: this.form.contacts.firstName,
     lastName: this.form.contacts.lastName,
     email: this.form.contacts.email,
     phone: this.form.contacts.phone,
     mobile: this.form.contacts.mobile,
     legalRepresentative: this.form.contacts.legalRepresentative,
     technicalContact: this.form.contacts.technicalContact,
     accountantContact: this.form.contacts.accountantContact,
     admin: this.form.contacts.admin,
    };

    const newObj = { [this.objKeyContact]: objValue };
    this.formFinal.contacts = { ...this.formFinal.contacts, ...newObj };
    this.objKeyContact = this.objKeyContact + 1;

    this.clearContactForm();

    this.sendForm(this.$t("informationSaved"), bool);
   } else if (
    Object.keys(this.formFinal.address).length > 0 &&
    Object.keys(this.formFinal.contacts).length > 0
   ) {
    let adressess = this.formFinal.address;

    let content = this.contentData;

    let network = {};
    network = {
     siteName: "",
     siteAddress: "",
     customerProvideSwitch: false,
     switchPOE: false,
     switchVlan: false,
     switchType: { model: "", brand: "" },
     firewall: "client",
     firewallType: { model: "", brand: "" },
     firewallReservedSpeed: "",
     firewallAllowTrafic: false,
     firewallProvider: false,
     smartphoneWifi: false,
     wifiType: { model: "", brand: "" },
     numbFixedWorkStations: 0,
     numbWifiAP: 0,
     wifiName: "",
     internetName: "",
     numbInternetAccess: 0,
     numbSwitchesProvided: 0,
     numbFirewallsProvided: 0,
     numbEthernetSockets: 0,
     numbPOEPorts: 0,
     numbElectricOutlets: 0,
     societyName: "",
     fileResponsibleName: "",
     fileResponsibleContact: "",
     fileResponsibleEmail: "",
     isComplete: false,
    };

    if (adressess && this.isTraversable(adressess)) {
      Object.values(adressess).forEach((address) => {
       const { name, street } = address;
       const matchingObj2 = content["network"]?.find((obj) => obj.siteName === name);

       if (matchingObj2) {
        matchingObj2.siteAddress = street;
       } else {
        network.siteName = name;
        network.siteAddress = street;
        content["network"] = [];
        content["network"].push(network);
       }
      });
    }

    content[this.stepName] = { ...this.formFinal };
    content = JSON.stringify(content);
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`,
     data: {
      content: `${content}`,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    axios
     .request(options)
     .then((res) => {
      let nextFormIndex = parseInt(this.$route.params.formIndex) + 1;
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      if (bool) this.$emit("scrollToTop");

      if (this.nextStep) {
       window.scrollTo({
        top: 0,
        behavior: "smooth",
       });
       window.onscroll = () => {
        if (window.pageYOffset === 0) {
         window.onscroll = null;
         // Perform any additional actions here
         this.$router.push(
          `/customer-files/collect/${this.customerFileId}/${this.fileId}/${nextFormIndex}`
         );
        }
       };
      }
     })
     .catch(function (error) {
      console.error(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   } else {
    this.errorOnFinalFormContact = true;
   }
  },
  returnFormattedNumb(numb) {
   return numb ? numb.split(" ").join("") : "";
  },
  errorContactForm(contact) {
   var i = 0;
   this.contactError = true;
   this.contactErrors.lists = [];
   const isPhoneNum = /^\d+$/.test(this.returnFormattedNumb(contact.phone));
   const isMobileNum = /^\d+$/.test(this.returnFormattedNumb(contact.mobile));

   if (!this.emailValidator(this.form.contacts.email) && !this.form.contacts.email) {
    i = 1;

    var key = this.$t("theEmail") + " " + this.$t("cantBeEmpty");
    const objValue = {
     type: "email",
     error: key,
    };
    this.contactErrors.lists.push(objValue);

    this.contactErrors.number = i;
   } else if (!this.emailValidator(this.form.contacts.email)) {
    i = 1;

    var key = this.$t("emailErrorFormat") + " " + "johndoe@example.com";
    const objValue = {
     type: "email",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }
   if (!contact.accountantContact && !contact.legalRepresentative && !contact.technicalContact) {
    i = 1;

    var key = "Un type de contact doit être sélectionné (signataire, technique ou comptabilité)";
    const objValue = {
     type: "contact",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }
   if (
    !isPhoneNum &&
    this.returnFormattedNumb(contact.phone).length < 10 &&
    this.returnFormattedNumb(contact.phone).length > 0
   ) {
    i = 1;

    let key = "Le numéro de téléphone doit être au format 01 98 76 54 32";

    const objValue = {
     type: "phone",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }
   if (
    !isMobileNum &&
    this.returnFormattedNumb(contact.mobile).length < 10 &&
    this.returnFormattedNumb(contact.mobile).length > 0
   ) {
    i = 1;

    let key = "Le numéro de téléphone doit être au format 01 98 76 54 32";

    const objValue = {
     type: "mobile",
     error: key,
    };
    this.contactErrors.lists.push(objValue);
    this.contactErrors.number = i;
   }

   for (const key in contact) {
    if (Object.hasOwnProperty.call(contact, key)) {
     const element = contact[key];
     if ((key === "firstName" && !element) || (key === "lastName" && !element)) {
      i = i + 1;
      const objValue = {
       type: key,
       error: this.$t(key) + " " + this.$t("cantBeEmpty"),
      };
      this.contactErrors.lists.push(objValue);
     } else if (key === "phone" && !element) {
      i = i + 1;
      const objValue = {
       type: key,
       error: this.$t("phoneOrMobile") + " " + this.$t("cantBeEmpty"),
      };
      this.contactErrors.lists.push(objValue);
     }
    }
   }
   this.contactErrors.number = i;
  },
  returnContactError(errType) {
   const filtered = this.contactErrors.lists.filter(function (err) {
    return err.type === errType;
   });
   return filtered[0];
  },
  errorAddressForm(address) {
   var i = 0;
   this.addressError = true;
   this.addressErrors.lists = [];

   for (const key in address) {
    if (Object.hasOwnProperty.call(address, key)) {
     const element = address[key];
     if (element) {
      console.log(key);
     } else if (key !== "state") {
      i = i + 1;
      this.addressErrors.lists.push(key);
     }
    }
   }
   this.addressErrors.number = i;
  },
  checkEmailValid(email) {
   if (email && this.emailValidator(email)) {
    this.errorEmail = false;
    this.checkFormElementValid(email, "email");
   } else {
    this.errorEmail = true;
   }
  },
  addressDataConverter(data) {
   // Temporary method to convert data structure of formFinal.address
   // from object of objects to array of objects
   if (typeof data === "object") {
    const arr = Object.values(data);
    return (this.formFinal.address = [...arr]);
   }
  },
  addAddress() {
   this.addressError = false;
   // Temporary condition to convert data structure of formFinal.address
   if (typeof this.formFinal.address === "object") {
    this.addressDataConverter(this.formFinal.address);
   }
   const data = { ...this.form.address };
   if (data.name && data.street && data.city && data.postal) {
    this.formFinal.address = [...this.formFinal.address, data];
    this.clearForm();
    this.sendForm(this.$t("informationSaved"), false);
   } else {
    this.errorAddressForm(this.form.address);
   }
  },
  addContact() {
   this.objKeyContact = Object.keys(this.formFinal.contacts).length;
   if (Object.keys(this.formFinal.contacts).length === 0) {
    var contact = {
     0: {
      invoice: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobile: "",
      legalRepresentative: false,
      technicalContact: false,
      accountantContact: false,
      admin: false,
     },
    };
   } else {
    var contact = this.formFinal.contacts;
   }
   if (
    this.form.contacts.firstName &&
    this.form.contacts.lastName &&
    this.form.contacts.email &&
    (/^\d+$/.test(this.form.contacts.phone && this.form.contacts.phone.replace(/\s/g, "")) ||
     /^\d+$/.test(this.form.contacts.mobile && this.form.contacts.mobile.replace(/\s/g, ""))) &&
    (this.form.contacts.legalRepresentative ||
     this.form.contacts.technicalContact ||
     this.form.contacts.accountantContact) &&
    this.emailValidator(this.form.contacts.email)
   ) {
    const objValue = {
     invoice: this.form.contacts.invoice,
     firstName: this.form.contacts.firstName,
     lastName: this.form.contacts.lastName,
     email: this.form.contacts.email,
     phone: this.form.contacts.phone,
     mobile: this.form.contacts.mobile,
     legalRepresentative: this.form.contacts.legalRepresentative,
     technicalContact: this.form.contacts.technicalContact,
     accountantContact: this.form.contacts.accountantContact,
     admin: this.form.contacts.admin,
    };
    const newObj = { [this.objKeyContact]: objValue };
    this.formFinal.contacts = { ...contact, ...newObj };
    this.objKeyContact = this.objKeyContact + 1;
    this.clearContactForm();
    this.sendForm(this.$t("informationSaved"), false);
   } else {
    this.errorContactForm(this.form.contacts);
   }
  },
  deleteNewContact(index) {
   delete this.formFinal.contacts[index];
  },
  editNewContact(contact, index) {
   this.isContactEdited = true;
   this.showForm = true;
   this.contactEditIndex = index;
   this.form.contacts.invoice = contact.invoice;
   this.form.contacts.firstName = contact.firstName;
   this.form.contacts.lastName = contact.lastName;
   this.form.contacts.email = contact.email;
   this.form.contacts.phone = contact.phone;
   this.form.contacts.mobile = contact.mobile;
   this.form.contacts.admin = contact.admin;

   this.form.contacts.is_signer_contract = contact.is_signer_contract;
   this.form.contacts.is_signer_iban = contact.is_signer_iban;
   this.form.contacts.is_collect_contact = contact.is_collect_contact;

   contact.legalRepresentative === 1 || contact.legalRepresentative
    ? (this.form.contacts.legalRepresentative = true)
    : (this.form.contacts.legalRepresentative = false);
   contact.technicalContact === 1 || contact.technicalContact
    ? (this.form.contacts.technicalContact = true)
    : (this.form.contacts.technicalContact = false);
   contact.accountantContact === 1 || contact.accountantContact
    ? (this.form.contacts.accountantContact = true)
    : (this.form.contacts.accountantContact = false);
  },
  editContact() {
   if (
    this.form.contacts.firstName &&
    this.form.contacts.lastName &&
    this.form.contacts.email &&
    (/^\d+$/.test(this.form.contacts.phone && this.form.contacts.phone.replace(/\s/g, "")) ||
     /^\d+$/.test(this.form.contacts.mobile && this.form.contacts.mobile.replace(/\s/g, ""))) &&
    (this.form.contacts.legalRepresentative ||
     this.form.contacts.technicalContact ||
     this.form.contacts.accountantContact) &&
    this.emailValidator(this.form.contacts.email)
   ) {
    const contact = this.formFinal.contacts;
    const objValue = {
     invoice: this.form.contacts.invoice,
     firstName: this.form.contacts.firstName,
     lastName: this.form.contacts.lastName,
     email: this.form.contacts.email,
     phone: this.form.contacts.phone,
     mobile: this.form.contacts.mobile,
     legalRepresentative: this.form.contacts.legalRepresentative,
     technicalContact: this.form.contacts.technicalContact,
     accountantContact: this.form.contacts.accountantContact,
     is_signer_contract: this.form.contacts.is_signer_contract,
     is_signer_iban: this.form.contacts.is_signer_iban,
     is_collect_contact: this.form.contacts.is_collect_contact,
    };
    const newObj = { [this.contactEditIndex]: objValue };

    this.formFinal.contacts = { ...contact, ...newObj };
    this.clearContactForm();
    this.sendForm(this.$t("informationSaved"), false);
   } else {
    this.errorContactForm(this.form.contacts);
   }
  },
  deleteNewAddress(index) {
   let siteName = this.formFinal.address[index].name;
   if (this.contentData && this.contentData.network && this.contentData.network.length > 0) {
    for (let index = 0; index < this.contentData.network.length; index++) {
     const element = this.contentData.network[index];
     if (element.siteName === siteName) {
      this.contentData.network.splice(index, 1);
     }
    }
   }
   // Temporary condition to convert data structure of formFinal.address
   if (typeof this.formFinal.address === "object") {
    this.addressDataConverter(this.formFinal.address);
   }
   this.formFinal.address.splice(index, 1);
  },
  editNewAddress(address, index) {
   this.isEdited = true;
   this.showForm = true;
   this.addressEditIndex = index;
   this.form.address.name = address.name;
   this.form.address.street = address.street;
   this.form.address.city = address.city;
   this.form.address.state = address.state;
   this.form.address.postal = address.postal;
   this.form.address.country = address.country;
  },
  editAddress() {
   this.addressError = false;
   // Temporary condition to convert data structure of formFinal.address
   if (typeof this.formFinal.address === "object") {
    this.addressDataConverter(this.formFinal.address);
   }
   const data = { ...this.form.address };
   if (data.name && data.street && data.city && data.postal) {
    this.formFinal.address[this.addressEditIndex] = data;
    this.clearForm();
    this.sendForm(this.$t("informationSaved"), false);
   } else {
    this.errorAddressForm(this.form.address);
   }
  },
  clearForm() {
   this.form.address.name = "";
   this.form.address.street = "";
   this.form.address.city = "";
   this.form.address.state = "";
   this.form.address.postal = "";
   this.form.address.country = "France";
   this.isEdited = false;
  },
  clearContactForm() {
   this.contactError = false;
   this.errorEmail = false;
   this.contactErrors.lists = [];
   this.form.contacts.invoice = false;
   this.form.contacts.firstName = "";
   this.form.contacts.lastName = "";
   this.form.contacts.email = "";
   this.form.contacts.phone = "";
   this.form.contacts.mobile = "";
   this.form.contacts.legalRepresentative = false;
   this.form.contacts.technicalContact = false;
   this.form.contacts.accountantContact = false;
   this.form.contacts.admin = false;
   this.isContactEdited = false;
  },
  checkFormElementValid(el, key) {
   if (key === "mobile" || key === "phone") {
    const isnum = /^\d+$/.test(this.returnFormattedNumb(el));
    if (!isnum) {
     this.errorContactForm(this.form.contacts);
    } else {
     let index = this.contactErrors.lists.findIndex((element) => {
      if (element.type === key) {
       return true;
      }
     });
     index > -1 ? this.contactErrors.lists.splice(index, 1) : "";
    }
   } else if (key === "firstName" || key === "lastName" || key === "email") {
    let index = this.contactErrors.lists.findIndex((element) => {
     if (element.type === key) {
      return true;
     }
    });
    index > -1 ? this.contactErrors.lists.splice(index, 1) : "";
   } else if (
    (key === "legalRepresentative" && el) ||
    (key === "technicalContact" && el) ||
    (key === "accountantContact" && el)
   ) {
    let index = this.contactErrors.lists.findIndex((element) => {
     if (element.type === "contact") {
      return true;
     }
    });
    index > -1 ? this.contactErrors.lists.splice(index, 1) : "";
   }
  },
 },
 mounted() {
  //this.getData();
  this.loadDataAndContacts();
 },
 watch: {
  selectedDomain3cx: function (val) {
   val
    ? (this.formFinal.globalSettings.domain3cx = val.code)
    : this.domain3cx.length > 0
    ? (this.formFinal.globalSettings.domain3cx = this.domain3cx[0].code)
    : (this.formFinal.globalSettings.domain3cx = "par");
  },
  "form.contacts.accountantContact": {
   handler: function (bool) {
    bool ? (this.form.contacts.invoice = true) : (this.form.contacts.invoice = false);
   },
   deep: true,
  },
  elementRef: function (ref) {
   if (ref === "mainForm") {
    const el = this.$refs.mainForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   } else if (ref === "contactForm") {
    const el = this.$refs.contactForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   } else if (ref === "addressForm") {
    const el = this.$refs.addressForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  formFinal: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "mainForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
  form: {
   handler: function (object) {
    const unsaveChangesContact = Object.values(object.contacts).every(
     (x) => x === false || x === ""
    );

    const unsaveChangesAddress = Object.values(object.address).every(
     (x) => x === "France" || x === ""
    );
    if (!unsaveChangesContact) {
     this.$emit("unsavedChanges", true, "contactForm");
    } else if (!unsaveChangesAddress) {
     this.$emit("unsavedChanges", true, "addressForm");
    } else {
     this.$emit("unsavedChanges", false, "");
    }
   },
   deep: true,
  },
 },
};
</script>

<style></style>
