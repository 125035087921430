<template>
 <div class="mt-5 bg-yellow-50 border-l-4 border-yellow-400 p-2">
  <div class="flex">
   <div class="flex-shrink-0">
    <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
   </div>
   <div class="ml-3">
    <span class="text-yellow-700 text-xs">
     Si vous choisissez une musique qui n’est pas libre de droit, vous devez effectuer une
     déclaration auprès de la SACEM et de la SCPA et régler la redevance annuelle calculée en
     fonction du nombre d’accès téléphoniques.
     <br />
     - pour la SACEM
     <a
      class="text-blue-400 font-medium underline"
      href="https://clients.sacem.fr/autorisations/landing?keyword=Musique+d%27attente+t%C3%A9l%C3%A9phonique"
      target="_blank"
     >
      https://clients.sacem.fr/autorisations
     </a>
     <br />
     - pour la SCPA :
     <a
      class="text-blue-400 font-medium underline"
      href="https://www.lascpa.org/Pages/choix-declaration.aspx"
      target="_blank"
     >
      https://www.lascpa.org/Pages/choix-declaration.aspx
     </a>
     <br />
     - pour la brochure de tarif SCPA :
     <a
      class="text-blue-400 font-medium underline"
      href="https://www.lascpa.org/#tarif"
      target="_blank"
     >
      https://www.lascpa.org/#tarif
     </a>
    </span>
   </div>
  </div>
 </div>
 <form @submit.prevent="onSubmit()" class="bg-white" method="POST">
  <div class="md:grid md:grid-cols-3 md:gap-6 border px-4 py-5 sm:rounded-lg sm:p-6 mt-2">
   <div class="mt-5 md:mt-0 md:col-span-3">
    <div class="space-y-6 sm:space-y-5">
     <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
      <label for="msgName" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
       {{ $t("messageName") }}*
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
       <div>
        <div class="relative rounded-md shadow-sm">
         <input
          required
          v-model="formName"
          type="text"
          name="msgName"
          id="msgName"
          class="border-gray-300 focus:outline-none block w-full rounded-md pr-10 sm:text-sm"
          :class="{
           'text-red-900 placeholder-red-300 focus:ring-red-500 border-red-300 focus:border-red-500 ':
            messageNameInvalid,
          }"
         />
         <div
          v-show="messageNameInvalid"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
         >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
        </div>
        <p v-show="messageNameInvalid" class="mt-1 ml-1 text-sm text-red-600" id="email-error">
         {{ $t("messageNameInvalid") }}.
        </p>
       </div>
      </div>
     </div>
     <!--   <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
      <label for="type" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
       Type*
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
       <select
        v-model="this.form.type"
        id="type"
        required
        name="type"
        class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300"
       >
        <option v-for="type in typeList" :key="type.id" :value="type.type">
         {{ type.type }}
        </option>
       </select>
      </div>
     </div>

     <div class="sm:grid sm:grid-cols-4 sm:items-start sm:pt-5">
      <label
       for="associatedNumber"
       class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
       {{
        form.type == "Pre-décroché pour une extension"
         ? "Extension " + $t("associatedMessage")
         : form.type == `Pre-décroché pour une file d'attente`
         ? `File d'attente ` + $t("associatedMessage")
         : form.type == "Pre-décroché pour SVI"
         ? "SVI " + $t("associatedMessage")
         : form.type == "Autre"
         ? "Choix personnalisé " + $t("associatedMessage")
         : form.type
       }}
      </label>
      <div
       v-if="form.type == 'Pre-décroché pour une extension' || form.type == 'Répondeur Personnel'"
       class="mt-1 sm:mt-0 sm:col-span-2"
      >
       <SearchExtensionsGroup
        :data="users"
        :placeholder="$t('header.search')"
        :isMultiple="true"
        :closeOnSelect="false"
        @action="(form.associatedElements = $event), (associatedNumber = $event)"
        :selected="form.associatedElements"
        :visibleOptions="this.availableExtensions()"
       />
       <p class="text-sm text-gray-500">
        {{ $t("goToSection") }} 03
        <span class="capitalize text-gray-700">
         {{ $t("users") }}
        </span>
        {{ $t("toManage") }} {{ $t("users") }}.
       </p>
      </div>
      <div
       v-else-if="
        form.type == `Pre-décroché pour une file d'attente` ||
        form.type == `Mise en attente pour une file d'attente`
       "
       class="mt-1 sm:mt-0 sm:col-span-2"
      >
       <SearchExtensionsGroup
        :data="queues"
        :placeholder="$t('header.search')"
        :isMultiple="true"
        :closeOnSelect="false"
        @action="(form.associatedElements = $event), (associatedNumber = $event)"
        :selected="form.associatedElements"
        :visibleOptions="this.availableQueues()"
       />
       <p class="text-sm text-gray-500">
        {{ $t("goToSection") }} 04
        <span class="text-gray-700"> {{ $t("callqueues") }} </span>
        {{ $t("toManage") }} {{ $t("callqueues") }}.
       </p>
      </div>
      <div v-else-if="form.type == `Pre-décroché pour SVI`" class="mt-1 sm:mt-0 sm:col-span-2">
       <SearchExtensionsGroup
        :data="svi"
        :placeholder="$t('header.search')"
        :isMultiple="true"
        :closeOnSelect="false"
        @action="(form.associatedElements = $event), (associatedNumber = $event)"
        :selected="form.associatedElements"
        :visibleOptions="this.availableSvi()"
       />
       <p class="text-sm text-gray-500">
        {{ $t("goToSection") }} 05
        <span class="text-gray-700"> SVI</span>
        {{ $t("toManage") }} SVI.
       </p>
      </div>
      <div
       v-else-if="
        form.type == 'Répondeur heures Ouvrées' || form.type == 'Répondeur heures Fermées'
       "
       class="mt-1 sm:mt-0 sm:col-span-2"
      >
       <SdaLinkedSelect
        :sdaNumberList="this.form.associatedElements"
        :createdSda="createdSda"
        :sdaLeft="this.sdaLeft"
        :sdaMax="this.sdaMax"
        :range="range"
        :sdaTown="this.sdaTown"
        :customerFileId="customerFileId"
        :fileId="fileId"
        :errorAddedSda="errorAddedSda"
        :errorSdaFound="errorSdaFound"
        @remove-sda="this.removeSda($event)"
        @create-new-number="this.createNewNumber($event)"
        @check-validate-sda="this.checkValidateSda($event)"
        @check-carried-number="this.checkCarriedNumber($event)"
       />
       <p class="text-sm text-gray-500">
        {{ $t("goToSection") }} 02
        <span class="text-gray-700"> {{ $t("numbersPortability") }}</span>
        {{ $t("toManage") }} {{ $t("sdaLinked") }}.
       </p>
      </div>
      <div v-else-if="form.type == `Autre`" class="mt-1 sm:mt-0 sm:col-span-2">
       <p class="text-sm text-gray-500 mt-2">
        Préciser dans la partie Commentaire le type de message
       </p>
      </div>
      <div v-show="isError" class="col-span-2 col-start-2 rounded-md bg-red-50 p-2 mt-1">
       <div class="flex">
        <div class="flex-shrink-0">
         <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-sm font-medium text-red-800 mt-px">{{ $t("selectElementFromList") }}.</h3>
        </div>
       </div>
      </div>
     </div> -->

     <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
      <fieldset class="col-span-2 col-start-2">
       <legend class="sr-only">Notification method</legend>
       <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div class="flex items-center">
         <input
          v-model="form.messageOption"
          value="new-message"
          id="new-message"
          name="notification-method"
          type="radio"
          checked
          class="h-4 w-4 border-gray-300"
         />
         <label for="new-message" class="ml-3 block text-sm font-medium text-gray-700">
          Créer un nouveau message avec Encom
         </label>
        </div>
        <div class="flex items-center">
         <input
          v-model="form.messageOption"
          value="import-message"
          id="import-message"
          name="notification-method"
          type="radio"
          class="h-4 w-4 border-gray-300"
         />
         <label for="import-message" class="ml-3 block text-sm font-medium text-gray-700">
          Importer un message existant
         </label>
        </div>
       </div>
      </fieldset>
     </div>

     <div v-if="form.messageOption === 'new-message'">
      <div class="sm:grid sm:grid-cols-4 sm:gap-2 sm:items-start sm:pt-5">
       <div class="flex space-x-2 col-start-2">
        <ToggleWithIcon :validated="form.isMusic" @boolean-toggle="form.isMusic = $event" />
        <label for="street-address" class="mt-px text-sm font-medium text-gray-700">
         {{ !form.isMusic ? $t("withoutMusic") : $t("withMusic") }}
        </label>
        <Tooltip
         tooltip1="Rendez-vous sur https://www.encom-studio.com/?MUSIQUE pour choisir votre musique."
         tooltip2="Visualisez d’un click toutes nos musiques libres de droits en cliquant sur « MUSIQUE SANS DROITS » en haut à droite de la page."
        />
       </div>
      </div>
      <div v-show="form.isMusic" class="sm:grid sm:grid-cols-4 sm:gap-2 sm:items-start sm:pt-2">
       <label for="music" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
        {{ $t("musicChoice") }}*
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="form.music"
         type="text"
         name="predecroche"
         id="predecroche"
         class="col-start-2 block shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
        <p class="col-start-2 col-span-2 text-sm text-gray-500">
         Rendez-vous sur
         <a
          class="text-gray-600 hover:text-gray-800 underline"
          href="https://www.encom-studio.com/?MUSIQUE"
          target="_blank"
         >
          https://www.encom-studio.com/?MUSIQUE
         </a>
         pour choisir votre musique.
        </p>
       </div>
      </div>

      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
       <label for="voice" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
        {{ $t("voiceChoice") }}*
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="this.form.voice"
         required
         type="text"
         name="voice"
         id="voice"
         class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300"
        />
        <p class="col-start-2 col-span-2 text-sm text-gray-500">
         Rendez-vous sur
         <a
          class="text-gray-600 hover:text-gray-800 underline"
          href="https://www.encom-studio.com/?VOIX"
          target="_blank"
         >
          https://www.encom-studio.com/?VOIX
         </a>
         pour choisir votre voix.
        </p>
       </div>
      </div>
      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
       <label for="message" class="block text-sm font-semibold text-gray-700 sm:mt-px sm:pt-2">
        Message*
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <textarea
         v-model="this.form.message"
         required
         type="text"
         name="message"
         id="message"
         :placeholder="$t('typeYourMessage')"
         rows="5"
         class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300"
        />
       </div>
      </div>
      <div class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
       <div>
        <label for="phonetic" class="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
         {{ $t("phonetic") }}
        </label>
        <Tooltip
         tooltip1="Pour tous les mots dont la prononciation pourrait être interprétée différemment selon les acteurs nous vous recommandons de préciser la phonétique."
         tooltip2="Exemple : ENCOM = « N» com"
        />
       </div>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         v-model="this.form.phonetic"
         type="text"
         name="phonetic"
         id="phonetic"
         class="rounded block w-full shadow-sm focus:encom_border_input sm:text-sm border-gray-300"
        />
       </div>
      </div>
     </div>

     <div v-else class="sm:grid sm:grid-cols-4 sm:items-start">
      <div v-if="form.file" class="col-start-2 col-span-2">
       <div class="border-t border-gray-200">
        <dl class="sm:divide-y sm:divide-gray-200">
         <div class="py-4 sm:grid sm:grid-cols-3 sm:py-5">
          <dt class="text-sm font-medium text-gray-500">
           {{ $t("importedFile") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
           <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
             <div class="flex w-0 flex-1 items-center">
              <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <span class="ml-2 w-0 flex-1 truncate">
               {{ form.file.fileName }}
              </span>
             </div>
             <div class="ml-4 flex-shrink-0">
              <a
               @click="(form.file = ''), (form.messageOption = 'new-message')"
               class="cursor-pointer font-medium text-red-500 hover:text-red-700"
              >
               {{ $t("delete") }}
              </a>
             </div>
            </li>
           </ul>
          </dd>
         </div>
        </dl>
       </div>
      </div>
      <div class="col-start-2 col-span-2">
       <div class="rounded-md bg-yellow-50 p-1.5 mb-2">
        <div class="flex">
         <div class="flex-shrink-0">
          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
         </div>
         <div class="ml-3">
          <div class="text-xs text-yellow-700">
           <p>
            Si votre fichier audio n'est pas en wav, convertissez le en suivant le lien ci-dessous :
            <br />
            <a
             class="text-blue-400 font-medium underline"
             href="https://www.3cx.com/docs/converting-wav-file/"
             >https://www.3cx.com/docs/converting-wav-file/</a
            >
           </p>
          </div>
         </div>
        </div>
       </div>
       <CollectImportMessage
        :key="isEdited"
        :fileId="fileId"
        :errorMissingFile="errorMissingFile"
        @file-data="form.file = { id: $event.id, fileName: $event.fileName }"
        @close-form="showImportForm = $event"
       />
      </div>
     </div>
    </div>
   </div>
  </div>

  <div class="pt-5" ref="studioMessageForm">
   <div class="flex justify-end">
    <a
     href="javascript:void(0)"
     @click="$emit('closeForm')"
     class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
    >
     {{ $t("ticket.cancel") }}
    </a>
    <button
     type="submit"
     class="capitalize rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white"
     :class="{ 'animate-bounce': bounceButton === 'studioMessageForm' && unsavedChangesBool }"
    >
     {{ $t("validate") }}
    </button>
   </div>
  </div>
 </form>
</template>

<script>
import axios from "axios";
import CollectImportMessage from "./CollectImportMessage.vue";

import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";
import Tooltip from "./formTools/Tooltip.vue";
import SdaLinkedSelect from "./formTools/SdaLinkedSelect.vue";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import { Switch } from "@headlessui/vue";
import {
 XCircleIcon,
 PaperClipIcon,
 ExclamationIcon,
 ExclamationCircleIcon,
} from "@heroicons/vue/solid";

const date = new Date();

const typeList = [
 { id: 0, type: "Pre-décroché pour une extension" },
 { id: 1, type: "Pre-décroché pour une file d'attente" },
 { id: 2, type: "Pre-décroché pour SVI" },
 { id: 3, type: "Mise en attente générale 3CX" },
 { id: 4, type: "Mise en attente pour une file d'attente" },
 { id: 5, type: "Répondeur heures Ouvrées" },
 { id: 6, type: "Répondeur heures Fermées" },
 { id: 7, type: "Répondeur Personnel" },
 { id: 8, type: "Autre" },
];

export default {
 props: [
  "defaultMsg",
  "defaultTitle",
  "editData",
  "fileId",
  "editIdx",
  "queues",
  "svi",
  "users",
  "messageAssociatedNumbers",
  "isEdit",
  "messagesNames",
  "contentData",
  "createdSda",
  "sdaTown",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
 ],
 components: {
  CollectImportMessage,
  Switch,
  SearchExtensionsGroup,
  Tooltip,
  ToggleWithIcon,
  XCircleIcon,
  PaperClipIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  SdaLinkedSelect,
 },
 data() {
  return {
   associatedNumber: [],
   date,
   errorSdaFound: false,
   errorAddedSda: false,
   errorIsInside: false,
   errorMissingFile: false,
   formName: "",
   form: {
    name: "",
    associatedNumber: undefined,
    associatedQueue: [],
    associatedElements: [],
    associatedExtension: [],
    messageOption: "new-message",
    file: "",
    isMusic: true,
    music: "",
    voice: "",
    message: "",
    phonetic: "",
    isComplete: true,
   },
   messageNameInvalid: false,
   isError: false,
   sdaLeft: null,
   sdaMax: null,
   range: null,
   typeList,
  };
 },
 methods: {
  /* onSubmit() {
      if (
        (this.form.associatedQueue && this.form.associatedQueue.length > 0) ||
        (this.form.associatedExtension &&
          this.form.associatedExtension.length > 0)
      ) {
        if (!this.messageNameInvalid && this.form.name.length > 0) {
          this.$emit("submitForm", this.form);
        }
      } else {
        this.isError = true;
      } */

  onSubmit() {
   if (this.form.messageOption === "import-message") {
    this.form.music = "";
    this.form.voice = "";
    this.form.message = "";
    try {
     /* Previous condition, we removed associatedElements
     if (
      this.form.associatedElements &&
      this.form.associatedElements.length > 0 &&
      !this.messageNameInvalid &&
      this.form.name.length > 0
     )  */
     if (!this.messageNameInvalid && this.form.name.length > 0) {
      try {
       this.form.file
        ? this.$emit("submitForm", this.form)
        : this._throw("No file has been uploaded");
      } catch (error) {
       this.errorMissingFile = true;
       console.error("Error submitting studio message, reason: " + error);
      }
     } else {
      this._throw("No extension has been added or no name is empty");
     }
    } catch (error) {
     if (this.form.associatedElements.length < 1) {
      this.isError = true;
      console.error("Error submitting studio message, reason: " + error);
     } else {
      this.messageNameInvalid = true;
      console.error("Error submitting studio message, reason: " + error);
     }
    }
   } else {
    try {
     /* Previous condition, we removed associatedElements   
   this.form.associatedElements &&
     this.form.associatedElements.length > 0 &&
     !this.messageNameInvalid &&
     this.form.name.length > 0
      ? this.$emit("submitForm", this.form)
      : this._throw("please give a correct name");  */

     !this.messageNameInvalid && this.form.name.length > 0
      ? this.$emit("submitForm", this.form)
      : this._throw("please give a correct name");
    } catch (error) {
     if (this.form.associatedElements.length < 1) {
      this.isError = true;
      console.error("Error submitting studio message, reason: " + error);
     } else {
      this.messageNameInvalid = true;
      console.error("Error submitting studio message, reason: " + error);
     }
    }
   }
  },
  formatDateForForm(date) {
   let formDate = new Date(date);
   let formDay = formDate.getDate();
   let formMonth = formDate.getMonth();
   let formYear = formDate.getFullYear();
   return formDay + "/" + formMonth + "/" + formYear;
  },
  getDataFromEdit() {
   if (this.editData) {
    let data = this.editData;
    this.form.name = data.name;
    this.formName = data.name;
    /*   this.form.type = data.type;
    this.form.associatedElements = data.associatedElements;
    this.form.associatedExtension = data.associatedExtension;
    this.form.associatedQueue = data.associatedQueue;
    this.form.associatedNumber = data.associatedNumber; */
    this.form.messageOption = data.messageOption;
    this.form.messageOption === "import-message" ? (this.form.file = data.file) : "";

    this.form.music = data.music;
    this.form.voice = data.voice;
    this.form.message = data.message;
    this.form.phonetic = data.phonetic;
   }
   this.savedForm = JSON.stringify(this.form);
  },
  availableQueues() {
   let nonAssociatedQueues = [];
   let queues = this.queues ? this.queues : [];
   if (this.messageAssociatedNumbers && this.messageAssociatedNumbers.length > 0) {
    for (let index = 0; index < queues.length; index++) {
     const element = queues[index];
     if (this.messageAssociatedNumbers.indexOf(element) < 0) {
      nonAssociatedQueues.push(element);
     }
    }
   } else {
    nonAssociatedQueues = queues;
   }
   return nonAssociatedQueues;
  },
  availableExtensions() {
   let nonAssociatedExtension = [];
   let extensions = this.users ? this.users : [];
   if (this.messageAssociatedNumbers && this.messageAssociatedNumbers.length > 0) {
    for (let index = 0; index < extensions.length; index++) {
     const element = extensions[index];
     if (this.messageAssociatedNumbers.indexOf(element) < 0) {
      nonAssociatedExtension.push(element);
     }
    }
   } else {
    nonAssociatedExtension = extensions;
   }
   return nonAssociatedExtension;
  },
  availableSvi() {
   let nonAssociatedSvi = [];
   let svi = this.svi ? this.svi : [];
   if (this.messageAssociatedNumbers && this.messageAssociatedNumbers.length > 0) {
    for (let index = 0; index < svi.length; index++) {
     const element = svi[index];
     if (this.messageAssociatedNumbers.indexOf(element) < 0) {
      nonAssociatedSvi.push(element);
     }
    }
   } else {
    nonAssociatedSvi = svi;
   }
   return nonAssociatedSvi;
  },
  getStoreVar() {
   if (this.contentData["numbers"] && this.contentData["numbers"].sdaUsed) {
    this.sdaLeft = this.contentData["numbers"].sdaUsed.sdaLeft;
    this.sdaMax = this.contentData["numbers"].sdaUsed.sdaMax;
    this.range = this.contentData["numbers"].number.range.map((a) => {
     return { ...a };
    });
   }
  },
  removeSda(index) {
   let arr = [...this.form.associatedElements];
   arr.splice(index, 1);
   this.form.associatedElements = [...arr];

   this.sdaLeft >= this.sdaMax ? (this.sdaLeft = this.sdaMax) : (this.sdaLeft = this.sdaLeft + 1);
  },
  checkValidateSda(sdaNumber) {
   this.errorAddedSda = false;
   this.errorSdaFound = false;
   var absoluteSda = sdaNumber ? sdaNumber.split(" ").join("") : "";
   const isnum = /^\d+$/.test(absoluteSda);

   const filterExisted = this.form.associatedElements.filter((sdas) => sdas === absoluteSda);
   const filterNew = this.createdSda.filter((sdas) => sdas === absoluteSda);
   if (absoluteSda.length === 10 && isnum && filterExisted.length < 1 && filterNew.length < 1) {
    let arr = [...this.form.associatedElements];
    arr.push(absoluteSda);
    this.form.associatedElements = [...arr];

    this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = this.sdaLeft - 1);
    this.sdaNumber = "";
   } else if (filterExisted.length > 0 || filterNew.length > 0) {
    this.errorSdaFound = true;
   } else {
    this.errorAddedSda = true;
   }
  },
  checkCarriedNumber(numb) {
   this.errorAddedSda = false;
   this.errorIsInside = false;
   var absoluteNumb = numb ? numb.split(" ").join("") : "";
   const isnum = /^\d+$/.test(absoluteNumb);
   if (isnum) {
    for (let index = 0; index < this.range.length; index++) {
     const range = this.range[index];
     if (absoluteNumb >= range.from && absoluteNumb <= range.to) {
      this.checkValidateSda(numb);
      return true;
     }
    }
    this.errorIsInside = true;
   } else {
    this.errorAddedSda = true;
   }
  },
  createNewNumber(town) {
   let arr = [...this.form.associatedElements];
   arr.push(town);
   this.form.associatedElements = [...arr];
   this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = this.sdaLeft - 1);
  },
 },
 mounted() {
  this.form.message = this.defaultMsg;
  this.formName = this.defaultTitle;
  this.getDataFromEdit();
  // this.availableQueues();
  this.getStoreVar();
 },
 watch: {
  formName: function (val) {
   if (val && this.messagesNames.includes(val) && !this.isEdit) {
    this.messageNameInvalid = true;
   } else {
    this.messageNameInvalid = false;
    this.form.name = val;
   }
  },
  associatedNumber: function (val) {
   if (val && val.length > 0) {
    this.isError = false;
   } else {
    this.error = true;
   }
  },
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.studioMessageForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  form: {
   handler: function (object) {
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "studioMessageForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
};
</script>

<style></style>
