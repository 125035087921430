const collectDependenciesKeys = {
  users: {
    callQueue: {
      adminExt: {
        isArray: true,
        hasObject: false,
        isObject: false,
      },
      participants: {
        isArray: true,
        hasObject: false,
        isObject: false,
      },
      nonAnsweringDestination: {
        isArray: false,
        hasObject: false,
        isObject: true,
        valueKeyToCheck: "number",
      },
      nonWorkingTimeDestination: {
        isArray: false,
        hasObject: false,
        isObject: true,
        valueKeyToCheck: "number",
      },
    },
    groups: {
      users: {
        isArray: true,
        hasObject: true,
        isObject: false,
        valueKeyToCheck: "extension",
      },
    },
  },
};

export default collectDependenciesKeys;
