<template>
 <form @submit.prevent="onSubmit()" method="POST">
  <div class="mt-2 space-y-6">
   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("generalSettings") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-6 gap-6">
       <div class="col-span-6 sm:col-span-6">
        <label for="svi-name" class="block text-sm font-semibold text-gray-700">
         {{ $t("callQueueName") }}*
        </label>
        <input
         v-model="formName"
         type="text"
         name="svi-name"
         id="svi-name"
         required
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
        <div v-show="queueNameInvalid" class="p-2 mt-3 rounded-md bg-red-50">
         <FormFieldMandatory message="This queue name is already being used." />
        </div>
       </div>

       <div class="col-span-6 sm:col-span-4 lg:col-span-4">
        <SdaLinkedSelect
         :sdaNumberList="this.form.associateNumber"
         :createdSda="createdSda"
         :sdaLeft="sdaLeft"
         :sdaMax="sdaMax"
         :range="range"
         :isolatedNumbers="isolatedNumbers"
         :sdaTown="sdaTown"
         :customerFileId="customerFileId"
         :fileId="fileId"
         :errorAddedSda="errorAddedSda"
         :errorSdaFound="errorSdaFound"
         :errorIsInside="errorIsInside"
         :notBundledNumbers="otherNumbers"
         :collectType="collectType"
         @remove-sda="this.removeSda($event)"
         @remove-element="removeElement()"
         @create-new-number="this.createNewNumber($event)"
         @check-validate-sda="this.checkValidateSda($event)"
         @check-carried-number="this.checkCarriedNumber($event)" />
       </div>
       <div class="col-span-6 sm:col-span-4">
        <label for="maxCallerNumb" class="text-sm font-medium text-gray-700">
         {{ $t("maxCallerNumb") }}
        </label>
        <Tooltip
         tooltip1="Quand ce nombre est atteint, l’appel sera routé en fonction du paramétrage dans : Destination en cas de non-réponses de la section Stratégie heures d’ouverture." />
        <p class="text-sm text-gray-500">
         La valeur 0 vaut illimité par défaut
        </p>

        <input
         v-model="form.maxCallerNumb"
         type="text"
         name="maxCallerNumb"
         id="maxCallerNumb"
         class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
       </div>
       <div class="col-span-6 sm:col-span-4">
        <label for="priorityQueue" class="text-sm font-medium text-gray-700">
         {{ $t("priorityQueue") }}
        </label>
        <Tooltip
         tooltip1="Une File prioritaire permet de prioriser les appels d’une file d’attente par rapport aux autres files dont l’agent/extension est membre."
         tooltip2="Exemple : une équipe de support a une file d’attente pour les appels standards de support et une autre pour les clients VIP. " />
        <div class="flex mt-2 space-x-2">
         <ToggleWithIcon
          @boolean-toggle="form.priorityQueue = $event"
          :validated="form.priorityQueue" />
         <label
          for="street-address"
          class="block mt-px text-sm font-medium text-gray-600">
          Option
          {{ !form.priorityQueue ? $t("desactivated") : $t("activated") }}
         </label>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("members") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-4 gap-6">
       <div class="col-span-4 sm:col-span-2">
        <div class="flex justify-between mx-2 mb-2">
         <div>
          <label for="participants" class="text-sm font-semibold text-gray-700">
           {{ $t("userQueueExtension") }}*
          </label>
          <Tooltip
           tooltip1="Sélectionnez les extensions qui seront membres de la file d’attente. L'utilisateur devra aussi se connecter à la file pour recevoir des appels." />
         </div>

         <div
          v-if="collectType === 'newSite'"
          class="flex items-center justify-between">
          <Switch
           v-model="userQueueEnabled"
           class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2">
           <span class="sr-only">Use setting</span>
           <span
            aria-hidden="true"
            class="absolute w-full h-full bg-white rounded-md pointer-events-none" />
           <span
            aria-hidden="true"
            :class="[
             userQueueEnabled ? 'encom_primary' : 'bg-gray-200',
             'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
            ]" />
           <span
            aria-hidden="true"
            :class="[
             userQueueEnabled ? 'translate-x-5' : 'translate-x-0',
             'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
            ]" />
          </Switch>
          <span class="ml-2 text-xs font-medium text-gray-600" passive>
           Extension existante
          </span>
         </div>
        </div>

        <div>
         <ul role="list" class="grid grid-cols-3 gap-2 my-1">
          <li
           v-for="(participant, idx) in form.participants"
           :key="idx"
           class="col-span-1 rounded-md shadow-sm">
           <div
            class="flex items-center justify-between flex-1 bg-white border border-t border-gray-300 rounded-md">
            <div class="flex-1 p-2 text-xs truncate" :title="participant">
             <p class="text-gray-500">
              {{ participant }}
             </p>
            </div>

            <div class="flex-shrink-0 pr-2">
             <button
              type="button"
              @click="
               form.participants.splice(
                form.participants.indexOf(participant),
                1
               )
              "
              class="inline-flex items-center justify-center w-6 h-6 text-red-400 bg-transparent bg-white rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke-width="1.5"
               stroke="currentColor"
               class="w-4 h-4">
               <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
             </button>
            </div>
           </div>
          </li>
         </ul>
         <SearchSelector
          v-if="!userQueueEnabled"
          :key="$route.name"
          :selected="this.form.participants"
          :searchData="users" />
         <input
          v-else
          v-model="userQueueExistingExtension"
          @keydown.enter.prevent="
           userQueueExistingExtension &&
            addExistingExtension('user', userQueueExistingExtension)
          "
          @input="
           userQueueExistingExtension = userQueueExistingExtension.replace(
            /\D/g,
            ''
           )
          "
          type="tel"
          name="participants"
          id="participants"
          :placeholder="this.$t('existingExtension')"
          class="block w-full mt-1 border-gray-300 rounded-md sm:text-sm" />
        </div>
       </div>
       <div class="col-span-4 sm:col-span-2">
        <div class="flex justify-between mx-2 mb-2">
         <div>
          <label for="admin-ext" class="text-sm font-semibold text-gray-700">
           {{ $t("adminQueueExtension") }}*
          </label>
          <Tooltip
           tooltip1="L'administrateur de la file n'est pas obligatoirement
                membre de la file. Le ou les administrateurs d’une file ont la
                possibilité de :"
           tooltipList1="Logger/Dé-logger les membres de la file."
           tooltipList2="Recevoir les notifications email pour cette file."
           :list="true" />
         </div>

         <div
          v-if="collectType === 'newSite'"
          class="flex items-center justify-between">
          <Switch
           v-model="adminQueueEnabled"
           class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2">
           <span class="sr-only">Use setting</span>
           <span
            aria-hidden="true"
            class="absolute w-full h-full bg-white rounded-md pointer-events-none" />
           <span
            aria-hidden="true"
            :class="[
             adminQueueEnabled ? 'encom_primary' : 'bg-gray-200',
             'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
            ]" />
           <span
            aria-hidden="true"
            :class="[
             adminQueueEnabled ? 'translate-x-5' : 'translate-x-0',
             'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
            ]" />
          </Switch>
          <span class="ml-2 text-xs font-medium text-gray-600" passive>
           Extension existante
          </span>
         </div>
        </div>

        <div>
         <ul role="list" class="grid grid-cols-3 gap-2 my-1">
          <li
           v-for="(participant, idx) in form.adminExt"
           :key="idx"
           class="col-span-1 rounded-md shadow-sm">
           <div
            class="flex items-center justify-between flex-1 bg-white border border-t border-gray-300 rounded-md">
            <div class="flex-1 p-2 text-xs truncate" :title="participant">
             <p class="text-gray-500">
              {{ participant }}
             </p>
            </div>

            <div class="flex-shrink-0 pr-2">
             <button
              type="button"
              @click="
               form.adminExt.splice(form.adminExt.indexOf(participant), 1)
              "
              class="inline-flex items-center justify-center w-6 h-6 text-red-400 bg-transparent bg-white rounded-full hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke-width="1.5"
               stroke="currentColor"
               class="w-4 h-4">
               <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
             </button>
            </div>
           </div>
          </li>
         </ul>

         <SearchSelector
          v-if="!adminQueueEnabled"
          :key="$route.name"
          :selected="form.adminExt"
          :searchData="users" />

         <input
          v-else
          v-model="adminQueueExistingExtension"
          @keydown.enter.prevent="
           adminQueueExistingExtension &&
            addExistingExtension('admin', adminQueueExistingExtension)
          "
          @input="
           adminQueueExistingExtension = adminQueueExistingExtension.replace(
            /\D/g,
            ''
           )
          "
          type="tel"
          name="admin-ext"
          id="admin-ext"
          :placeholder="this.$t('existingExtension')"
          class="block w-full mt-1 border-gray-300 rounded-md sm:text-sm" />
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("strategyOpeningHours") }}
      </h3>
     </div>
     <div class="grid grid-cols-6 col-span-2 mt-5 space-y-6 md:mt-0">
      <div class="grid grid-cols-6 col-span-6 gap-2">
       <div class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
         <div>
          <label
           for="open-time"
           class="block text-sm font-semibold text-gray-700">
           {{ $t("openTimeSlot") }}*
          </label>
         </div>
         <button
          @click="(openTimeSlotForm = true), (isActive = true)"
          type="button"
          class="inline-flex items-center px-2 py-2 text-sm font-medium text-white capitalize border border-transparent rounded body__button focus:outline-none">
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="w-5 h-5 mr-1"
           fill="none"
           viewBox="0 0 24 24"
           stroke="currentColor"
           stroke-width="2">
           <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>

          {{ $t("callqueueTable.addTimeSlot") }}
         </button>
        </div>
       </div>
       <div v-if="form.openingDaysAndTime.length > 0" class="col-span-6 mb-5">
        <div class="border rounded-md">
         <CollectTimeSlotTable
          :tablePaginatedData="form.openingDaysAndTime"
          @deleteList="deteleTimeSpan($event)" />
        </div>
       </div>
      </div>
      <div class="col-span-4 sm:col-span-6">
       <label for="strategy" class="text-sm font-medium text-gray-700">
        {{ $t("strategyType") }}
       </label>

       <font-awesome-icon
        icon="circle-question"
        class="w-4 h-4 mb-1 ml-1 text-green-400 cursor-pointer hover:text-green-500 hover:h-5 hover:w-5"
        @click="
         this.$refs.popupAlert.show({
          title: 'unsavedChangesTitle',
          message: 'unsavedChangesMessage',
          cancelButton: 'unsavedChangesCancelButton',
          okButton: 'unsavedChangesOkButton',
         })
        "
        title="Click to see more"
        style="cursor: help" />

       <div class="flex gap-2">
        <select
         v-model="form.strategy.type"
         id="type"
         name="type"
         class="block w-1/2 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm">
         <option
          v-for="element in strategyList"
          :key="element.id"
          :value="element.id">
          {{ element.desc }}
         </option>
        </select>
        <input
         v-model="form.strategy.time"
         v-if="form.strategy.type !== 0"
         type="number"
         min="0"
         name="strategy"
         id="strategy"
         :placeholder="$t('switchingTimes')"
         class="block w-1/2 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
       </div>
      </div>

      <div
       v-if="form.participants.length > 0 && form.strategy.type == 1"
       class="col-span-6 px-4 py-2 border border-gray-200 rounded-md">
       <div class="space-y-4">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
         {{ $t("callQueueOrder") }}
        </h3>
        <p class="mt-1 mr-1 text-sm text-gray-500">
         Si vous souhaitez mettre en place une distribution des appels vers les
         agents du type “1 à 1 dans l’ordre” (cf. section suivante) veuillez
         ajouter les agents
         <span class="font-semibold"> dans l'ordre de distribution voulu</span>.
        </p>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="grid grid-cols-4 gap-6">
         <div class="col-span-4 sm:col-span-2">
          <label
           for="participants"
           class="block text-sm font-semibold text-gray-700">
           {{ $t("callQueueOrderDescription") }}.
          </label>

          <ul role="list" class="divide-y divide-gray-200">
           <li
            v-for="(person, idx) in form.participants"
            :key="person"
            class="py-4"
            draggable="true"
            @dragstart="startDrag($event, idx)"
            @drop="onDrop($event, idx)"
            @dragover.prevent
            @dragenter.prevent>
            <div class="flex justify-between">
             <div class="ml-1">
              <p class="text-sm font-medium text-gray-900">
               {{ idx + 1 }} -
               {{ person }}
              </p>
             </div>
             <div class="flex gap-1">
              <a
               class="cursor-pointer"
               v-show="idx > 0"
               @click="changePositionUp(idx)">
               <font-awesome-icon icon="caret-up" class="icon alt" />
              </a>
              <a
               @click="changePositionDown(idx)"
               class="cursor-pointer"
               v-show="idx < form.participants.length - 1">
               <font-awesome-icon icon="caret-down" class="icon alt" />
              </a>
             </div>
            </div>
           </li>
          </ul>
         </div>
        </div>
       </div>
      </div>
      <div
       v-if="
        form.participants.length > 0 &&
        (form.strategy.type == 5 ||
         form.strategy.type == 6 ||
         form.strategy.type == 7 ||
         form.strategy.type == 8)
       "
       class="col-span-6 px-4 py-2 border border-gray-200 rounded-md">
       <div class="space-y-4">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
         Valeur de compétence
        </h3>
        <p class="mt-1 mr-1 text-sm text-gray-500">
         Si vous souhaitez mettre en place une distribution des appels vers les
         agents sur compétence (cf. section suivante) veuillez ajouter les
         agents
         <span class="font-semibold"> dans l'ordre de compétence voulu</span>.
        </p>
       </div>
       <div class="mt-5 md:mt-0 md:col-span-2">
        <label
         for="participants"
         class="block text-sm font-semibold text-gray-700">
         Veuillez entrer l'agent et la valeur de compétence à attribuer, entre 1
         à 5. Séparer chaque agent par une virgule.
        </label>
        <div class="grid grid-cols-4 gap-6">
         <div class="col-span-4 sm:col-span-2">
          <div>
           <div class="mt-1">
            <textarea
             v-model="form.strategy.comment"
             rows="4"
             name="comment"
             id="comment"
             class="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
             placeholder="John Doe: 5, Jane Doe: 3,... " />
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="col-span-6 sm:col-span-4 lg:col-span-6">
       <div class="flex space-x-2">
        <ToggleWithIcon
         @boolean-toggle="form.predecroche.type = $event"
         :validated="form.predecroche.type" />
        <label
         for="street-address"
         class="mt-px text-sm font-medium text-gray-700">
         {{ $t("prehook") }}
         {{ !form.predecroche.type ? $t("desactivated") : $t("activated") }}
        </label>
        <Tooltip
         tooltip1="Un « pré-décroché » permet de remplacer la traditionnelle sonnerie d’attente par une message d’accueil personnalisé à l’image de votre société."
         tooltip2="La section 06 « Studio » vous permet de créer un nouveau message ou d’importer un message existant. Enregistrez vos modifications avant de quitter cette page, vous pourrez à tout moment revenir sur cette file pour corriger ou compléter vos informations." />
       </div>
       <p v-if="form.predecroche.type" class="mt-2 text-sm text-gray-500">
        {{ $t("prehookDescription") }}.
       </p>
       <select
        v-if="form.predecroche.type"
        v-model="form.predecroche.value"
        id="type"
        name="type"
        class="w-1/2 mt-0 border-gray-300 rounded-md shadow-sm sm:text-sm">
        <option
         v-for="(msg, idx) in getRecordedMsgs()"
         :key="idx"
         :value="msg.message ? msg.message : msg.file">
         {{ msg.name }}
        </option>
       </select>
       <!-- <input
                v-model="form.predecroche.value"
                type="text"
                name="predecroche"
                id="predecroche"
                class="block w-1/2 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm"
                v-show="form.predecroche.type"
              /> -->
      </div>

      <div v-if="form.predecroche.type" class="col-span-6">
       <div>
        <legend
         for="predecroche-agent"
         class="text-sm font-medium text-gray-900">
         {{ $t("prehookBeforeCallAgent") }}
        </legend>
       </div>

       <div class="mt-2 space-y-6">
        <div role="group" aria-labelledby="label-notifications">
         <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
          <div class="sm:col-span-2">
           <div class="space-y-4">
            <div class="flex items-center">
             <input
              v-model="form.predecrocheAgent"
              value="afterMessage"
              id="push-activate"
              name="push-notifications"
              type="radio"
              class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text" />
             <label
              for="push-activate"
              class="block ml-3 text-sm font-medium text-gray-500">
              {{ $t("afterMessagePredecroche") }}.
             </label>
            </div>

            <div>
             <div class="flex items-center">
              <input
               v-model="form.predecrocheAgent"
               value="withMessage"
               id="push-manual"
               name="push-notifications"
               type="radio"
               class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text" />
              <label
               for="push-manual"
               class="block ml-3 text-sm font-medium text-gray-500">
               {{ $t("withMessagePredecroche") }}.
              </label>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="col-span-6 sm:col-span-6">
       <label
        for="predecroche-music"
        class="block text-sm font-medium text-gray-700">
        {{ $t("predecrocheMusicTitle") }}
       </label>

       <div class="flex items-center my-3 space-x-2">
        <ToggleWithIcon
         @boolean-toggle="form.predecrocheMusic.type = $event"
         :validated="form.predecrocheMusic.type" />
        <p class="text-sm text-gray-500">
         <span class="mr-1"> {{ $t("predecrocheMusic") }} </span>
         <span class="font-semibold text-gray-700 lowercase">
          {{ form.predecrocheMusic.type ? $t("custom1") : $t("noCustom") }}
         </span>
         :
         <span>
          {{
           form.predecrocheMusic.type
            ? $t("predecrocheMusicDescription")
            : $t("predecrocheMusicDefault")
          }}
         </span>
        </p>
       </div>
       <select
        v-model="form.predecrocheMusic.value"
        v-show="form.predecrocheMusic.type"
        id="type"
        name="type"
        class="w-2/3 mt-0 border-gray-300 rounded-md shadow-sm sm:text-sm">
        <option
         v-for="(msg, idx) in getRecordedMsgs()"
         :key="idx"
         :value="msg.message">
         {{ msg.name }}
        </option>
       </select>
      </div>
      <div class="content-center col-span-4 sm:col-span-4">
       <label
        for="out-work-time"
        class="block mt-5 text-sm font-semibold text-gray-700">
        {{ $t("nonAnsweringDestination") }}*
        <Tooltip
         tooltip1="Séléctionnez la destination des appels :"
         tooltipList1="REPONDEUR SIMPLE: l’appel sera dirigé sur un répondeur SANS offrir la possibilité de laisser un message. Sélectionner à droite le message audio correspondant."
         tooltipList2="REPONDEUR: l’appel sera dirigé sur le répondeur d'une extension. Sélectionner à droite le numéro d'extension du répondeur."
         tooltipList3="FILE: l’appel sera dirigé sur une File d’attente. Sélectionner à droite la File."
         tooltipList4="TRANSFERT: l’appel sera dirigé vers un numéro externe à l’entreprise. Indiquez à droite le Numéro externe."
         :list="true" />
       </label>

       <p
        v-show="form.nonAnsweringDestination.type === 5"
        class="text-sm text-gray-500">
        Saisissez une extension existante valide de votre 3CX.
       </p>
       <span
        v-if="
         form.nonAnsweringDestination.number &&
         form.nonAnsweringDestination.type != 3 &&
         form.nonAnsweringDestination.type != 5
        "
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
        {{ form.nonAnsweringDestination.number }}
        <a
         :title="$t('delete')"
         class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
         @click="form.nonAnsweringDestination.number = ''">
         <span class="sr-only">Remove sda number</span>
         <svg
          class="w-2 h-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8">
          <path
           stroke-linecap="round"
           stroke-width="1.5"
           d="M1 1l6 6m0-6L1 7" />
         </svg>
        </a>
       </span>

       <div class="flex gap-2">
        <select
         v-model="form.nonAnsweringDestination.type"
         id="type"
         name="type"
         class="flex-none block w-2/5 h-10 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm">
         <option
          v-for="element in filteredNonWorkingTimeDestinationList"
          :key="element.id"
          :value="element.id">
          {{ element.desc }}
         </option>
        </select>

        <div
         v-if="
          form.nonAnsweringDestination.type != 3 &&
          form.nonAnsweringDestination.type != 4 &&
          form.nonAnsweringDestination.type != 5
         "
         class="mt-1">
         <SearchSelector
          :key="$route.name"
          :selected="form.nonAnsweringDestination.number"
          :searchData="
           form.nonAnsweringDestination.type == 0
            ? users
            : form.nonAnsweringDestination.type == 1
            ? svi
            : queues
          "
          @addElement="form.nonAnsweringDestination.number = $event[0]" />
        </div>

        <div
         v-else-if="form.nonAnsweringDestination.type != 4"
         class="flex-auto">
         <div class="relative mt-1 rounded-md shadow-sm">
          <input
           v-model="form.nonAnsweringDestination.number"
           @keyup="
            /^\d+$/.test(
             form.nonAnsweringDestination.number.split(' ').join('')
            )
             ? (errorPhoneNumberFormat = false)
             : (errorPhoneNumberFormat = true)
           "
           type="tel"
           name="nonAnsweringDestination"
           id="nonAnsweringDestination"
           :maxlength="form.nonAnsweringDestination.type === 5 ? '4' : '16'"
           :placeholder="
            form.nonAnsweringDestination.type === 5
             ? this.$t('newSitePlaceholder')
             : '01 12 34 56 78'
           "
           :class="[
            errorPhoneNumberFormat && form.nonAnsweringDestination.number
             ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
             : 'border-gray-300',
            'block w-full h-10 rounded-md sm:text-sm',
           ]" />
          <div
           v-if="errorPhoneNumberFormat && form.nonAnsweringDestination.number"
           class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
           <ExclamationCircleIcon
            class="w-5 h-5 text-red-500"
            aria-hidden="true" />
          </div>
         </div>
         <p
          v-if="errorPhoneNumberFormat && form.nonAnsweringDestination.number"
          class="ml-1 text-sm text-red-600">
          {{
           $t("pleaseUseFormat", {
            n:
             form.nonAnsweringDestination.type === 5
              ? this.$t("newSitePlaceholder")
              : "01 98 76 54 32",
           })
          }}.
         </p>
        </div>
       </div>
      </div>
      <div class="col-span-4 sm:col-span-4">
       <label
        for="noResponseTime"
        class="block text-sm font-medium text-gray-700">
        {{ $t("noResponseTime") }}.
       </label>
       <input
        v-model="form.noResponseTime"
        type="number"
        name="noResponseTime"
        id="noResponseTime"
        class="block w-full mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
      </div>
     </div>
    </div>
   </div>

   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("strategyClosingHours") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="gap-2">
       <div>
        <label
         for="out-work-time"
         class="block text-sm font-semibold text-gray-700">
         {{ $t("nonWorkingTimeDestination") }}*
         <Tooltip
          tooltip1="Séléctionnez la destination des appels :"
          tooltipList1="REPONDEUR SIMPLE: l’appel sera dirigé sur un répondeur SANS offrir la possibilité de laisser un message. Sélectionner à droite le message audio correspondant."
          tooltipList2="REPONDEUR: l’appel sera dirigé sur le répondeur d'une extension. Sélectionner à droite le numéro d'extension du répondeur."
          tooltipList3="FILE: l’appel sera dirigé sur une File d’attente. Sélectionner à droite la File."
          tooltipList4="TRANSFERT: l’appel sera dirigé vers un numéro externe à l’entreprise. Indiquez à droite le Numéro externe."
          :list="true" />
        </label>
        <p
         v-show="form.nonWorkingTimeDestination.type === 5"
         class="text-sm text-gray-500">
         Saisissez une extension existante valide de votre 3CX.
        </p>
        <span
         v-if="
          form.nonWorkingTimeDestination.number &&
          form.nonWorkingTimeDestination.type != 3 &&
          form.nonWorkingTimeDestination.type != 5
         "
         class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
         {{ form.nonWorkingTimeDestination.number }}
         <a
          :title="$t('delete')"
          class="cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
          @click="form.nonWorkingTimeDestination.number = ''">
          <span class="sr-only">Remove sda number</span>
          <svg
           class="w-2 h-2"
           stroke="currentColor"
           fill="none"
           viewBox="0 0 8 8">
           <path
            stroke-linecap="round"
            stroke-width="1.5"
            d="M1 1l6 6m0-6L1 7" />
          </svg>
         </a>
        </span>

        <div class="flex gap-2">
         <select
          v-model="form.nonWorkingTimeDestination.type"
          id="type"
          name="type"
          class="block w-1/2 h-10 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm">
          <option
           v-for="element in filteredNonWorkingTimeDestinationList"
           :key="element.id"
           :value="element.id">
           {{ element.desc }}
          </option>
         </select>

         <div
          v-if="
           form.nonWorkingTimeDestination.type != 3 &&
           form.nonWorkingTimeDestination.type != 4 &&
           form.nonWorkingTimeDestination.type != 5
          "
          class="mt-1">
          <SearchSelector
           :key="$route.name"
           :selected="form.nonWorkingTimeDestination.number"
           :searchData="
            form.nonWorkingTimeDestination.type == 0
             ? users
             : form.nonWorkingTimeDestination.type == 1
             ? svi
             : queues
           "
           @addElement="form.nonWorkingTimeDestination.number = $event[0]" />
         </div>
         <div
          v-else-if="form.nonWorkingTimeDestination.type != 4"
          class="flex-auto">
          <div class="relative mt-1 rounded-md shadow-sm">
           <input
            v-model="form.nonWorkingTimeDestination.number"
            @keyup="
             /^\d+$/.test(
              form.nonWorkingTimeDestination.number.split(' ').join('')
             )
              ? (errorPhoneNumberFormat = false)
              : (errorPhoneNumberFormat = true)
            "
            type="tel"
            name="nonWorkingTimeDestination"
            id="nonWorkingTimeDestination"
            :maxlength="form.nonWorkingTimeDestination.type === 5 ? '4' : '16'"
            :placeholder="
             form.nonWorkingTimeDestination.type === 5
              ? this.$t('newSitePlaceholder')
              : '01 12 34 56 78'
            "
            :class="[
             errorPhoneNumberFormat && form.nonWorkingTimeDestination.number
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300',
             'block w-full h-10 rounded-md sm:text-sm',
            ]" />
           <div
            v-if="
             errorPhoneNumberFormat && form.nonWorkingTimeDestination.number
            "
            class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
             class="w-5 h-5 text-red-500"
             aria-hidden="true" />
           </div>
          </div>
          <p
           v-if="
            errorPhoneNumberFormat && form.nonWorkingTimeDestination.number
           "
           class="ml-1 text-sm text-red-600">
           {{
            $t("pleaseUseFormat", {
             n:
              form.nonWorkingTimeDestination.type === 5
               ? this.$t("newSitePlaceholder")
               : "01 98 76 54 32",
            })
           }}.
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>

   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("advancedSettings") }}
      </h3>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="grid grid-cols-6 space-y-4">
       <div class="col-span-6 sm:col-span-6">
        <label for="intercallDelay" class="text-sm font-medium text-gray-700">
         {{ $t("intercallDelay") }}
        </label>
        <Tooltip
         tooltip1="Le temps de pause entre deux appels non personnalisé est de 2 secondes. C’est le temps minimum et la configuration 3CX par défaut."
         tooltip2="Activer cette option si vous souhaitez augmenter ce temps de pause pour permettre aux agents de prendre des notes à la fin d’un entretien ou clôturer une fiche CRM." />

        <div class="flex items-center mt-1 space-x-2">
         <ToggleWithIcon
          @boolean-toggle="form.intercallDelay.type = $event"
          :validated="form.intercallDelay.type" />
         <p class="text-sm text-gray-500">
          {{
           form.intercallDelay.type
            ? $t("intercallDelayDescription")
            : $t("noCustom")
          }}
         </p>
        </div>
        <input
         v-show="form.intercallDelay.type"
         v-model="form.intercallDelay.value"
         type="number"
         min="2"
         :disabled="!form.intercallDelay.type"
         name="intercallDelay"
         id="intercallDelay"
         :class="[
          !form.intercallDelay.type ? 'bg-gray-100' : '',
          'mt-1 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md',
         ]" />
       </div>
       <div class="col-span-6 sm:col-span-6">
        <label for="autoCallback" class="text-sm font-medium text-gray-700">
         {{ $t("autoCallback") }}
        </label>
        <Tooltip
         tooltip1="L’option de rappel automatique permet aux appelants de pouvoir raccrocher et d’être rappelés. Dès qu'un agent de la file est disponible, 3CX appelle automatiquement l'agent disponible pour le mettre en relation avec le client.Vous pouvez nous fournir un message studio pour l'annonce du rappel Auto ou utiliser le message par défaut 3CX."
         tooltipList1="Option 1 : Proposer au correspondant d’appuyer sur “2” pour être rappeler via un message de prédécroché du type « demandez à être rappeler en tapant la touche 2 à tout moment lors de l'attente »"
         tooltipList2="Option 2 : Le correspondant est automatiquement invité à être rappeler après un délai d’attente que vous spécifiez en secondes. Cette durée inclut le prédécroché (par exemple si le prédecroche dure 30s et que la tempo de rappel auto est à 45s, le client aura limpression qu’on lui propose le rappel auto seulement apres 15 sec d'attente."
         :list="true" />

        <div class="flex items-center mt-2 space-x-2">
         <ToggleWithIcon
          @boolean-toggle="form.autoCallback.type = $event"
          :validated="form.autoCallback.type" />
         <p class="text-sm text-gray-500 capitalize">
          {{ form.autoCallback.type ? $t("custom2") : $t("desactivated") }}
         </p>
        </div>
        <div v-show="form.autoCallback.type" class="mt-2 space-y-2">
         <div class="flex items-center">
          <input
           v-model="form.autoCallback.option"
           value="prehookMessage"
           id="push-activate"
           name="push-notifications"
           type="radio"
           class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text" />
          <label
           for="push-activate"
           class="block ml-3 text-sm font-medium text-gray-500">
           {{ $t("prehookMessageDescription") }}.
          </label>
         </div>

         <div class="flex items-center">
          <input
           v-model="form.autoCallback.option"
           value="delayTime"
           id="push-manual"
           name="push-notifications"
           type="radio"
           class="w-4 h-4 border-gray-300 encom_border_color encom_primary_text" />
          <label
           for="push-manual"
           class="block ml-3 text-sm font-medium text-gray-500">
           {{ $t("delayTimeDescription") }}.
          </label>
         </div>
         <div v-show="form.autoCallback.option === 'delayTime'" class="">
          <div class="col-span-3">
           <div class="flex space-x-2">
            <ToggleWithIcon
             @boolean-toggle="form.autoCallback.delayTimeType = $event"
             :validated="form.autoCallback.delayTimeType" />
            <label
             for="street-address"
             class="block mt-px text-sm font-medium text-gray-700">
             {{ $t("announcementMessage") }}
            </label>
           </div>
          </div>
          <div class="flex justify-start space-x-4">
           <div class="mt-4">
            <input
             v-model="form.autoCallback.value"
             type="number"
             name="predecroche"
             id="predecroche"
             placeholder="Délais en secondes"
             class="block mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
           </div>
           <div v-show="form.autoCallback.delayTimeType">
            <p class="text-sm text-gray-500">{{ $t("prehookDescription") }}.</p>
            <select
             v-model="form.autoCallback.value"
             id="type"
             name="type"
             class="w-1/2 mt-0 border-gray-300 rounded-md shadow-sm sm:text-sm">
             <option
              v-for="(msg, idx) in getRecordedMsgs()"
              :key="idx"
              :value="msg.message">
              {{ msg.name }}
             </option>
            </select>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <div class="gap-2">
       <div>
        <label for="notRecord" class="block text-sm font-medium text-gray-700">
         {{ $t("notRecord") }}
        </label>

        <div class="flex mt-2">
         <div class="flex items-center space-x-2">
          <ToggleWithIcon
           @boolean-toggle="form.notRecord = $event"
           :validated="form.notRecord" />
          <p class="text-sm text-gray-500">
           {{ form.notRecord ? $t("notRecordYes") : $t("desactivated") }}
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
      <fieldset class="space-y-3">
       <legend class="text-sm font-medium text-gray-700">
        Notifications e-mails envoyées aux administrateurs
        <Tooltip
         tooltip1="Les notifications email permettent d’informer l’administrateur de la file d’attente de certains événements liés à la file d’attente: lorsque le temps SLA est atteint, quand un appel dans la file vient d’être perdu ou les activités liées aux rappels." />
       </legend>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="form.adminNotifications.sla"
          id="sla"
          aria-describedby="sla-description"
          name="sla"
          type="checkbox"
          class="w-4 h-4 border-gray-300 rounded body__form--checkbox-focus text-main-color" />
        </div>
        <div class="ml-3 text-sm">
         <label for="sla" class="font-medium text-gray-600">
          Notifier l'administrateur de la file lors de dépassements du temps SLA
          <Tooltip
           tooltip1="La notification de dépassement de temps SLA permet d’informer l’administrateur par email à chaque fois qu’un appel dépasse le temps d’attente définit en SLA." />
         </label>
         <div v-show="form.adminNotifications.sla" class="flex items-center">
          <label for="strategy" class="mr-3 text-sm font-medium text-gray-600">
           Définissez le SLA en secondes
          </label>
          <input
           v-model="form.adminNotifications.slaInSeconds"
           type="number"
           min="0"
           name="sla"
           id="sla"
           class="mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm" />
         </div>
        </div>
       </div>

       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="form.adminNotifications.callback"
          id="callback"
          aria-describedby="callback-description"
          name="callback"
          type="checkbox"
          class="w-4 h-4 border-gray-300 rounded body__form--checkbox-focus text-main-color" />
        </div>
        <div class="ml-3 text-sm">
         <label for="cancallbackidates" class="font-medium text-gray-600">
          Notifier l'administrateur lorqu'un rappel a échoué
         </label>
        </div>
       </div>
       <div class="relative flex items-start">
        <div class="flex items-center h-5">
         <input
          v-model="form.adminNotifications.lostCall"
          id="lostCall"
          aria-describedby="lostCall-description"
          name="lostCall"
          type="checkbox"
          class="w-4 h-4 border-gray-300 rounded body__form--checkbox-focus text-main-color" />
        </div>
        <div class="ml-3 text-sm">
         <label for="lostCall" class="font-medium text-gray-600">
          Notifier l'administrateur lorqu'un appel a été perdu
         </label>
        </div>
       </div>
      </fieldset>
     </div>
    </div>
   </div>

   <div class="px-4 py-5 bg-white border sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
     <div class="md:col-span-1">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
       {{ $t("comment") }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">
       {{ $t("callQueueAndSviComment") }}.
      </p>
     </div>
     <div class="mt-5 md:mt-0 md:col-span-2">
      <div>
       <textarea
        v-model="form.comment"
        rows="5"
        name="comment"
        id="comment"
        class="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
        :placeholder="$t('addYourComment')" />
      </div>
     </div>
    </div>
   </div>
  </div>
  <div class="flex justify-end py-5" ref="callqueueForm">
   <button
    @click="$emit('closeForm')"
    type="button"
    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
    {{ $t("cancel") }}
   </button>
   <button
    type="submit"
    class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white capitalize border border-transparent rounded-md shadow-sm body__button"
    :class="{
     'animate-bounce': bounceButton === 'callqueueForm' && unsavedChangesBool,
    }">
    {{ $t("validate") }}
   </button>
  </div>
 </form>

 <ask-confirmation-dialog
  ref="askConfirmationDialog"
  @closeForm="$emit('closeForm')"></ask-confirmation-dialog>
 <CollectTimeSlot
  @closeForm="openTimeSlotForm = false"
  v-if="openTimeSlotForm && isActive"
  :call_queue_time_spans="form.openingDaysAndTime"
  @pushTimeSlot="
   form.openingDaysAndTime.push($event), (openTimeSlotForm = false)
  "
  @isActive="(isActive = $event), (openTimeSlotForm = $event)" />
 <Popup
  ref="popupAlert"
  @successMsg="successMsg = $event"
  @success="isSuccess = $event" />
</template>

<script>
import axios from "axios";

import FormFieldMandatory from "./formTools/FormFieldMandatory.vue";
import CollectTimeSlotTable from "./formTools/CollectTimeSlotTable.vue";
import CollectTimeSlot from "./formTools/CollectTimeSlot.vue";
import ToggleWithIcon from "./formTools/ToggleWithIcon.vue";
import SdaLinkedSelect from "./formTools/SdaLinkedSelect.vue";
import SearchSelector from "../SearchSelector.vue";
import Tooltip from "./formTools/Tooltip.vue";
import Popup from "../../components/Collect/formTools/Popup.vue";

import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import SearchExtensionsGroup from "./SearchExtensionsGroup.vue";

import {
 Switch,
 SwitchDescription,
 SwitchGroup,
 SwitchLabel,
} from "@headlessui/vue";

import {
 InformationCircleIcon,
 PlusIcon,
 ExclamationCircleIcon,
} from "@heroicons/vue/solid";

const choiceList = [
 { id: 0, desc: "File d'attente" },
 { id: 1, desc: "Extension" },
 { id: 2, desc: "Répondeur" },
 { id: 3, desc: "Numero externe" },
 { id: 3, desc: "SVI (arboresence supplementaire)" },
];
const strategyList = [
 { id: 0, desc: "SONNE TOUS" },
 { id: 1, desc: "1 À 1 DANS L'ORDRE" },
 { id: 2, desc: "1 À 1 AU HASARD" },
 { id: 3, desc: "AGENT AVEC LE MOINS D'APPEL" },
 { id: 4, desc: "TEMPS DE COMMUNICATION" },
 { id: 5, desc: "SUR COMPÉTENCE AVEC AGENT AYANT LE MOINS D'APPEL" },
 { id: 6, desc: "SUR COMPÉTENCE EN SONNE TOUS" },
 { id: 7, desc: "SUR COMPÉTENCE DANS L'ORDRE EN ROUND ROBIN" },
 { id: 8, desc: "SUR COMPÉTENCE AU HASARD" },
];
const yesOrNo = [
 { id: 0, desc: "NON" },
 { id: 1, desc: "OUI" },
];
const account = localStorage.getItem("account");
export default {
 props: [
  "editData",
  "users",
  "queuesNames",
  "isEdit",
  "showForm",
  "queues",
  "sdaTown",
  "editIdx",
  "contentData",
  "createdSda",
  "fileId",
  "bounceButton",
  "unsavedChangesBool",
  "elementRef",
  "svi",
  "collectType",
  "queuesData",
  "studioMsgs",
 ],
 components: {
  SdaLinkedSelect,
  SearchSelector,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  SearchExtensionsGroup,
  AskConfirmationDialog,
  InformationCircleIcon,
  CollectTimeSlot,
  CollectTimeSlotTable,
  PlusIcon,
  ToggleWithIcon,
  Tooltip,
  ExclamationCircleIcon,
  FormFieldMandatory,
  Popup,
 },
 data() {
  return {
   account,
   previousData: {},
   choiceList,
   errorSdaFound: false,
   errorAddedSda: false,
   errorIsInside: false,
   errorPhoneNumberFormat: false,
   userQueueEnabled: false,
   adminQueueEnabled: false,
   isRequired: true,
   userQueueExistingExtension: "",
   adminQueueExistingExtension: "",
   formName: "",
   form: {
    name: "",
    associateNumber: [],
    adminExt: [],
    participants: [],
    openingDaysAndTime: [],
    nonWorkingTimeDestination: { number: "", type: 0 },
    strategy: { time: "", type: 0, comment: "" },
    predecroche: { value: "", type: 0 },
    predecrocheMusic: { value: "", type: 0 },
    predecrocheAgent: "afterMessage",
    maxCallerNumb: 0,
    noResponseTime: 90,
    priorityQueue: false,
    notRecord: false,
    intercallDelay: { value: 2, type: 0 },
    autoCallback: {
     type: false,
     option: "prehookMessage",
     delayTimeType: false,
     delayTime: null,
     isComplete: false,
     value: "",
    },
    nonAnsweringDestination: { number: "", type: 0 },
    adminNotifications: {
     sla: false,
     slaInSeconds: null,
     callback: false,
     lostCall: false,
    },
    comment: "",
    isComplete: false,
   },
   isActive: true,
   openTimeSlotForm: false,
   nonWorkingTimeDestinationList: [
    { id: 0, desc: "Répondeur" },
    { id: 1, desc: "Répondeur simple (SVI)" },
    { id: 2, desc: "File d'attente" },
    { id: 3, desc: "Transfert (numéro externe)" },
    { id: 4, desc: "Mettre fin à l'appel" },
   ],
   queueNameInvalid: false,
   range: [],
   isolatedNumbers: [],
   savedForm: "",
   sdaNumber: null,
   sdaLeft: null,
   sdaMax: null,
   selected: [],
   strategyList,
   yesOrNo,
   otherNumbers: [],
  };
 },
 computed: {
  filteredNonWorkingTimeDestinationList() {
   const newList = [...this.nonWorkingTimeDestinationList];
   if (this.collectType === "newSite") {
    newList.splice(3, 0, { id: 5, desc: "Extension existante" });
   } else {
    return newList.filter(type => type.id !== 5);
   }
   return newList;
  },
 },
 methods: {
  toggleItem(event) {
   console.log("toggleItem ~ event:", event);
   //  if (!this.form.participants.includes(event)) {
   this.form.participants.push(event);
   //  } else {
   //   this.form.participants = this.form.participants.filter(
   //    item => item !== event
   //   );
   //  }
  },
  async onSubmit() {
   //adminExt is not mandatory anymore
   if (!this.queueNameInvalid && this.form.name.length > 0) {
    this.form.participants.length > 0 &&
    this.form.openingDaysAndTime.length > 0 &&
    this.form.nonWorkingTimeDestination.number &&
    this.form.nonAnsweringDestination.number
     ? (this.form.isComplete = true)
     : (this.form.isComplete = false);

    this.$emit(
     "submitForm",
     this.form,
     this.sdaLeft,
     this.$t("informationSaved")
    );
    // }
   }
  },
  deteleTimeSpan(id) {
   this.form.openingDaysAndTime.splice(id, 1);
  },
  setFormAdminExt(event) {
   this.form.adminExt = event;
  },
  setFormParticipants(event) {
   this.form.participants = event;
  },
  formatDateForForm(date) {
   let formDate = new Date(date);
   let formDay = formDate.getDate();
   let formMonth = formDate.getMonth();
   let formYear = formDate.getFullYear();
   return formDay + "/" + formMonth + "/" + formYear;
  },
  async getDataFromEdit() {
   if (this.editData) {
    console.log(
     "file: CollectCallQueueFormPopup.vue:913 ~ getDataFromEdit ~ this.editIdx:",
     this.editIdx
    );
    console.log(
     "file: CollectCallQueueFormPopup.vue:913 ~ getDataFromEdit ~ this.editData:",
     this.editData
    );
    let data = await this.editData["callQueue"]["data"][this.editIdx];

    console.log(
     "file: CollectCallQueueFormPopup.vue:913 ~ getDataFromEdit ~ data:",
     data
    );

    this.form.name = data.name;
    this.formName = data.name;
    this.form.associateNumber = [...data.associateNumber];
    this.form.adminExt = data.adminExt;
    this.form.openingDaysAndTime = data.openingDaysAndTime;
    this.form.maxCallerNumb = data.maxCallerNumb;
    this.form.noResponseTime = data.noResponseTime;
    this.form.priorityQueue = data.priorityQueue;
    this.form.notRecord = data.notRecord;
    this.form.participants = data.participants;
    this.form.strategy = data.strategy;
    this.form.predecroche = data.predecroche;
    this.form.predecrocheAgent = data.predecrocheAgent;
    this.form.predecrocheMusic = data.predecrocheMusic;
    this.form.intercallDelay = data.intercallDelay;
    this.form.autoCallback = data.autoCallback;
    this.form.nonWorkingTimeDestination = data.nonWorkingTimeDestination;
    this.form.nonAnsweringDestination = data.nonAnsweringDestination;
    this.form.comment = data.comment;
   }
   this.savedForm = JSON.stringify(this.form);
  },
  getStoreVar() {
   let numbers = this.contentData["numbers"];

   if (numbers) {
    this.sdaLeft = numbers.sdaUsed?.sdaLeft;
    this.sdaMax = numbers.sdaUsed?.sdaMax;
    this.otherNumbers = numbers.number?.other?.map(obj => obj.number);
    this.range = numbers.number?.range?.map(a => {
     return { ...a };
    });
    numbers.number?.rio?.forEach(el => {
     this.isolatedNumbers.push(el.number);
    });
   }
  },

  removeSda(index) {
   this.$emit("removeSdaTown", this.form.associateNumber[index], () => {
    this.form.associateNumber.splice(index, 1);
   });

   this.sdaLeft >= this.sdaMax
    ? (this.sdaLeft = this.sdaMax)
    : (this.sdaLeft = this.sdaLeft + 1);
  },
  removeElement() {
   this.sdaLeft >= this.sdaMax
    ? (this.sdaLeft = this.sdaMax)
    : (this.sdaLeft = parseInt(this.sdaLeft) + 1);
  },

  checkValidateSda(value) {
   this.errorAddedSda = false;
   this.errorSdaFound = false;

   const validLength = 10;
   let absoluteValue =
    typeof value === "string" ? value.replace(/\s+/g, "") : "";
   const isNum = /^\d+$/.test(absoluteValue);

   if (absoluteValue.length !== validLength || !isNum) {
    this.errorAddedSda = true;
    return;
   }

   // Check existence in both createdSda and userSettings.sdaNumber
   const isExisted = this.createdSda.includes(absoluteValue);

   if (isExisted) {
    this.errorSdaFound = true;
    return;
   }
   this.sdaLeft <= 0
    ? (this.sdaLeft = 0)
    : (this.sdaLeft = parseInt(this.sdaLeft) - 1);
   this.sdaNumber = "";
  },

  checkValidateSda(sdaNumber) {
   this.errorAddedSda = false;
   this.errorSdaFound = false;

   const absoluteSda =
    typeof sdaNumber === "string" ? sdaNumber.split(" ").join("") : "";
   const isnum = /^\d+$/.test(absoluteSda);
   const filterExisted = this.form.associateNumber.filter(
    sdas => sdas === absoluteSda
   );
   const filterNew = this.createdSda.filter(sdas => sdas === absoluteSda);

   if (!isnum || absoluteSda.length !== 10) {
    this.errorAddedSda = true;
    return;
   }

   if (filterExisted.length === 0 && filterNew.length === 0) {
    this.form.associateNumber.push(absoluteSda);
   }

   this.sdaLeft = Math.max(0, this.sdaLeft - 1);
   this.sdaNumber = "";
  },

  checkCarriedNumber(number) {
   this.errorAddedSda = false;
   this.errorIsInside = false;

   const isnum = /^\d+$/.test(number);
   if (isnum) {
    this.checkValidateSda(number);
    return true;
    /*  for (let index = 0; index < this.range.length; index++) {
     const range = this.range[index];
     if (absoluteNumb >= range.from && absoluteNumb <= range.to) {
      this.checkValidateSda(numb);
      return true;
     }
    } */
    //this.errorIsInside = true;
   } else {
    this.errorAddedSda = true;
   }
  },
  createNewNumber(town) {
   let arr = [...this.form.associateNumber];
   arr.push(town);
   this.form.associateNumber = [...arr];
   this.$emit("updateSdaTown", town);
   this.sdaLeft <= 0 ? (this.sdaLeft = 0) : (this.sdaLeft = this.sdaLeft - 1);
  },
  changePositionUp(idx) {
   const fromIndex = idx;
   const toIndex = idx - 1;

   const element = this.form.participants.splice(fromIndex, 1)[0];

   this.form.participants.splice(toIndex, 0, element);
  },
  changePositionDown(idx) {
   const fromIndex = idx; // 👉️ 0
   const toIndex = idx + 1;

   const element = this.form.participants.splice(fromIndex, 1)[0];

   this.form.participants.splice(toIndex, 0, element);
  },
  changePosition(fromIdx, toIdx) {
   const fromIndex = fromIdx; // 👉️ 0
   const toIndex = toIdx;

   const element = this.form.participants.splice(fromIndex, 1)[0];

   this.form.participants.splice(toIndex, 0, element);
  },
  startDrag(evt, idx) {
   evt.dataTransfer.dropEffect = "move";
   evt.dataTransfer.effectAllowed = "move";
   evt.dataTransfer.setData("itemIdx", idx);
  },
  onDrop(evt, idx) {
   const itemIdx = evt.dataTransfer.getData("itemIdx");
   this.changePosition(itemIdx, idx);
  },
  getRecordedMsgs() {
   let msgList = [];
   //    if (this.contentData && this.contentData["studio"] && this.contentData["studio"].length > 0) {
   //     msgList = this.contentData["studio"];
   //    }
   msgList = this.studioMsgs;
   return msgList;
  },
  async getCollect() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${
      this.fileId
     }?customerAccount=${this.account}`
    );
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  addExistingExtension(type, newExt) {
   if (type === "user") {
    this.form.participants = [...this.form.participants, newExt];
    this.userQueueExistingExtension = "";
   } else {
    this.form.adminExt = [...this.form.adminExt, newExt];
    this.adminQueueExistingExtension = "";
   }
  },
 },
 mounted() {
  this.getDataFromEdit();
    this.getStoreVar();
 },
 watch: {
  formName: function (val) {
   if (val && this.queuesNames.includes(val) && !this.isEdit) {
    this.queueNameInvalid = true;
   } else {
    this.queueNameInvalid = false;
    this.form.name = val;
   }
  },
  "form.predecroche.type": {
   handler: function (bool) {
    if (!bool) this.form.predecroche.value = "";
   },
   deep: true,
  },
  "form.predecrocheMusic.type": {
   handler: function (bool) {
    if (!bool) this.form.predecrocheMusic.value = "";
   },
   deep: true,
  },
  "form.nonAnsweringDestination.type": {
   handler: function (numb) {
    if (numb === 4) this.form.nonAnsweringDestination.number = "";
   },
   deep: true,
  },
  elementRef: function (ref) {
   if (ref) {
    const el = this.$refs.callqueueForm;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
   }
  },
  sdaLeft: function (number) {
   this.$emit("sdaLeft", number);
  },
  form: {
   handler: function (object) {
    this.$emit("elementForm", object);
    this.savedForm !== JSON.stringify(object)
     ? this.$emit("unsavedChanges", true, "callqueueForm")
     : this.$emit("unsavedChanges", false, "");
   },
   deep: true,
  },
 },
};
</script>

<style></style>
